/* eslint-disable @typescript-eslint/no-this-alias */
import UserStore from './user';
import CodedStore from './coded';
import LoadingStore from './loading';
import AppStore from './app';
import DataStore from './data';

class Root {
  static rootStore: Root;
  app: AppStore;
  user: UserStore;
  data: DataStore;
  coded: CodedStore;
  loading: LoadingStore;

  constructor() {
    if (!Root.rootStore) {
      this.app = new AppStore();
      this.user = new UserStore();
      this.data = new DataStore();
      this.coded = new CodedStore();
      this.loading = new LoadingStore();
      Root.rootStore = this;
    }
    return Root.rootStore;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static getInstance = () => {
    return Root.rootStore;
  };
}

export default Root;
