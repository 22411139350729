import { JudgePf } from '../../utils/JudgePf';

const os = JudgePf();
const isPc = os.isPc;

const $phone1vw = '1vw';
const $phone3dot2vw = '3.2vw';
const $phone3dot333vw = '3.333vw';
const $phone6dot4vw = '6.4vw';
const $phone7vw = '7vw';
const $phone8vw = '8vw';
const $phone8dot933vw = '8.933vw';

const $pc1vw = isPc ? '10px' : $phone1vw;
const $pc3dot2vw = isPc ? '20px; line-height: 48px' : $phone3dot2vw;
const $pc3dot333vw = isPc ? '25px; line-height: 48px' : $phone3dot333vw;
const $pc6dot4vw = isPc ? '20px; line-height: 48px' : $phone6dot4vw;
const $pc7vw = isPc ? '25px; line-height: 48px' : $phone7vw;
const $pc8vw = isPc ? '25px; line-height: 48px' : $phone8vw;
const $pc8dot933vw = isPc ? '25px; line-height: 48px' : $phone8dot933vw;

let monthlyVipAgreementTxt = `
        <div style="color: #fff; font-size: ${$pc3dot2vw}; padding: ${$pc8vw} ${$pc8dot933vw}; box-sizing: border-box; line-height: ${$pc6dot4vw}; ">
          <div style="font-size: ${$pc3dot333vw};text-align: center; margin-bottom: ${$pc7vw};">QuickFox会员连续包月协议</div> <div>1.服务条款的接受与修改</div>
          &nbsp;&nbsp;本协议是超级VIP会员（下称“会员”）与福建紫讯信息科技有限公司旗下应用软件QuickFox（以下称“QuickFox”）之间关于会员使用QuickFox提供的自动续费委托扣款服务（下称“本服务”）所订立的协议，本协议描述QuickFox与会员之间关于本服务的使用及相关方面的权利义务。“会员”是指享受QuickFox提供的会员服务的个人或单一实体。本协议构成会员（无论个人或者单位）使用QuickFox所提供的本服务之先决条件，除非会员接受本协议条款，否则会员无权使用本服务，会员选择使用本服务行为将视为同意接受本协议各项条款的约束。
          <br />
          <br />
          <div style="margin-bottom: ${$pc1vw};"> 2.服务说明</div>
          &nbsp;&nbsp;2.1
          本服务是出于会员对于自动续费的需求，在会员已开通本服务的前提下，避免会员因疏忽或其他原因导致未能及时续费造成损失而推出的服务，会员不可撤销地授权QuickFox可在会员有效期即将过期时，从会员的自有充值账户、与会员账号绑定的第三方支付账户、银行卡、通信账户（以下统称“账户”）余额中代扣下一个计费周期的费用，计费周期包括但不限于月度、季度、年度等（下同），会员可根据QuickFox平台上显示的计费周期自行选择。该服务实现的前提是会员已将其QuickFox会员账号与上述账户绑定，且可成功从其上述账户中扣款。
          <br />
          &nbsp;&nbsp;2.2
          自动续费具体指，基于2.1的前提，QuickFox通过上述账户收取会员下一计费周期费用的扣费方式，会员需保证QuickFox可以从上述账户扣款成功，因上述账户中可扣款余额不足导致的续费失败，由会员自行承担责任。
          <br />
          &nbsp;&nbsp;2.3
          会员在享受本服务时，会员的使用行为视为其对本服务的服务条款以及QuickFox针对本服务发出的各类公示的同意。
          <br />
          &nbsp;&nbsp;2.4
          QuickFox向会员提供的本服务均仅限于会员在QuickFox平台使用，任何以恶意破解等非法手段将QuickFox提供的本服务与QuickFox平台分离的行为，均不属于本协议中约定的本服务。由此引起的一切法律后果由行为人负责，QuickFox将依法追究行为人的法律责任。
          <br />
          <br />
          <div>3. 双方的权利和义务</div>
          &nbsp;&nbsp;3.1
          QuickFox负责向会员提供有关自动续费收费具体情况的查询方式，但不负责打印相关发票并转送会员。
          <br />
          &nbsp;&nbsp;3.2
          QuickFox通过会员上述账户扣除下一计费周期费用的，应于开通自动续费约定时间划扣，并将款项记入会员支付记录，并同时相应延长会员有效期。
          <br />
          &nbsp;&nbsp;3.3
          如在扣费过程出现差错，QuickFox和会员应密切配合查明原因，各自承担己方过错造成的损失；若因双方各自存在不均等过错造成损失的，由双方按过错的程度承担相应责任；因双方共同过错造成损失但无法区分各自过错的，由双方均摊责任。
          <br />
          &nbsp;&nbsp;3.4
          QuickFox可根据其业务开展或技术升级等情况变更或修改本协议的有关服务内容、规则及条款，QuickFox将通过在QuickFox相关页面公示的方式告知用户相关变更或修改内容，但无义务另行做个别通知。如果会员不同意本协议的修改，可以取消已经获取的本服务并停止使用；如果会员继续使用QuickFox提供的服务，则视为会员已经接受本协议的全部修改。
          <br />
          &nbsp;&nbsp;3.5
          本服务由会员自主选择是否取消，若会员选择不取消，则视为会员同意QuickFox按照一定规则进行扣款尝试，一旦扣款成功，QuickFox将为会员开通下一个计费周期的会员服务。
          <br />
          &nbsp;&nbsp;3.6
          若在自动续费时/之前，QuickFox服务价格发生调整，应以现时有效的价格为准。
          <br />
          &nbsp;&nbsp;3.7
          QuickFox对会员开通本服务不收取任何费用，但QuickFox有权根据业务需要或市场变化等原因决定是否对本服务本身进行收费或调整自动续费周期及费用，并在相关页面向会员进行公示。
          <br />
          <br />
          <div>4. 协议有效期限、终止</div>
          &nbsp;&nbsp;4.1
          本协议自会员选择接受或使用本服务后生效，直至会员终止本服务/注销会员资格时终止。
          <br />
          &nbsp;&nbsp;4.2
          会员有权随时在会员账号设置中选择终止本服务，终止本服务后，QuickFox将停止向会员提供本服务。
          <br />
          &nbsp;&nbsp;4.3
          会员在选择终止本服务前已经委托QuickFox自动续费扣款的指令仍然有效，QuickFox对于基于该指令扣除的费用不予退还，由会员承担相关责任。
          <br />
          <br />
          <div>5. 违约责任</div>
          &nbsp;&nbsp;如果一方违约，守约方有权通过双方约定的争议解决方式获得补偿。
          <br />
          <br /> <div>6. 争议解决和法律适用</div>
          &nbsp;&nbsp;QuickFox与会员应通过友好协商解决本协议履行过程中产生的争议，经协商无法解决的，任何一方均有权将争议提交至福州市中级人民法院诉讼解决。本协议的解释、效力和执行等有关问题均适用中华人民共和国法律。
          <br />
        </div>
`;
export default monthlyVipAgreementTxt;
