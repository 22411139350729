import { observable, action } from 'mobx';

class AppStore {
  @observable isReview: boolean;
  @observable version: string;
  @observable marketPlace: string;
  @observable androidReview: string;
  @observable isReviewMode: string;
}

export default AppStore;
