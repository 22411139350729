import { Toast } from 'antd-mobile';
import currentEnvConfig from '~/.config/api';
import { Cookie, JudgePf, NativeTools, postMessage, Url } from '.';
import LocalData from './LocalData';

const Tools = {
  /**
   * 检查是否登录（是否有token） 没有就跳转（原生）登录页面
   */
  needsLogin() {
    const API_ENV = process.env.API_ENV;
    // *本地环境不验证登录
    if (API_ENV === 'local') return false;
    if (!Cookie.getCookie('token')) {
      postMessage.send('jumpLogin', {});
      return true;
    }
    return false;
  },
  updateRemainCoins(params: {
    resRemain: number;
    resExpire?: number;
    price: number;
  }) {
    const { resRemain, price, resExpire } = params;
    //避免2.0.0以前的包用户出现报错
    if (localStorage) {
      //使用后端返回的余额
      let resRemain_ = resRemain;
      //如果没有返回 就手动算
      if (resRemain_ == null) {
        let coins = LocalData.getItem('remainCoins');

        let remain = Number(coins) - price;
        if (remain < 0) remain = 0; //避免出现负数
        resRemain_ = remain;
      }

      LocalData.setItem('remainCoins', resRemain_.toString());
      if (resExpire != null && !isNaN(Number(resExpire))) {
        LocalData.setItem('expireCoins', resExpire.toString());
      }
    }
  },
  alertDev(content: string) {
    const API_ENV = process.env.API_ENV;
    if (API_ENV === 'prod') return;
    alert(content);
    console.log('alertDev content: ', content);
  },
  /**
   * 通用catch error返回
   * @param error 获取到的error
   * @param defaulText 默认的error提示
   * @returns message.error()
   */
  catchError(error: any, defaulText = '操作失败') {
    Toast.fail(typeof error === 'object' ? defaulText : error);
    throw error;
  },
  /**
   * 获取配置中的常量地址
   * @returns
   */
  getConstUrls() {
    let urls = {
      host: currentEnvConfig.host,
      ios: currentEnvConfig.ios,
      android: currentEnvConfig.android,
      official: currentEnvConfig.official,
      official_hhq: currentEnvConfig.official_hhq,
      official_quick_fox: currentEnvConfig.official_quick_fox,
      cancelQuestionPath: currentEnvConfig.cancelQuestionPath,
      h5: currentEnvConfig.h5,
      imageCDN: currentEnvConfig.imageCDN,
    };
    // 正式环境，判断51域名，给出相应地址
    if (Url.isDomain51QF()) {
      urls.host = currentEnvConfig.host_51;
      urls.h5 = currentEnvConfig.h5_51;
      urls.imageCDN = currentEnvConfig.imageCDN_51;
    }
    return urls;
  },
  async isInApp() {
    const os = JudgePf();
    if (os.isIOS || os.isAndroid) {
      //安卓/ios应用中
      try {
        let res = await postMessage.send('setTitle', {
          title: '',
        });
        return Boolean(res);
      } catch (e) {
        console.log('判断isInApp出错: ', e);
        return false;
      }
    } else if (os.isPcApp) {
      //pc应用中
      return Boolean(window?.customElectronAPI);
    }
    //其他
    return false;
  },

/**
 * 
 * @param type 页面跳转（（跳转类型  0内部  1外部 3 小程序 4 原生）
 * @param params
 */
  goToHandle(type, params) {
    const os = JudgePf();
    switch (type) {
      case 0:
        NativeTools.goto('', params.targetUrl, false);
        break;
      case 1:
        NativeTools.goto('', params.targetUrl, true);
        break;
      case 3:
        if (os.isPcApp) {
          NativeTools.goto('', params.clientUrl, true);
        } else {
          postMessage.send('jumpWxMiniProgress', {
            username: params?.originalId,
            path: params?.innerLink ?? '',
            pcURL: params?.clientUrl
          });
        }
        break;
      case 4:
        NativeTools.goto('', params.targetUrl, false);
        break;
      default:
        break;
    }
  },
  // 神策通用配置
  sensorsConfig(data) {
    try {
      sensors.registerPage({
        client_type: data?.client_type,
        channel: data?.channel,
        account_type: data?.account_type,
      })
    } catch(e) {
      console.log(name, '神策配置错误');
    }
  },
  sensorsTrack (name, data = {}) {
    try {
      sensors.track(name, data);
    } catch (e) {
      console.log(name, '埋点错误');
    }
  }
};
export default Tools;
