import { JudgePf } from '../../utils/JudgePf';

const os = JudgePf();
const isPc = os.isPc;

const $phone3dot2vw = '3.2vw';
const $phone3dot333vw = '3.333vw';
const $phone6dot4vw = '6.4vw';
const $phone7vw = '7vw';
const $phone8vw = '8vw';
const $phone8dot933vw = '8.933vw';

const $pc3dot2vw = isPc ? '20px; line-height: 48px' : $phone3dot2vw;
const $pc3dot333vw = isPc ? '25px; line-height: 48px' : $phone3dot333vw;
const $pc6dot4vw = isPc ? '20px; line-height: 48px' : $phone6dot4vw;
const $pc7vw = isPc ? '25px; line-height: 48px' : $phone7vw;
const $pc8vw = isPc ? '25px; line-height: 48px' : $phone8vw;
const $pc8dot933vw = isPc ? '25px; line-height: 48px' : $phone8dot933vw;

let memberAgreementTxt = `<div style="color: #fff; font-size: ${$pc3dot2vw}; padding: ${$pc8vw} ${$pc8dot933vw}; box-sizing: border-box">
    <div style="font-size: ${$pc3dot333vw};text-align: center; margin-bottom: ${$pc7vw};"> QuickFox超级VIP服务协议</div>
    <div style="line-height: ${$pc6dot4vw};">欢迎您使用QuickFox超级VIP会员服务！</div>
 
    <div style="line-height: ${$pc6dot4vw}; color: rgb(236, 207, 171);">
        QuickFox超级VIP服务由福建紫讯信息科技有限公司旗下应用程序QuickFox加速器（以下亦称“QuickFox”）向您提供。QuickFox是一款面向海外华人和留学生打造专业的永久免费回国加速器，身在海外，一键加速回国，国内视频、音乐、游戏加速助手。<br />
        《QuickFox超级VIP服务协议》（以下亦称“本协议”）由您（以下亦称“会员”或“超级VIP”）和QuickFox进行缔结，对双方具有同等法律效力。QuickFox建议您仔细阅读本协议的全部内容，尤其是以加粗形式展示的，与您的权益（可能）存在重大关系的条款（包括相关约定QuickFox责任、您享有的权利、争议解决方式及司法管辖等条款），请您留意重点阅读。若您认为本协议中的加粗条款可能会导致您的部分或全部权利或利益受损，请您务必再次仔细阅读，在确保您已经理解、接受了加粗条款的前提下，继续使用QuickFox超级VIP服务。<br />
        如果您不同意本协议的任一或全部条款内容，请不要以确认形式（包括但不限于支付行为/接受赠与、或完成了成为会员的全部程序而在此过程中未向QuickFox提出关于本协议的任何异议、或使用超级VIP服务）进行下一步操作或使用QuickFox超级VIP服务。当您以确认形式进行下一步操作或使用QuickFox超级VIP服务时，即表示您与QuickFox已达成协议关系，您自愿接受本协议并遵守本协议项下的全部约定。<br />
        QuickFox有权变更本协议内容，一旦本协议内容发生变更的，QuickFox将在相应页面、站内信或以其他合理方式进行通知，请您仔细阅读。如果您不同意变更的内容的，您可以选择停止使用超级VIP服务。如您继续使用超级VIP服务的，则视为您已经同意变更的全部内容。更新后的协议自文首更新之日起生效。<br />
        若您是未成年人，请您在监护人监护、指导下阅读并决定是否同意本协议。<br />
    </div>
     <div style="line-height: ${$pc6dot4vw};">
        一、服务说明<br />
        1. <span
            style="color: rgb(236, 207, 171);">【QuickFox超级VIP】</span>是指完成了成为会员的所有程序，且在遵守本协议的前提下，在会员时长有效期内享受QuickFox提供的超级VIP服务的自然人。<br />
        2. <span
            style="color: rgb(236, 207, 171);">【QuickFox超级VIP服务】</span>是由QuickFox向您提供的专享的会员权益，具体权益内容说明请您查看本协议第四节。<br />
        3. <span
            style="color: rgb(236, 207, 171);">【QuickFox超级VIP服务协议】</span>是《QuickFox服务协议》、《QuickFox隐私政策》的补充协议，是其不可分割的一部分，与其具有同等法律效力；本协议如与前述协议发生冲突的，以本协议为准。<br />
        4. <span
            style="color: rgb(236, 207, 171);">【QuickFox超级VIP服务规则】</span>是QuickFox在QuickFox超级VIP中心已经或未来不时发布的与超级VIP服务相关的协议、公告、页面说明、通知、FAQ等内容。前述内容一经发布即生效，均构成本协议不可分割的一部分。<br />
         </div>
    <div style="line-height: ${$pc6dot4vw};">
        二、使用服务<br />
        1. 服务获取<br />
        1.1 QuickFox超级VIP服务为收费服务，您可以通过支付相应的服务费用购买；同时，您亦可以通过营销活动、接受礼品卡等QuickFox认可的途径获取服务。<br />
        1.2 您在获取QuickFox超级VIP服务时，应当遵守法律法规、本协议约定，不侵犯第三方或QuickFox的合法权益。您不得自行（或协助他人）通过以下方式获取超级VIP服务：<br />
        （1） 以商业性或其他非个人使用等目的；<br />
        （2） 通过机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件等方式；<br />
        （3） 未经QuickFox允许通过转移、赠与、借用、租用、销售、转让超级VIP服务的方式；<br />
        （4） 通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等）；<br />
        （5） 通过利用或破坏QuickFox超级VIP服务规则的方式。<br />
        <span
            style="color: rgb(236, 207, 171);">QuickFox在此声明：任何未经QuickFox明示授权而销售、转让QuickFox超级VIP资格的行为属于非法销售、非法转让，QuickFox有权追究其法律责任。</span><br />
        2. 服务使用的基本原则<br />
        2.1 您在使用QuickFox超级VIP服务的过程中，应遵守法律法规及其他规范性文件，包括但不限于《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《中华人民共和国网络安全法》、《信息网络传播权保护条例》等；应遵守公共秩序，尊重社会公德，不得危害网络安全，不得利用网络从事危害国家安全、荣誉和利益，煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，传播暴力、淫秽色情信息，编造、传播虚假信息扰乱经济秩序和社会秩序，以及侵害他人名誉、隐私、知识产权和其他合法权益等活动。在任何情况下，QuickFox一旦合理地认为您存在上述行为的，可以在任何时候，不经事先通知终止向您提供QuickFox超级VIP服务。<br />
        2.2 QuickFox授予您对QuickFox超级VIP服务一项个人的、非独家的、不可转让的、非商业用途的、可撤销的、有期限的使用许可。<span
            style="color: rgb(236, 207, 171);">即：您仅可出于个人、非商业的目的使用超级VIP服务。</span><br />
        3. 服务内容的变更<br />
        <spanstyle="color: rgb(236, 207, 171);">
            QuickFox有权根据法律法规及政策变更、版权方要求、自身运营策略变更对超级VIP服务内容（包括但不限于超级VIP权益细则、收费标准、收费方式）进行部分或全部变更。就前述变更，QuickFox将通过相应服务页面、站内信通知或以其他合理方式进行发布，并于发布之日起生效。发布后，您继续操作的行为（包括但不限于点击同意、或继续购买、或完成支付行为、或使用超级VIP服务等），均视为您已经接受前述变更。
            </spanstyle=><br />
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        三、您的账号<br />
        1. 账号获得<br />
        在您的超级VIP服务的有效期限内，您享有超级VIP权益的QuickFox账号即为您的QuickFox超级VIP账号（即：与您的超级VIP服务绑定的QuickFox账号，以下亦称“超级账号”或“会员账号”）。<br />
        同时，QuickFox在此善意提醒您，您应当在遵守QuickFox账号使用规则的前提下，使用您的会员账号。QuickFox建议您查阅《QuickFox服务协议》及相关子协议、各类公告、页面说明和规范流程、FAQ等以获得更多关于账号使用规则的内容。<br />
        2. 登录<br />
        除您以游客模式（具体说明见第三节第8条）购买QuickFox超级VIP服务的，QuickFox超级VIP服务需要您登录您的QuickFox超级VIP账号方可使用。<br />
        3. 账号管理及安全<br />
        &nbsp;&nbsp;3.1  您应自行负责并妥善、正确地保管、使用、维护您的超级VIP账号和密码，并对您的账号和密码采取必要和有效的保密措施。<span
            style="color: rgb(236, 207, 171);">非QuickFox法定过错导致的任何遗失、泄露、被篡改、被盗以及其他因保管、使用、维护不当而造成的损失，您应自行承担。</span><br />
        &nbsp;&nbsp;3.2  如果您发现有人未经授权使用了您的账号或您的账号存在其他异常情况导致无法正常登录使用的，则您需要按照QuickFox官方公布的账号找回流程进行账号找回。在找回过程中，QuickFox可能会要求您提供相应信息及/或证明资料，<span
            style="color: rgb(236, 207, 171);">请确保您所提供的内容真实有效，否则将可能无法通过QuickFox的验证而导致找回失败。</span><br />
        &nbsp;&nbsp;3.3  为保护账号安全，防止账号被盗等情况发生，QuickFox可能会不时或定期采用一种或多种方式对账号使用者进行用户身份验证（如短信验证、邮件认证等）、<span
            style="color: rgb(236, 207, 171);">如未成功通过验证的，QuickFox有合理理由怀疑该账号出现被盗等不安全情况，并视情节严重情况而决定是否中止向该账号继续提供会员服务及/或采取进一步措施。</span><br />
        &nbsp;&nbsp;3.4  请您特别注意，<span
            style="color: rgb(236, 207, 171);">您的超级VIP账号下的行为视为您本人的行为，您应对您的超级账号下发生的活动或通过该账号进行的活动负责。</span><br />
        4. 信息查询<br />
        您可以通过登录QuickFox超级VIP中心免费查询您的账号信息详情，包括已开通的超级VIP服务内容、服务期限、消费记录等。<br />
        5. 登录限制<br />
        &nbsp;&nbsp;5.1  <span
            style="color: rgb(236, 207, 171);">QuickFox超级VIP账号可在手机端、Pad端、电脑端以及上述终端相应的网页端使用，具体适用的设备终端以QuickFox实际提供为准。</span><br />
        &nbsp;&nbsp;5.2  为了您顺利使用QuickFox超级VIP服务，QuickFox在此建议您，您可以在产品中查阅、管理您的超级VIP账号的设备终端登录情况。<br />
        6. 账号独立<br />
        <span
            style="color: rgb(236, 207, 171);">您的超级账号仅限您本人出于非商业目的进行使用。除非QuickFox同意，会员权益不可在不同账号之间进行转移、迁徙、转让、赠与、售卖、租借、分享，即使前述不同账号由同一人拥有使用权。QuickFox在此善意提醒您，您在进行会员服务购买/参与活动时请注意区分，以免造成不必要的损失。</span><br />
        7. 权利归属<br />
        <span
            style="color: rgb(236, 207, 171);">QuickFox超级VIP账号、超级VIP账号下的超级VIP服务、虚拟产品（商品）及其涉及的产品及/或服务的所有权及相关知识产权归QuickFox所有或经过授权使用，您仅拥有前述产品及/或服务的有限使用权。但您经合法渠道取得的实体产品所有权、账号下归属于您的个人财产、QuickFox另有说明的其他权益除外。</span><br />
        8. 游客模式<br />
        &nbsp;&nbsp;8.1 <span
            style="color: rgb(236, 207, 171);">因苹果应用商店渠道允许非注册用户通过游客方式购买超级VIP服务，会员服务将会绑定您购买会员时所用的设备，超级VIP服务也将仅可在绑定设备上使用，且部分会员权益可能会因为您使用游客模式而无法享受（如活动参与）。若您设备丢失、损坏、进行系统升级、还原、清空或其他类似行为的，将会导致您无法继续使用绑定于此设备的超级VIP权益，由此造成的您的损失，您应当自行承担。</span><br />
        &nbsp;&nbsp;8.2 QuickFox在此建议您按照页面操作指引绑定您的QuickFox注册账号，以便可在您所拥有的其他设备上使用会员权益及更好的享受QuickFox所提供给您的会员权益。<br />
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        四、会员权益及额外付费相关<br />
        1. 超级VIP权益<br />
        <span
            style="color: rgb(236, 207, 171);">关于“超级VIP”赠送会员权益的特别说明：若您是QuickFox超级VIP，QuickFox还将赠送您其他QuickFox自有或合作的会员服务，具体赠送的会员权益类型、该等会员权益的具体内容及使用规则（包括适用设备终端）以相关会员协议约定及/或页面的说明为准。您的前述会员权益将直接提供至您绑定超级VIP账号的手机号所绑定的相关会员账号下（如：您QuickFox超级VIP绑定的手机号是12345678900，则您的QuickFox超级VIP权益将直接提供至手机号12345678900所绑定的超级VIP账号），且有效期自您开通QuickFox超级VIP服务（或升级成为QuickFox超级VIP）之日起算，因此QuickFox在此善意提醒您，请记得及时进行使用。</span><br />
        2. 设备及系统差异<br />
        您成为超级VIP后，可能会因您使用的软件版本、设备、操作系统等不同以及其他第三方原因导致实际可使用的具体权益或服务内容有差别，由此可能给您带来的不便，您表示理解且不予追究或豁免QuickFox的相关责任。QuickFox建议您可以通过升级应用程序或操作系统版本、更换使用设备等方式来尝试解决，或者直接通过本协议提供的方式与QuickFox联系进行解决。<br />
        <span
            style="color: rgb(236, 207, 171);">QuickFox在此特别提醒您，使用QuickFox超级VIP权益需要满足一定软件版本、设备及/或操作系统要求，因此，QuickFox建议您及时升级应用程序或操作系统版本、或更换使用设备以顺利的享受QuickFox超级VIP权益。</span><br />
        3. 虚拟产品<br />
        您在使用超级VIP服务过程中可能获得由我们提供的各种积分、卡券、优惠券、代金券、福利券、成长值等虚拟产品。前述虚拟产品仅能用于QuickFox官方渠道公布的指定用途，不能退货、兑换现金或转让、买卖、置换、抵押等，在使用后亦不会恢复。<br />
        同时，请您理解，QuickFox有权根据实际运营策略变更，变更其使用规则（包括使用有效期）。<br />
        4. 活动参与<br />
        在超级VIP服务期限内，您有权选择参加由QuickFox组织的超级VIP活动并享有由QuickFox提供的各项优惠。具体活动及优惠的内容及规则以QuickFox实际提供为准。<br />
        5. 优惠政策<br />
        QuickFox可能会根据实际运营情况对不同阶段已经购买且持续有效的超级VIP服务给予延期、权益升级等方面的优惠，具体优惠政策以QuickFox在相关服务页面通知的内容为准。<br />
        6. 使用规范<br />
        您在获取、使用QuickFox超级VIP服务(包括参与活动)时，应遵循QuickFox官方渠道公布的流程、平台规范等：<br />
        &nbsp;&nbsp;6.1 不得通过未经QuickFox授权或公布的途径、方式、渠道非法获取QuickFox超级VIP服务（包括但不限于部分或全部会员权益、超级VIP账号）及/或参与活动获取福利（包括但不限于会员权益、虚拟产品、实体商品、会员服务兑换码，本条下同）；<br />
        &nbsp;&nbsp;6.2 不得通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等违背QuickFox提供服务/举办活动的初衷的方式）参与活动或者获取福利。<br />
        <span
            style="color: rgb(236, 207, 171);">若您违反上述约定，则QuickFox有权对您所获得的福利作出删除、取消、清零等处理，且有权终止向您提供服务，由此造成的全部损失由您自行承担。</span><br />
        7.  服务期限<br />
        &nbsp;&nbsp;7.1  您的超级VIP服务的服务期限以您自行选择并支付相应会员费用对应的服务期限为准，自您成为超级VIP之时起算，您可以通过登录QuickFox超级VIP会员中心免费查询。当会员服务期限到期后，QuickFox将停止继续向您提供超级VIP服务；但如您开通自动续费服务且您在服务期限到期前续费成功的，会员服务期限将在原服务期限的基础上顺延。<span
            style="color: rgb(236, 207, 171);">自动续费的超级VIP服务在当前周期内的服务有效期到期前，您可以单方面停止自动续费，QuickFox亦可以根据运营策略需要而单方面停止继续向您自动续费服务，超级VIP服务期限自当前服务周期届满之日起终止。</span><br />
        &nbsp;&nbsp;7.2  <span
            style="color: rgb(236, 207, 171);">请您理解，超级VIP服务期限中包含QuickFox解决故障、服务器维修、调整、升级等或因第三方侵权处理所需用的合理时间，对上述情况所需用的时间或造成的任何损失（如有），QuickFox不予任何形式的赔偿/补偿，但QuickFox会尽可能将影响降至最低。</span><br />
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        五、收费及退订<br />
        1.  收费方式<br />
        QuickFox超级VIP服务为收费服务，您可通过QuickFox实际支持的付费方式完成超级VIP费用的支付，如银行卡支付、第三方支付等。请您注意，若您使用您的苹果账户或与您的会员账号绑定的通信账户进行支付，此付费方式为代收费运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险（如不法分子利用您账户或银行卡等有价卡等进行违法活动），该等风险可能会给您造成相应的经济损失，您应自行承担全部损失。<br />
        2.  费用退还<br />
        超级VIP服务系网络商品和虚拟商品，采用先收费后服务的方式，会员费用是您所购买的会员服务所对应的网络商品价格，而非预付款或者存款、定金、储蓄卡等性质，<span
            style="color: rgb(236, 207, 171);">超级VIP服务开通后，每个帐号可享受一次七天无理由退款服务，会员费用将原路退还至您的支付账户。超过退款次数和时限的订单将无法发起退款。</span><br />
        QuickFox在此特别提醒您，您在购买超级VIP服务（包括自动续费服务）之前应仔细核对账号信息、购买的服务内容、价格、服务期限等信息。<br />
        3.  收费标准、方式的变更<br />
        <span
            style="color: rgb(236, 207, 171);">超级VIP服务收费标准由QuickFox根据公司的运营成本、运营策略等综合考虑后独立决定（调整包括但不限于促销、涨价等），并在相关的产品服务宣传及支付页面向您展示；若您在购买和续费时，相关收费方式发生变化的，以QuickFox实际支持的收费方式为准；相关服务的价格发生了调整的，应以QuickFox平台上公示的现时有效的价格为准（但QuickFox与您另有约定的情形除外）。您同意您继续操作的行为（包括但不限于点击同意、或继续购买、或完成支付行为、或使用超级VIP服务、或进行额外付费等），即视为您知悉并同意变更后的收费方式、收费标准。</span><br />
        4.  自动续费<br />
        自动续费服务是指在会员已购买超级VIP服务的前提下，出于会员对于自动续费的需求，避免会员因疏忽或其他原因导致未能及时续费而推出的服务。如会员选择开通自动续费服务的，即会员授权QuickFox可在会员服务期限即将过期时或会员服务的订阅周期即将到期时，委托支付渠道从与您的自有充值账户、绑定的第三方支付账户、银行卡、通信账户等（以下统称“账户”）余额中代扣下一个计费周期的费用。该服务实现的前提是您已开通自动续费及绑定相关账户，且可成功从其上述账户中足额扣款。<br />
        4.1  计费周期：如月度、季度、年度等（具体以QuickFox提供的为准），会员可自行选择。<br />
        4.2  自动扣款规则：<br />
        &nbsp;&nbsp;（1）会员选择开通自动续费的，则视为同意授权QuickFox可在会员服务期限即将过期时，依据支付渠道的扣款规则发出扣款指令，并同意支付渠道可以根据QuickFox发出的扣款指令，在不验证会员账户密码、支付密码、短信校验码等信息的情况下从账户中扣划下一个计费周期的费用。苹果IOS渠道的自动扣费规则以苹果公司的规定为准。<br />
        &nbsp;&nbsp;（2）除非会员或QuickFox主动明确地取消了自动续费，否则，自动扣款长期有效、不受次数限制。<br />
        &nbsp;&nbsp;（3）<span
            style="color: rgb(236, 207, 171);">如果您未主动明确地取消自动续费，则将视为您同意QuickFox可依据支付渠道的扣款规则在会员服务期限到期后的一定期限内进行不时的扣款尝试（即使您账户内金额不足）。</span><br />
        &nbsp;&nbsp;（4）一旦扣款成功，QuickFox将为您开通本次计费周期对应的超级VIP服务。<br />
        4.3  取消方式：<br />
        &nbsp;&nbsp;（1）苹果移动设备端：苹果移动设备“APP Store” --> 点击右上角苹果账户头像，进入“账户”--> “订阅”，选择“QuickFox”取消订阅；<br />
        &nbsp;&nbsp;（2）安卓移动设备端：暂时不支持用户在客户端取消自动续费，需在支付平台自行取消；<br />
        您知悉并同意，自动续费状态更新可能存在显示延时，具体以QuickFoxAPP内显示的您的续费状态为准。<span
            style="color: rgb(236, 207, 171);">且您在成功取消前，已经委托QuickFox自动续费的扣款指令仍然有效，QuickFox对于基于该指令在取消操作完成前已经扣除的费用不予退还和补偿。</span><br />
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        六、您的行为规范和违约处理<br />
        1. <span style="color: rgb(236, 207, 171);">在您使用QuickFox超级VIP服务的过程中，不得存在以下行为：</span><br />
        &nbsp;&nbsp;1.1  未经QuickFox明确授权，通过技术手段对服务内容、服务期限、消费金额、交易状态等信息进行修改；<br />
        &nbsp;&nbsp;1.2  将超级VIP服务通过非QuickFox明确授权的方式（包括但不限于转移、赠与、借用、租用、销售、转让）提供他人使用；<br />
        &nbsp;&nbsp;1.3  对QuickFox用于保护超级VIP服务的任何安全措施技术进行破解、更改、反操作、篡改或其他破坏；<br />
        &nbsp;&nbsp;1.4  未经QuickFox事先书面同意，删除超级VIP服务内容上的任何所有权或知识产权声明或标签；<br />
        &nbsp;&nbsp;1.5  未经QuickFox明确授权，采用收费或免费的方式，在任何公开场合全部或部分展示超级VIP服务内容（但如您的上述行为不构成侵权的除外）；<br />
        &nbsp;&nbsp;1.6  其他未经QuickFox明示授权许可或违反本协议、法律法规或监管政策、侵犯第三方或QuickFox合法权益的行为。<br />
        <div style="color: rgb(236, 207, 171);">
            2.
            您知悉并同意，如您存在或QuickFox经独立判断后认为您存在任何违反国家法律法规或监管政策、违反本协议或有损QuickFox或/及其关联公司的声誉、利益的行为的，QuickFox有权独立决定采取以下一项或多项处理措施：<br />
            &nbsp;&nbsp;2.1  如本协议对此行为有单独条款约定处理方式的，按照该条款处理；<br />
            &nbsp;&nbsp;2.2  无需通知您而采取一种或多种措施制止您的行为及行为产生的后果，如删除/屏蔽相关链接或内容、限制/取消您的账号/账户使用权限等；<br />
            &nbsp;&nbsp;2.3  无需通知您而中断或终止部分或全部超级VIP服务，且您已交纳的超级VIP服务费用将不予退还且不获得任何形式的补偿/赔偿；<br />
            &nbsp;&nbsp;2.4  如您的行为使QuickFox或/及其关联公司遭受任何损失的，您应当承担全部损失赔偿责任并在QuickFox要求的时限内完成费用支付。 <br />
        </div>
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        七、服务的中断和终止<br />
        <p>
            1.   本服务的中断或终止包括如下情况：<br />
            &nbsp;&nbsp;1.1  您主动提出要求的；<br />
            &nbsp;&nbsp;1.2  您存在或QuickFox经独立判断后认为您存在任何违反国家法律法规或监管政策、违反本协议或有损QuickFox或/及其关联公司的声誉、利益的行为的；<br />
            &nbsp;&nbsp;1.3  QuickFox根据法律法规、监管政策的规定或有权机关的要求；<br />
            &nbsp;&nbsp;1.4  QuickFox为维护账号与系统安全等紧急情况之需要；<br />
            &nbsp;&nbsp;1.5  不可抗力（鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、病毒侵袭等影响互联网正常运行之情形）；<br />
            &nbsp;&nbsp;1.6  其他QuickFox无法抗拒的情况。<br />
        <div style="color: rgb(236, 207, 171);">
            2.   当发生前述终止的情况时，您与QuickFox均认可如下处理方式：<br />
            &nbsp;&nbsp;2.1  已经产生但未使用的超级VIP权益自动清除且不折现；<br />
            &nbsp;&nbsp;2.2  如您在QuickFox平台内有正在进行中的交易，QuickFox届时将视情况进行合理处理；<br />
            &nbsp;&nbsp;2.3  除法律法规另有规定或QuickFox另有说明外，QuickFox已收取的会员费用不予退还；<br />
            &nbsp;&nbsp;2.4  如因您违反本协议导致终止的，QuickFox有权视情况要求您承担相应的违约责任；<br />
            &nbsp;&nbsp;2.5  除法律法规另有规定或QuickFox另有说明外，QuickFox无需向您和第三人承担任何责任。<br />
        </div>
        </p>
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        八、您的个人信息保护<br />
        1.   QuickFox深知个人信息对您的重要性，因此QuickFox非常重视保护您的个人信息，亦将您的个人信息以高度审慎的义务对待和处理。在您使用QuickFox超级VIP服务的过程中，QuickFox将采用相关技术措施及其他安全措施来保护您的个人信息。<br />
        2.   未成年人保护<br />
        QuickFox非常注重未成年人的保护。<br />
        &nbsp;&nbsp;2.1  若您为未成年人，应在监护人监护、指导下阅读本协议，并且使用超级VIP服务已经得到监护人的同意。<br />
        &nbsp;&nbsp;2.2  <span
            style="color: rgb(236, 207, 171);">监护人应指导子女上网应该注意的安全问题，防患于未然。若监护人同意未成年人使用超级VIP服务，必须以监护人名义申请消费，并对未成年人使用超级VIP服务进行正确引导、监督。未成年人使用超级VIP服务，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。</span><br />
        &nbsp;&nbsp;2.3  QuickFox提醒未成年人在使用会员服务时，要善于网上学习，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。 <br />
        九、通知 <br />
        为便于您获知与本协议和超级VIP服务相关的信息，您同意QuickFox有权通过网页公示、页面提示、弹窗、消息通知、公众号通知、站内信、您预留的联系方式（如手机短信、电子邮件等）等一种或多种方式进行通知，该通知自QuickFox发送之时视为已成功送达您。如多种通知方式并存的，则送达时间以上述方式中最早发送之时为准。<br />
        此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注相应通知。<br />
         </div>
    <div style="line-height: ${$pc6dot4vw};">
        十、联系QuickFox<br />
        如您对本协议或使用超级VIP服务的过程中有任何问题（包括问题咨询、投诉等），QuickFox专门为您提供了多种反馈通道，希望为您提供满意的解决方案：<br />
        &nbsp;&nbsp;· 在线客服/其他在线意见反馈通道：您可与QuickFox平台上产品功能页面的在线客服联系或者在线提交意见反馈；<br />
        &nbsp;&nbsp;· 人工客服通道：您可以拨打QuickFox的任何一部客服电话与QuickFox联系；<br />
        &nbsp;&nbsp;· 其他方式：QuickFox提供的其他反馈通道。<br />
        我们会在收到您的反馈后尽快向您答复。<br />
         </div>
    <div style="line-height: ${$pc6dot4vw};">
        十一、其他<br />
        &nbsp;&nbsp;1. 本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。<br />
        &nbsp;&nbsp;2. <span
            style="color: rgb(236, 207, 171);">如就本协议的签订、履行等发生任何争议的，双方应尽量友好协商解决；协商不成时，任何一方均可向被告住所地享有管辖权的人民法院提起诉讼。</span><br />
        &nbsp;&nbsp;3. 如本协议因与中华人民共和国现行法律相抵触而导致部分无效的，不影响协议的其他部分的效力。<br />
        &nbsp;&nbsp;4. 本协议的标题仅为方便及阅读而设，并不影响正文中任何条款的含义或解释。<br />
    </div>
</div>`;

export default memberAgreementTxt;
