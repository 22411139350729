import { observable, action, computed } from 'mobx';
import { ICancelData } from '~/models';
import { Http, JudgePf } from '~/utils';
interface IUserInfo {
  // 时间类型 0分钟 1小时 2天
  timeType: 0 | 1 | 2;
  vipTime: string;
}

class UserStore {
  @observable token: string;
  @observable cancelData: ICancelData;
  @observable vip404: boolean
  @observable userInfo = {} as IUserInfo;
  
  @action
  async getUserInfo(params = {}) {
    const res = await Http.get('/user/info', { params });
    if (res?.code === 200) {
      this.userInfo = res?.data;
    }
    if (res?.code === 200 && res?.data?.accountType === 2) {
      return true
    }
    return false
  }
}

export default UserStore;
