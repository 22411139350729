import uaParser from 'ua-parser-js';

/**
 * 平台判断
 * @returns
 */
export function JudgePf() {
  const PCWEBVIEW_WIDTH = 410; //实际宽度是400
  let parser = new uaParser();
  let result = parser.getResult();
  let isAndroid = result.os.name === 'Android';
  let isIOS =
    (result.os.name === 'iOS' || result.os.name === 'Mac OS') &&
    result.browser.name === 'WebKit';
  //check ElectronStr
  let noElectronStr1 = result.ua.indexOf('Electron') == -1;
  let noElectronStr2 = result.browser.name.indexOf('Electron') == -1;
  let isPc =
    (result.os.name === 'Windows' || result.os.name === 'Mac OS') &&
    window.innerWidth > PCWEBVIEW_WIDTH &&
    noElectronStr1 &&
    noElectronStr2; //ua不包含'Electron'; browser.name不包含'Electron'
  let isPcApp = !isAndroid && !isIOS && !isPc;
  let isMac = isPcApp && result.os.name === 'Mac OS';
  let platformType = isIOS ? 0 : isMac ? 4 : isPcApp ? 2 : 1;

  return {
    isPcApp: isPcApp, //是否pc端app内嵌页 电脑浏览器手机模式也会被识别成这个
    isMacApp: isMac, //是否mac端app内嵌页
    isIOS: isIOS, //是否ios内嵌页
    isAndroid: isAndroid, //是否安卓内嵌页
    isPc: isPc, //是否pc浏览器(普通网页)展示
    platformType: platformType, //0:ios 1:android 2:pc app
  };
}
