import { observable, action } from 'mobx';

class Store {
  @observable langInit = false;
  @observable imgLoaded = false;

  @action
  setLangInit = (value: boolean) => {
    this.langInit = value;
  };

  @action
  setImgLoaded = (value: boolean) => {
    this.imgLoaded = value;
  };
}

export default Store;
