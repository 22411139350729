import { Cookie, postMessage, Tools, TrackEventName } from '.';
import intl from 'react-intl-universal';
import LocalData from './LocalData';
import { JudgePf } from './JudgePf';

const API_ENV = process.env.API_ENV;
const isLocal = API_ENV == 'local';

/**
 * 一些具体的原生通信
 */
export default {
  /**
   * 获取token
   * @param callback 后续操作
   */
  async getTokenFromNative(callback?: (token?: string) => void) {
    //windows挂载
    const setCookie = async (res: { token: string }) => {
      Cookie.setCookie('token', res.token);
      await callback?.(res.token);
    };
    window['setCookie'] = setCookie;

    // let param = null;
    // if (this.isVersionAfter('1.0.4')) param = { cbName: 'setCookie' };

    let res = await postMessage.send(
      'getToken',
      { cbName: 'setCookie' },
      async (token: string) => {
        Cookie.setCookie('token', token);
        await callback?.(token);
      }
    );
    return res;
  },
  /**
   * 获取token
   * @param callback 后续操作
   */
  async getEnterpriseSubAccount(callback?: (token?: string) => void) {
    //windows挂载
    const isEnterpriseSubAccount = async (res: { token: string }) => {
      await callback?.(res.token);
    };
    window['isEnterpriseSubAccount'] = isEnterpriseSubAccount;

    // let param = null;
    // if (this.isVersionAfter('1.0.4')) param = { cbName: 'setCookie' };

    let res = await postMessage.send(
      'enterpriseSubAccount',
      null
      // async (token: string) => {
      //   await callback?.(token);
      // }
    );
    console.log(111, res);
    return res;
  },
  async getUidFromNative() {
    const setUid = async (res: string) => {
      LocalData.setItem('uid', res);
    };
    window['setUid'] = setUid;
    let res = await postMessage.send('getUid', null, () => {
      LocalData.setItem('uid', res);
    });
    return res;
  },
  /**
   * 是否审核状态
   * @param callback
   * @returns
   * */
  async ngetIsReview(callback?: (res?: string) => void) {
    //window挂载：
    const isReviewRes = async (res: string) => {
      LocalData.setItem('isReviewRes', res);
      await callback?.(res);
    };
    window['isReviewMode'] = isReviewRes;

    let res = await postMessage.send(
      'getIsReview',
      null,
      async (res: string) => {
        await callback?.(res);
      }
    );
    return res;
  },
  // 是否是企业子账号
  async getIsEnterpriseSubAccount(callback?: (res?: string) => void) {
    //window挂载：
    const isEnterpriseSubAccount = async (res: string) => {
      LocalData.setItem('isReviewRes', res);
      await callback?.(res);
    };
    window['isEnterpriseSubAccount'] = isEnterpriseSubAccount;
    let res = await postMessage.send(
      'enterpriseSubAccount',
      null,
      );
    console.log(res)
    return res;
  },
  /**
   * 获得版本号(和设备码)
   * @param callback 后续操作
   * @returns
   */
  async getVersionFromNative(
    callback?: (data?: { version: string; equipment: string }) => void
  ) {
    //window挂载：
    const getVersionFromClient = async (data: {
      version: string;
      equipment: string;
    }) => {
      LocalData.setItem('version', data?.version);
      LocalData.setItem('deviceCode', data?.equipment);
      await callback?.(data);
    };
    window['getVersionFromClient'] = getVersionFromClient;

    let res = await postMessage.send(
      'getVersion',
      null,
      async (data: { version: string; equipment: string }) => {
        LocalData.setItem('version', data?.version);
        LocalData.setItem('deviceCode', data?.equipment);
        await callback?.(data);
      }
    );
    return res;
  },
  /**
   * 获得渠道
   * @param callback
   * @returns
   */
  async getMarketPlaceFromNative(
    callback?: (marketPlaceName?: string) => void
  ) {
    //window挂载：
    const responseMarketPlace = async (marketPlaceName: string) => {
      let mkName = marketPlaceName;
      if (mkName === 'google') mkName = 'google_new';
      LocalData.setItem('marketPlace', mkName);
      await callback?.(mkName);
    };
    window['responseMarketPlace'] = responseMarketPlace;

    let res = await postMessage.send(
      'requestMarketPlace',
      null,
      async (marketPlaceName: string) => {
        let mkName = marketPlaceName;
        if (mkName === 'google') mkName = 'google_new';
        LocalData.setItem('marketPlace', mkName);
        await callback?.(mkName);
      }
    );
    return res;
  },
  /**
   * 获得安卓审核模式
   * @param callback
   * @returns
   */
  async getAndroidReview(callback?: (res?: string) => void) {
    //window挂载：
    const androidReviewRes = async (res: string) => {
      LocalData.setItem('androidReview', res);
      await callback?.(res);
    };
    window['androidReviewRes'] = androidReviewRes;

    let res = await postMessage.send(
      'getAndroidReview',
      null,
      async (res: string) => {
        LocalData.setItem('androidReview', res);
        await callback?.(res);
      }
    );
    return res;
  },
  getToken() {
    return Cookie.getCookie('token');
  },
  getVersion() {
    return LocalData.getItem('version');
  },
  getDeviceCode() {
    //equipment
    return LocalData.getItem('deviceCode');
  },
  getMarketPlace() {
    return LocalData.getItem('marketPlace');
  },
  getUid() {
    return LocalData.getItem('uid');
  },
  /**
   * 是否审核状态 目前只有ios用到
   * @param callback 后续操作
   */
  getIsReviewFromNative(callback?: (data?: { isReview: boolean }) => void) {
    const isReviewFromClient = async (data: { isReview: boolean }) => {
      LocalData.setItem('isReview', data.isReview);
      await callback?.(data);
    };
    window['isReviewFromClient'] = isReviewFromClient;
    postMessage.send('nowIsReview', null);
  },
  getIsReview() {
    return LocalData.getItem('isReview');
  },
  /**
   * 当前版本是否大于等于目标版本
   * @param targetVersion 目标版本
   * @param nowVersion 当前版本
   * @returns
   */
  isVersionAfter(targetVersion: string, nowVersion?: string) {
    try {
      let version = nowVersion ?? LocalData.getItem('version');
      console.log(targetVersion, 'version', version);
      if (!version) {
        console.log('没有取到version！');
        return false;
      }
      let targetArr = targetVersion.split('.');
      let nowArr = version.split('.');
      //todo
      return (
        Number(nowArr[0]) > Number(targetArr[0]) ||
        (Number(nowArr[0]) == Number(targetArr[0]) &&
          Number(nowArr[1]) > Number(targetArr[1])) ||
        (Number(nowArr[0]) == Number(targetArr[0]) &&
          Number(nowArr[1]) == Number(targetArr[1]) &&
          parseInt(nowArr[2]) > parseInt(targetArr[2])) ||
        (Number(nowArr[0]) == Number(targetArr[0]) &&
          Number(nowArr[1]) == Number(targetArr[1]) &&
          parseInt(nowArr[2]) == parseInt(targetArr[2]))
      );
    } catch (e) {
      console.log('判断版本出错！' + e);
      return false;
    }
  },
  /**
   * 页面跳转
   * @param title 页面标题
   * @param url
   * @param outbound 是否跳外链
   * @param jumpFunc 回调
   * @param pcNotice pc端提示
   * @returns
   */
  goto(
    title: string,
    url: string | number,
    outbound = false,
    jumpFunc?: () => void,
    pcNotice?: string
  ) {
    try {
      //开发环境
      // if (process.env.API_ENV === 'local') {
      //   if (!isNaN(Number(url))) {
      //     if (url == 2) location.href = '/welfare';
      //   } else location.href = url.toString();
      //   return;
      // }

      const os = JudgePf();
      //pc端跳转邀请好友时弹出提示 并且不跳转
      // if (
      //   (url === '/inviteV4' ||
      //     url.toString().includes('/inviteV4')) &&
      //   os.isPcApp
      // ) {
      //   postMessage.send(
      //     'setH5ModalText',
      //     pcNotice || intl.get('task.taskCard.isPcAppNotice')
      //   );
      //   postMessage.send('openH5Modal', null);
      //   return;
      // }

      if (outbound) {
        if (title === 'clientUrl') {
          postMessage.send(os.isPcApp ? 'openInBrowser' : 'jumpOutboundLink', {
            url: url,
          });
          return;
        }
        postMessage.send(os.isPcApp ? 'openInBrowser' : 'jumpOutboundLink', {
          url: url,
        });
        return;
      }
      //跳原生页面
      if (!isNaN(Number(url))) {
        console.log('准备跳原生', url, 197);
        if((url == 2) && os.isIOS) {
          //兼容ios无法跳转福利页
          postMessage.send('goToNewWebView', { name: '', url: 'welfare' });
        } else if ((url == 3) && os.isAndroid && !this.isVersionAfter('3.25.2')) {
          // 兼容低版本安卓不支持通过jumpNativePage跳转美洽
          postMessage.send('callMeiqia', { visible: true });
        } else if ((url == 3) && os.isMacApp && !this.isVersionAfter('3.0.7')) {
          // 兼容低版本安卓不支持通过jumpNativePage跳转美洽
          postMessage.send('callMeiqia', { visible: true });
        } else {
          postMessage.send('jumpNativePage', { type: url });
        }
        console.log('跳了原生', url, 197);
        return;
      }
      let url_ = url.toString();

      const reg = /(http|https):\/\/([\w.]+\/?)\S*/gi;
      if (!url_.match(reg)) {
        //不是http/https开头
        let adressArr = url_.split('/');
        url_ = adressArr[adressArr.length - 1];
      }
      if (os.isPcApp) {
        location.href = url_;
      } else {
        postMessage.send('goToNewWebView', {
          name: title,
          url: url_,
        });
      }
    } catch (e) {
      console.log('跳转报错！' + e);
    }
  },
  /**
   * 埋点统计
   * @param eventName 事件名
   * @param des 事件描述
   * @param type 时间类型 如click,pv等 默认click
   */
  trackEvent(eventName: string, des: string, type?: string, needPrefix=true) {
    const alertArr = ['ZhuXiao_PV'];
    try {
      //暂时设置本地开发运行时不发送埋点数据
      if (isLocal) return;
      const os = JudgePf();
      if (os.isPcApp) {
        //移动端特有的埋点名 不触发埋点事件
        if (TrackEventName.mobile_special.has(eventName)) return;
        //@ts-ignore
        analyticsPC.logEvent('PC_' + eventName, {
          operation: type ?? 'click',
          description: des,
          // targetRouter: '/pointEvent',
          // pageRouter: '/notice',
        });
      } else {
        if (alertArr.includes(eventName))
          if (TrackEventName.pc_special.has(eventName))
            // Tools.alertDev('发送埋点 ' + eventName);
            //pc特有的埋点 移动端不管
            return;
        postMessage.send('H5Monitor', {
          type: needPrefix?`APP_${eventName}`: eventName,
          event: des,
        });
      }
    } catch (e) {
      console.log('埋点报错！' + e);
    }
  },
  /**
   * showRightItem
   * @param params type: number; title: string; url: string; funcName?: string; list[];
   * @param callBackParams type:0跳转 1其他 5会员退款 默认0  callback:回调方法
   * @returns null 或 Function(calltype 为0 时)
   */
  async showRightItem(
    params: {
      type: number;
      title: string;
      url: string;
      funcName?: string;
      list?: { type: number; title: string; url: string; funcName?: string }[];
    },
    callBackParams?: {
      type: number; //0跳转 1其他 默认0 5会员退款
      callback: (event?: any, json?: any) => void;
    }
  ) {
    postMessage.send('showRightItem', params);
    if (!callBackParams) return null;
    const os = JudgePf();
    if (!os.isPcApp) return null;
    //如果是pc端应用 就调回调方法
    const { type, callback } = callBackParams;
    if (type === 0) {
      let unsubscribe = await postMessage.response('goToPage', callback);
      return unsubscribe;
    } else {
      callback?.();
      return null;
    }
  },
  async setNativeCallback(
    cbfuncName: string,
    callback: (event: any, json: any) => void
  ) {
    const os = JudgePf();
    if (os.isPcApp) {
      let unsubscribe = await postMessage.response(cbfuncName, callback);
      return unsubscribe;
    } else window[cbfuncName] = callback;
  },
  async initPage(callback: (event: any, json: any) => void) {
    const os = JudgePf();
    if (os.isPcApp) {
      let unsubscribe = await postMessage.response('initPage', callback);
      return unsubscribe;
    }
  },
  async initActivationRecordStates(callback: (event: any, json: any) => void) {
    const os = JudgePf();
    if (os.isPcApp) {
      let unsubscribe = await postMessage.response(
        'initOrderPageStates',
        callback
      );
      return unsubscribe;
    }
  },
  async callMeiqia(visible: boolean) {
    postMessage.send('callMeiqia', { visible });
  },
};
