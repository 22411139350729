import React, {
  FunctionComponent,
  useState,
  useLayoutEffect,
  useRef,
} from 'react';
import intl from 'react-intl-universal';
import { JudgePf } from '~/utils';
import { PullToRefresh } from 'antd-mobile5';
import { ListView } from 'antd-mobile';
import Style from './style.scss';

interface IProps {
  dataSource?: object[]; // 数据源
  onLoad?: (page?: number, size?: number, refresh?: boolean) => void; // 上拉加载
  loading?: boolean; // loading状态
  hasMore?: boolean; // 是否还有更多
  children?: any;
  onRefresh?: () => void; // 刷新方法
  pullToRefresh?: boolean;
  row?: (data: Object, idx: number, key: number) => void; // 每行渲染数据
  listFooterStyle?: {};
  className?: string;
  fullHeight?: boolean; //该列表是否占满全屏高度
}
const PC_WvHeight = 611;
const ScrollList: FunctionComponent = (props: IProps) => {
  // const [height, setHeight] = useState(document.documentElement.clientHeight);

  const [refreshing, setRefreshing] = useState(false);

  const {
    dataSource,
    loading,
    hasMore,
    onLoad,
    onRefresh,
    row,
    pullToRefresh,
  } = props;

  const el = useRef();

  //弃用，在pc端有问题：pc端 没在当前页面 但 刷新了页面的时候，（由于webview高度为0）导致列表高度为0，看上去没加载
  // useLayoutEffect(() => {
  //   //因为PC端会将wv高度设为0 这会导致全屏的列表没有高度，所以在PC端时将全屏列表写死高度为611.
  //   //目前pc端暂无调整窗口尺寸的需求 如果以后有变动 就不能写死高度了
  //   const hei =
  //     props.fullHeight && JudgePf().isPcApp
  //       ? PC_WvHeight
  //       : document.documentElement.clientHeight;

  //   const effsetTop = el.current.offsetTop;
  //   setHeight(hei - effsetTop);
  // }, []);

  const onEndReached = async (event) => {
    if (!hasMore) return;
    setRefreshing(true);
    await onLoad();
    setRefreshing(false);
  };

  return (
    <div
      className={`${Style.lv}${props.className ? ` ${props.className}` : ''}`}
      ref={el}
      style={{
        height: `${
          (props.fullHeight && JudgePf().isPcApp
            ? PC_WvHeight //是撑满高度 且 是pc端
            : document.documentElement.clientHeight) -
          (el?.current?.offsetTop ?? 0)
        }px`,
      }}
    >
      <ListView
        renderFooter={() => (
          <div
            style={{
              padding: 20,
              textAlign: 'center',
              ...props.listFooterStyle,
            }}
          >
            {loading
              ? intl.get('tips.loading')
              : dataSource.length === 0
              ? intl.get('tips.nodata.forNow')
              : intl.get('tips.load.success')}
          </div>
        )}
        dataSource={new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2,
        }).cloneWithRows(dataSource)}
        renderRow={row}
        style={{
          height: '100%',
          overflow: 'auto',
        }}
        onEndReached={onEndReached}
        pageSize={10} // 每次渲染的行数
        onEndReachedThreshold={20} // 调用onEndReached之前的临界值，单位是像素
        pullToRefresh={
          pullToRefresh ? (
            <PullToRefresh
              refreshing={refreshing}
              onRefresh={onRefresh ?? (() => {})}
              // indicator={{ deactivate: 'xx' }}
              renderText={(status) => {
                return (
                  <div>
                    {
                      {
                        pulling: intl.get('tips.pull2Refresh'),
                        canRelease: intl.get('tips.release2Refresh'),
                        refreshing: intl.get('tips.refreshing'),
                        complete: intl.get('tips.refreshed'),
                      }[status]
                    }
                  </div>
                );
              }}
            />
          ) : (
            false
          )
        }
      />
    </div>
  );
};

export default ScrollList;
