import RootStore from '~/stores';
import CryptoJS from 'crypto-js';

const key = 'L8nq7g7RnDR6WCVknN88';
const key2 = key.substring(0, 16);
const iv2 = key.slice(-16);
const key1 = key.substring(3, 19);
const iv1 = key.substring(4, 20);
export const HttpEncryption = {
  encrypt: (word) => {
    return RootStore.getInstance().coded.secretor.encode(
      JSON.stringify(word || {}),
      key1,
      iv1
    );
  },
  decrypt(word) {
    return RootStore.getInstance().coded.secretor.decode(word, key2, iv2);
  },
};

const LocalDataKey = '5bj6PNkQ8aleI5MNgj6r';
const LocalData_KEY2 = LocalDataKey.substr(3, 16);
const LocalData_IV2 = LocalDataKey.substr(4);
export const LocalDataEncryption = {
  encrypt_AES: (word) => {
    var key = CryptoJS.enc.Utf8.parse(LocalData_KEY2);
    var iv = CryptoJS.enc.Utf8.parse(LocalData_IV2);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  },
  decrypt_AES(word) {
    var key = CryptoJS.enc.Utf8.parse(LocalData_KEY2);
    var iv = CryptoJS.enc.Utf8.parse(LocalData_IV2);
    var decrypt = CryptoJS.AES.decrypt(word, key, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  },
};
