import memberAgreementTxt from './memberAgreementTxt-tw';
import monthlyVipAgreementTxt from './monthlyVipAgreementTxt-tw';

// 關於我們
const about = {
  'about.tab.title': '關於我們',
  'about.welcome': `歡迎來到QuickFox`,
  'about.list.check': '檢查更新',
  'about.list.contact': '聯繫我們',
  'about.list.like': '給個好評',
  'about.list.terms': '服務條款',
  'about.list.policy': '隱私協議',
  'about.list.vip': '會員服務協議',
};

// 聯繫我們
const contact = {
  'contact.tab.title': '聯繫我們',
  'contact.title': '您可以通過以下方式和我們取得聯繫',
  'contact.list.offcial': '官方網站',
  'contact.list.wx': '微信公眾號',
  'contact.list.qq': '客服QQ',
  'contact.list.qqgroup': '官方QQ群',
  'contact.list.wxkf': '技術支持微信',
  'contact.list.email': '郵箱',
  'contact.list.op.visit': '去訪問',
  'contact.list.op.copy': '複製號碼',
  'contact.list.op.copyAccount': '複製帳號',
  'contact.list.op.copy.mail': '複製地址',
  'contact.copy': '複製成功',
};

// 幫助
const help = {
  'help.tab.title': '幫助',
  'help.title': '需要人工幫助？點擊聯繫在線客服',
  'help.contact': '立即聯繫',
  'help.head.speedUp': '加速',
  'help.head.recharge': '充值',
  'help.head.other': '其他',
  'help.pv': '幫助頁瀏覽量',
  'help.speed': '加速',
  'help.other': '其他',
  'help.vip': '會員',
};

// 幫助詳情
const helpDetail = {
  'helpDetail.tab.title': '詳情',
  'helpDetail.about': '相關問題',
};

const notice = {
  'notice.tab.title': '消息中心',
  'notice.nodata': '暫無任何消息哦~',
  'notice.numberOfMsgJumpClick': '站內信按鈕跳轉到會員頁的點擊量',
  'notice.getItRightnow': '點擊領取',
  'notice.allReadyGetIt': '已領取',
  'notice.getItSuccessfully': '領取成功',
};

// 邀請
const invite = {
  'invite.tab.title': '邀請送會員',
  'invite.title.quickfoxExclusiveInvitation': 'QuickFox專屬邀請',
  'invite.title.friendsSendYou3DaysQuickfoxFreeMembership':
    '好友送您3天QuickFox免費會員',
  'invite.title.whenBindingFillInTheInvitationCodeBelowToGet':
    '註冊時填寫下方的邀請碼即可獲得',
  'invite.title.oneClickHomeToEnjoyTheDomesticNetwork': '回國加速 快人一步',
  'invite.title.globalNode': '全球節點',
  'invite.title.games&Video': '遊戲&影音',
  'invite.title.longTermStability': '長久穩定',
  'invite.title.7x24Hours': '7X24小時',
  'invite.title.specialLineBackToChina': '專線回國',
  'invite.title.smoothOperator': '暢通無阻',
  'invite.title.neverDropTheLine': '永不掉線',
  'invite.title.carefreeService': '無憂服務',
  'invite.title.numberOfPeopleYouInvited': '您邀請的人數',
  'invite.title.daysYouGet': '您獲得的天數',
  'invite.title.inviteYourFriendsToUseQuickfox': '邀請您的朋友一起使用QuickFox',
  'invite.title.yourExclusiveInvitationCode': '您的專屬邀請碼',
  'invite.title.rule1': '1.當您的邀請碼被新用戶使用,雙方都會獲得3天會員獎勵',
  'invite.title.rule2': '2.邀請碼在新用戶綁定界面填寫',
  'invite.title.rule3':
    '3.獎勵天數會累加,使用您的邀請碼人數越多,您獲得的獎勵天數也會越多',
  'invite.title.rule4': '4.同一設備僅限一個帳號領取',
  'invite.btn.copy': '複製邀請碼',
  'invite.btn.shareWithFriends': '分享給朋友',
  'invite.btn.iOSDownload': 'IOS下載',
  'invite.btn.androidDownload': 'Android下載',
};

// 邀請
const inviteDetail = {
  'inviteDetail.tab.title': '邀請送會員',
};

// 線路
const lines = {
  'lines.tab.title': '線路選擇',
  'lines.title.free': '空閒',
  'lines.title.normal': '正常',
  'lines.title.crowding': '擁擠',
  'lines.title.fiery': '火爆',
  'lines.title.maintain': '維護',
  'lines.tabs.gameLine': '遊戲模式',
  'lines.tabs.soundAndShadowCircuit': '影音模式',
  'lines.btn.quickConnect': '快速連接',
};

// 購買
const purchase = {
  'purchase.tab.title': '購買會員',
  'purchase.title.discount': '折',
  'purchase.title.originalPrice': '原價',
  'purchase.title.asLowAs': '低至',
  'purchase.title.tag.us': '$',
  'purchase.title.tag.cn': '￥',

  'purchase.title.silverMember': '白銀會員',
  'purchase.title.goldMember': '黃金會員',
  'purchase.title.upTo50m/S': '高達50m/s',
  'purchase.title.gameLine': '遊戲專線',
  'purchase.title.audioVisualSpecialLine': '影音線路',
  'purchase.title.modeIntelligentSwitching': '模式智能切換',
  'purchase.title.antiDelayTechnology': '抗延遲技術',
  'purchase.title.privacyEncryption': '隱私加密',
  'purchase.title.upTo100M/S': '高達100m/s',
  'purchase.title.exclusiveCustomerService': '專屬客服',
  'purchase.title.moreExclusiveLines': '更多獨享專線',
  'purchase.title.pleaseSelectPaymentMethod': '請選擇支付方式',
  'purchase.title.privilegeDescription': '特權說明',
  'purchase.title.alipay': '支付寶',
  'purchase.title.weChat': '微信',
  'purchase.btn.buyNow': '立即購買',
  'notice.numberOfMsgJumpClick': '站內信按鈕跳轉到會員頁的點擊量',
};

// Http
const http = {
  'http.prompt.networkError': '網絡錯誤',
};

const memberServiceAgreement = {
  'memberServiceAgreement.tab.title': '會員服務協議',
  'memberServiceAgreement.content': memberAgreementTxt,
};

const monthlyVipAgreement = {
  'monthlyVipAgreement.tab.title': '連續包月協議',
  'monthlyVipAgreement.content': monthlyVipAgreementTxt,
};

// 服務條款
const clause = {
  'clause.tab.title': '服務條款',
  'clause.title': 'QuickFox用戶服務條款',
  'clause.versionInfo': 'V1.0版本生效時間2020年1月1日',
  'clause.t1': '歡迎來到QuickFox',
  'clause.t1.p1':
    '本服務條款文檔（以下簡稱「條款」 ）概述了QuickFox 提供的服務使用條款和條件。這些條款還規定了 QuickFox 內容的使用和訪問,其中包括QuickFox網站,應用程式以及QuickFox提供的任何軟體 。',
  'clause.t1.p2': '同意這些條款,即表示您同意隱私政策,您可以在此處閱讀。',
  'clause.t2': '1.接受',
  'clause.t2.p1':
    '訪問內容或服務時,您代表您自己或您代表的人同意遵守這些條款並受其法律約束。這些條款構成您與QuickFox之間具有法律約束力的協議。如果您不同意本條款的任何部分,則不得使用我們的服務。',
  'clause.t2.p2':
    '通過創建使用我們服務的帳戶,您表示您已年滿十八（18）歲或您是有效的法律實體,並且您提供的註冊信息準確完整。',
  'clause.t3': '2.修改',
  'clause.t3.p1':
    'QuickFox可能會不時更新本條款,恕不另行通知。如果您在這些更改生效後繼續使用QuickFox的服務,內容,網站,應用程式或軟體,則表示您同意修訂後的條款。本條款的當前版本可在本網站上獲得。您理解並同意,您有義務不時查看這些條款,以便隨時了解當前的規則和義務。有關條款的任何核心更改的通知將通過電子郵件或本產品更新提供給購買者。您在更改這些條款後使用內容或服務即表示您接受更改後的條款。',
  'clause.t4': '3.隱私政策',
  'clause.t4.p1':
    'QuickFox致力於保護您的隱私,不會收集或記錄連接到我們產品的購買者的瀏覽歷史記錄,流量目標,數據內容或 DNS 查詢。在您註冊期間,我們可能會收集一些敏感的個人信息,例如您的電子郵件地址和付款信息。我們僅收集正確交付網站和服務所需的信息。',
  'clause.t4.p2':
    '為了清晰和透明,我們將與數據收集相關的所有信息放在一個稱為隱私政策的單獨文件中,該文件可在網站上找到。請完整閱讀隱私政策,以清楚了解我們如何處理您的敏感數據。',
  'clause.t5': '4.購買',
  'clause.t5.p1':
    '您可以在本產品上註冊QuickFox服務。購買服務即表示您同意在您選出的期間成為購買者。產品上提供了完整的購買計劃和定價清單。 QuickFox 保留在產品上發布合理預先通知或通過電子郵件發送的任何時候修改購買費或提出新費用的權利。定價的任何更改都不會影響購買者的當前購買期,並將在續訂購買時生效。',
  'clause.t5.p2':
    '如果您的付款方式支持,計劃會在結算期限結束時自動續訂。默認情況下,續訂期限與原始購買的結算期限相同。購買費將自動按您上次選擇的付款方式收取。如果您想停止自動續訂,您可以登錄本產品並關閉自動續訂。默認情況下,當您使用支持自動續訂的付款方式時,會啟用自動續訂,並在使用不支持自動續訂的付款方式時關閉自動續訂。',
  'clause.t6': '5.退款政策',
  'clause.t6.p1':
    '如果您對QuickFox的服務不滿意並通過信用卡付款,您可以隨時聯繫我們取消購買。或者,您可以在訂單日期的3天內申請退款。要提交退款,請通過客服聯繫我們。此過程需要7個工作日才能完成。請注意,只有信用卡付款支持3天退款保證,其他付款方式不支持退款政策。',
  'clause.t6.p2': `如果選擇退款,請將以下信息提交給客服：
      QuickFox有效帳號；
      您的信用卡號碼；
      退款的原因；
      如果發生惡意退款,我們會將您的信息提交到信用黑名單系統,這可能會降低您的信用並對您以後的付款和購買產生負面影響。`,
  'clause.t7': '6.可接受的使用政策',
  'clause.t7.p1':
    '您了解自己有責任對您的QuickFox帳戶信息保密。您負責帳戶下的所有活動。如果您發現或懷疑某人未經您的授權訪問了您的帳戶,建議您立即通知我們,以便我們撤銷您的帳戶憑據並發布新帳戶憑據。',
  'clause.t7.p2':
    'QuickFox旨在為所有用戶提供最佳服務。從這個意義上說,我們要求您不要濫用我們的內容或服務。濫用是指違反本條款或適用法律法規對內容或服務的任何使用,訪問或干擾。',
  'clause.t7.p3':
    '為了保護服務不被濫用或用於傷害某人,QuickFox保留在違反本條款和適用法律的情況下使用我們的服務時採取適當措施的權利。您同意,如果您濫用本服務, QuickFox 可能會終止您的帳戶,而不會對已支付的服務提供退款。',
  'clause.t7.p4': `在使用我們的服務時,您將同意：
      通過服務發送或傳輸未經請求的廣告或內容（即「垃圾郵件」）。
      通過服務發送,發布或傳輸任何非法,仇恨,威脅,侮辱或誹謗的內容 ; 侵犯智慧財產權;侵犯隱私 ; 或煽動暴力。
      在未事先獲得專有內容所有者的許可的情況下,上傳,下載,發布,複製或分發受版權或任何其他所有權保護的任何內容。
      上傳,下載,發布,複製或分發任何包含未成年人性或外觀描述的內容。
      從事任何限制或禁止任何其他訂戶使用或享受本服務的行為。
      未經適當授權（即任何形式的「黑客攻擊」）嘗試訪問,探測或連接到計算設備。
      嘗試編譯,使用或分發 QuickFox 運行的IP位址列表以及服務。
      將本服務用於合法目的之外的任何其他用途。`,
  'clause.t8': '7.許可證',
  'clause.t8.p1':
    '在您遵守這些條款的前提下,QuickFox將向您授予下載和使用本軟體的非獨占且有限的許可。未經 QuickFox 明確授權,嚴禁以任何方式修改,分發給未授權方,進行逆向工程或以其他方式使用本軟體。',
  'clause.t8.p2':
    '除非您獲得QuickFox的明確書面同意,否則禁止使用受 QuickFox 智慧財產權保護的任何材料。',
  'clause.t9': '8.第三方網站',
  'clause.t9.p1':
    'QuickFox可能會向您提供屬於第三方的內容或指向第三方網站的連結。 QuickFox 不對第三方提供的內容的可用性負責,因為它們不受QuickFox的控制或監督,並且它們可能具有不同的使用條款和政策。您通過我們的服務訪問第三方提供的任何網站,服務或內容並不表示 QuickFox 與此類第三方之間存在任何關係。',
  'clause.t10': '9.免責聲明',
  'clause.t10.p1':
    '我們將努力防止網站和服務中斷。但是,這些是按「原樣」和「現有」的原則提供的,我們不保證,無論是明示還是暗示,通過本網站或服務提供的任何材料或信息的準確性,或它們是否適合任何特定的目的。我們明確拒絕承擔任何明示或暗示的擔保,包括但不限於適銷性或適用於特定用途或不侵權的擔保。我們不保證服務符合您的要求,也不保證不會中斷,及時,安全或沒有錯誤,或者如果有任何缺陷將得到糾正。您承認您自行承擔風險和自行決定訪問本網站和服務。',
  'clause.t10.p2':
    '產品服務範圍,速度,伺服器位置和質量可能會有所不同。 QuickFox 將嘗試始終提供服務。但是,該服務可能因我們無法控制的各種因素而無法使用,包括但不限於緊急情況;第三方服務失敗 ; 或傳輸,設備或網絡問題或限制,干擾或信號強度;並且可能被打斷,拒絕,限制或縮減。由於服務,通信服務或網絡的中斷或性能問題,我們不對丟失,未交付,延遲或誤導的數據,消息或頁面負責。我們可以自行決定強制使用或服務限制,暫停服務,終止帳戶或阻止某些使用,以保護用戶或服務。收到的數據的準確性和及時性無法保證;可能會出現延誤或遺漏。',
  'clause.t10.p3':
    'QuickFox保留調查我們認為違反這些條款的事項的權利。我們可以（但沒有義務）自行決定刪除,阻止,過濾或限制我們認為實際或可能違反這些條款中規定的限制的任何材料或信息,以及可能使 QuickFox 或我們的客戶承擔責任的任何其他活動。對於我們未能防止此類材料或信息通過服務和/或計算設備傳輸, QuickFox 不承擔任何責任。',
  'clause.t11': '10.責任限制',
  'clause.t11.p1': `對於因以下情況而導致的任何損失或損害,QuickFox不承擔任何責任,也不對任何用戶或其他個人承擔任何責任：
      任何產品或服務的失敗或中斷;
      任何參與製作本產品或服務的第三方或其中包含的數據的任何作為或不作為;
      與您訪問或使用,或無法訪問或使用本產品的任何部分或其內容有關的任何其他原因;
      您在本產品或服務上的互動;
      您未能遵守本協議;
      採購替代商品或服務的費用;要麼未經授權訪問或更改您的傳輸或數據,無論引起此類原因的情況是否在QuickFox或為本產品或服務提供軟體,服務或支持的任何供應商的控制範圍內。
      在任何情況下,QuickFox,其合作夥伴,附屬機構,子公司,成員,官員或員工均不對任何直接,特殊,間接,後果性或附帶損害或任何其他任何類型的損失或損害承擔責任,即使他們有被告知其可能性。上述規定不適用於適用法律禁止的範圍。`,
  'clause.t12': '11.最終條款',
  'clause.t12.p1':
    '如果本協議中的任何條款被視為無效或不可執行,則該條款應以與適用法律一致的方式解釋,以反映該條款的原意,並且本協議的其餘條款仍然完全有效。 未行使或執行本協議的任何權利或規定不構成對此類權利或規定的放棄。',
};

// 隱私協議
const agreement = {
  'agr.tab.title': '隱私協議',
  'agr.title': 'QuickFox用戶隱私協議',
  'agr.versionInfo': 'V1.0版本生效時間2020年1月1日',
  'agr.t1': '歡迎來到QuickFox',
  'agr.t1.p1':
    'QuickFox致力於保護您的隱私。我們希望您了解我們收集的信息,我們不收集的信息以及我們如何收集,使用和存儲信息。我們不會收集您的活動日誌,包括不記錄瀏覽歷史記錄,流量目標,數據內容或DNS查詢。我們也從不存儲連接日誌,這意味著沒有您的IP位址,傳出 IP位址,連接時間戳或會話持續時間的日誌。',
  'agr.t1.p2':
    '我們的數據收集指導原則是僅收集大規模運營世界級服務所需的最少數據。我們設計的系統沒有關於客戶的敏感數據;即使被迫,我們也無法提供我們不具備的數據。',
  'agr.t1.p3': '本隱私政策將幫助您了解QuickFox的收集,使用和存儲信息方式。',
  'agr.t2': '一般信息',
  'agr.t2.p1': `QuickFox收集兩種類型的信息：`,
  'agr.t2.p2': `（i）與您的帳戶相關的信息（「個人信息」）
      
      收集此信息是為了管理您的QuickFox購買,並包括您在購買服務時在我們的購買頁面上提交的姓名,電子郵件地址和付款信息。`,
  'agr.t2.p3': `（ii）聚合應用和連接摘要統計

      QuickFox收集有關使用的最少信息,以保持卓越的客戶支持和服務質量。以下部分詳細說明了我們收集的信息。這些統計信息從未包含用戶對產品所做的事情：沒有關於產品流量的內容或目的地的數據,沒有DNS查詢,也沒有IP位址。`,
  'agr.t2.p4': `（iii）（用戶控制選項）：匿名產品連接診斷和崩潰報告

      產品連接診斷是匿名的,不能綁定到各個QuickFox用戶。此功能類似於「發送錯誤報告」選項。用戶可以在任何QuickFox App的設置菜單中指定是否將這些數據發送給我們。`,

  'agr.t3': '個人信息',
  'agr.t3.p1':
    'QuickFox會直接通過本產品收集您提供給我們的個人信息。我們要求您提供個人信息,例如電子郵件地址和付款信息,以便建立QuickFox帳戶,以便我們向您發送電子郵件,收取您的付款,並回復您發起的支持查詢。收集的具體信息取決於您選擇的付款方式。為了儘量減少您提交給我們的個人信息,我們建議您在購買我們的服務時使用iOS內購付款。',
  'agr.t3.p2': 'QuickFox使用您的電子郵件地址,原因如下：',
  'agr.t3.p3': '發送與付款交易相關的電子郵件。',
  'agr.t3.p4': '提供我們產品的連結,包括密碼重置電子郵件。',
  'agr.t3.p5': '向您發送更新和公告。',
  'agr.t3.p6': '與您溝通有關產品服務或回復您的通信。',
  'agr.t3.p7':
    '發送營銷信息,例如QuickFox提供,調查,邀請以及與QuickFox相關的其他事項的內容。您可以選擇不按照這些電子郵件中描述的選擇退出程序接收營銷電子郵件。',
  'agr.t3.p8':
    'QuickFox僅將您的個人信息用於本隱私政策中列出的目的,我們不會將您的個人信息出售給第三方。',
  'agr.t4': '聚合應用和產品連接摘要統計',
  'agr.t4.p1':
    '我們確保永遠不會記錄瀏覽歷史記錄,流量目標,數據內容,IP位址或DNS查詢。因此：',
  'agr.t4.p2': '我們不知道哪個用戶訪問過特定網站或服務。',
  'agr.t4.p3':
    '我們不知道哪個用戶在特定時間連接到產品或者他們使用了哪個產品伺服器IP位址。',
  'agr.t4.p4': '我們不知道用戶計算機的原始IP位址集。',
  'agr.t4.p5':
    '如果有人試圖強迫QuickFox發布基於上述任何一項的用戶信息,我們無法提供此信息,因為數據不存在。',
  'agr.t4.p6':
    '為了保持出色的客戶支持和服務質量,QuickFox會收集以下與您的產品使用相關的信息：',
  'agr.t5': '應用和應用版本',
  'agr.t5.p1':
    '我們收集與您激活的應用程式和應用程式版本相關的信息。了解當前版本的應用程式後,我們的支持團隊可以幫助您解決技術問題。',
  'agr.t6': '成功連接',
  'agr.t6.p1':
    '我們收集有關您是否在特定日期（但不是當天的特定時間）成功建立產品連接的信息,產品位置（但不是您指定的傳出IP位址）以及來自哪個國家/ ISP（但不是你的源IP位址）。這些最少的信息有助於我們提供技術支持,例如識別連接問題,提供有關如何最好地使用我們服務的國家特定建議,以及使QuickFox工程師能夠識別和修復網絡問題。傳輸數據的總和（以MB為單位）',
  'agr.t6.p2':
    '我們收集有關給定用戶傳輸的數據總和的信息。雖然我們提供無限制的數據傳輸,但如果我們發現單個用戶推送的流量超過其他數千個用戶,從而影響其他QuickFox用戶的服務質量,我們可能會聯繫該用戶進行解釋。',
  'agr.t6.p3':
    '我們設計的系統可以明確地消除敏感數據的存儲。我們可能知道客戶使用過QuickFox,但我們從未知道他們如何使用我們的服務。我們堅持對客戶隱私的堅定承諾,不擁有與用戶在線活動相關的任何數據。',
  'agr.t7': '匿名產品連接診斷和崩潰報告（可由用戶關閉）',
  'agr.t7.p1':
    '經過您的許可,我們會收集用於網絡診斷的匿名分析數據。我們在網絡運營工具中使用這些數據來幫助優化網絡速度,並讓我們識別與特定應用,產品伺服器或ISP相關的問題。我們收到的信息是完全匿名的,不能綁定到單個QuickFox用戶（即,我們不存儲哪個用戶發送了哪些數據,我們不存儲IP位址）。',
  'agr.t7.p2': '如果您選擇與QuickFox共享此信息,我們將收集以下信息：',
  'agr.t7.p3': '有關產品連接嘗試是否以及如何失敗的診斷信息。',
  'agr.t8': '速度測試數據。',
  'agr.t8.p1':
    '崩潰報告,也沒有任何個人身份信息。這些由第三方以匿名形式處理,具體取決於您使用QuickFox的平台：',
  'agr.t8.p2':
    'Windows：Microsoft擁有的Hockeyapp和Functional Software,Inc。擁有的Sentry。請參閱Microsoft 的隱私政策和Sentry的隱私政策。',
  'agr.t8.p3':
    'Mac：Crashlytics,由Google擁有,Sentry,由Functional Software,Inc。擁有。請參閱Crashlytics 的隱私政策和Sentry的隱私政策。',
  'agr.t8.p4':
    'Linux：Sentry,由Functional Software,Inc。擁有。參見Sentry的隱私政策。',
  'agr.t8.p5':
    'iOS：由Google和Apple擁有的Firebase Crashlytics 。請參閱Apple的隱私政策和Firebase的隱私和安全文檔。您可以在 iOS設置中禁用Apple的崩潰報告,如此處所述。',
  'agr.t8.p6':
    'Android：Google擁有的GoogleAnalytics 和FirebaseCrashlytics 。請參閱Google Analytics的服務條款和 Firebase 的隱私和安全文檔。',
  'agr.t8.p7':
    '激活任何QuickFox應用程式後,系統將詢問您是否要共享這些數據。您可以隨時在應用程式的設置菜單中啟動或停止共享這些診斷數據。在iOS 上,Apple的崩潰報告可以在iOS設置中關閉。',
  'agr.t9': '存儲與電子郵件,實時聊天和反饋表單相關的信息',
  'agr.t9.p1':
    'QuickFox會記錄您通過我們的產品或服務提交給我們的任何通信,問題,投訴或稱讚,以及我們的回覆。根據您與QuickFox的聯繫方式,我們可能會收集您的電子郵件地址以及您提供給我們的任何其他信息。擁有完整的通信記錄使我們的員工能夠提供最佳的客戶支持體驗。',
  'agr.t10': '保護您的信息的安全措施',
  'agr.t10.p1':
    'QuickFox針對我們的辦公室和信息存儲設施使用一流的物理程序和技術安全性,以防止任何丟失,誤用,未經授權的訪問,披露或修改信息。訪問用戶信息僅限於需要此類訪問權限才能執行其工作職能的員工。',
  'agr.t10.p2':
    '雖然我們認為這些系統非常強大,但重要的是要了解世界上沒有任何數據安全措施可以提供100％的保護。',
  'agr.t10.p3':
    '伺服器位於具有強大安全實踐的數據中心內。這些數據中心都不要求我們收集或存儲與您使用產品服務相關的任何流量數據或個人信息。如果任何數據中心要求我們記錄此類數據,我們將立即停止與所述數據中心的操作並找到其他選項。',
  'agr.t11': 'Cookie',
  'agr.t11.p1': '什麼是Cookie？',
  'agr.t11.p2':
    'Cookie是一個小文本文件,用於存儲有關您訪問本產品的信息。 Cookie允許QuickFox通過幫助我們提供某些功能（例如產品登錄和語言設置）來優化和改善產品的用戶體驗。隨著我們不斷更新和改進我們的產品,我們使用的cookie可能會隨時間而變化。',
  'agr.t11.p3': '禁用cookie',
  'agr.t11.p4':
    '您可以隨時更改您的cookie首選項。您可以在瀏覽器的設置面板中執行此操作。根據您使用的瀏覽器和設備,您可能能夠控制您允許的Cookie,將來要阻止的Cookie以及刪除Cookie。有關這些設置的詳細信息。請注意,如果您選擇禁用cookie,QuickFox的功能可能無法正常工作。',
  'agr.t11.p5': 'QuickFox的cookies',
  'agr.t11.p6':
    'QuickFox設置的Cookie使我們能夠設置您的語言偏好,將訪問者歸因於營銷渠道,並在您登錄後,安全地向您顯示特定於您帳戶的信息。 Cookie包含用戶標識符,但不包含您的姓名或電子郵件地址等直接個人身份信息,也不會跟蹤QuickFox域外的任何活動。',
  'agr.t11.p7': '第三方Cookie',
  'agr.t11.p8':
    'QuickFox使用第三方服務,例如Google Analytics和Adwords。來自此類服務的Cookie用於收集統計報告的數據。例如,我們可能會生成有關用戶在網站上花費的時間以及訪問特定網頁的用戶數量的報告。',
  'agr.t11.p9':
    'QuickFox使用Google AdWords在營銷廣告向訪問過我們網站的用戶展示第三方網站（包括Google）上的廣告。我們可能會在Google搜索結果頁或Google展示廣告網絡中的網站上展示此類用戶廣告。',
  'agr.t11.p10':
    '第三方供應商（包括Google）使用Cookie根據某人過去訪問過本網站的情況來投放廣告。收集的所有數據將根據我們的隱私政策和Google的隱私政策使用。用戶可以訪問Google廣告選擇停用頁面,選擇停用Google對Cookie的使用。用戶可以訪問Google Analytics停用頁面選擇退出Google Analytics。用戶可以訪問網絡廣告促進會選擇退出頁面,選擇退出第三方供應商使用Cookie。',
  'agr.t12': '第三方網站',
  'agr.t12.p1':
    '本產品可能包含不屬於QuickFox域名的外部網站的連結。 QuickFox不對此類外部網站的隱私慣例或內容負責。',
  'agr.t12.p2': '同意和年齡限制',
  'agr.t12.p3':
    '使用本產品,內容,應用程式,軟體或服務,即表示您同意按照我們的服務條款和隱私政策中的規定處理您的信息。',
  'agr.t12.p4':
    '該服務適用於18歲及以上的成年人。如果您認為您的孩子已向我們提供了信息,請立即通知我們。',
  'agr.t12.p5': '隱私政策的變更',
  'agr.t12.p6':
    '根據適用的隱私法律和原則,我們可能會在不事先通知您的情況下不時更改我們的隱私政策。您繼續使用本網站或服務即表示您接受我們的隱私政策。',
  'agr.t13': '如何聯繫QuickFox',
  'agr.t13.p1':
    '如果您對我們的隱私政策及我們如何處理您的信息有任何疑問,請通過以下方式與我們聯繫。',
  'agr.t13.p2':
    '我們的註冊地址/常用辦公地址為：福建省福州市鼓樓區鼓西街道楊橋東路8號利嘉大廣場2#樓16層01室A區',
  'agr.t13.p3': '我們的電子郵件：info@quickfox.com.cn',
  'agr.t13.p4':
    '信息保護負責人聯繫電話：18606018662（僅限工作日工作時間可聯繫,限於個人信息保護相關問題）',
  'agr.t13.footer': '福建紫訊信息科技有限公司',

  'agr.t14': '數據共享與披露',
  'agr.t14.p1': `為了向您提供一鍵分享服務,我們的產品集成友盟+ U-Share SDK,將收集您的設備標識信息（如IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置等）和您需要分享的社交帳戶公開信息,以便完成一鍵分享服務。為了您的信息安全,我們已與第三方SDK服務商進行數據安全保密約定,這些公司會嚴格遵守我們的數據隱私和安全要求。除非得到您的同意,我們不會與其共享您的個人身份信息。為便於您更好地了解【友盟+】採集的數據類型及用途,以及如何保護您的個人信息,您可以登陸https://www.umeng.com/policy了解【友盟+】隱私權政策。`,

  'arg.t15':
    '為了提升 AdMob 的效果,Google Mobile Ads SDK 可能會從應用中收集特定信息,包括：',
  'arg.t15.p1': '1. IP 地址,可用於估測設備所在的大致位置。',
  'arg.t15.p2':
    '2. 與用戶無關的崩潰日誌,可用於診斷問題和改進 SDK。診斷信息還可用於廣告和分析。',
  'arg.t15.p3':
    '3. 與用戶相關的性能數據（例如應用啟動時間、掛起率或耗電量）,可用於評估用戶行為、了解現有產品功能的效果以及規劃新功能。性能數據還可用於展示廣告,包括與展示廣告的其他實體共享。',
  'arg.t15.p4':
    '4. 設備 ID（例如設備的廣告標識符或其他與應用綁定的設備標識符）,可用於第三方廣告和分析。',
  'arg.t15.p5':
    '5. 廣告數據（例如用戶看過的廣告）,可用於為分析和廣告功能提供支持。',
  'arg.t15.p6':
    '6. 用戶與產品進行的其他互動（例如啟動應用時的點按情況）及互動信息（例如視頻觀看次數）,可用於提升廣告效果。',

  'agr.t16': '權限申請：',
  'agr.t16.p1':
    '為了向您提供瀏覽、下載任務頁內圖片及緩存客戶端配置文件,我們會申請存儲權限；',
  'agr.t16.p2': '為針對不同應用優化您的加速體驗,我們需要獲取應用列表權限。',
};

const Welfare = {
  'welfare.header.todayincome': '今日收益',
  'welfare.header.totalincome': '累計收益',

  'welfare.coinsTasks': '金幣任務',

  'welfare.tasksCard.chekout': '查看',
  'welfare.tasksCard.coins': '金幣',

  'welfare.tasks.invite': '邀請有禮',
  'welfare.tasks.invite.unit': '金幣/人',
  'welfare.tasks.invite.desc': '邀請賺年卡會員',
  'welfare.tasks.invite.descript': '邀請好友拿金幣,百萬紅包等你兌',
  'welfare.tasks.sign': '每日簽到',
  'welfare.tasks.sign.descript': '最高每日可獲得50金幣哦',
  'welfare.tasks.wx': '關注公眾號',
  'welfare.tasks.bindEmail': '綁定有禮',
  'welfare.tasks.bindText': '去綁定',
  'welfare.tasks.bindEmail.descript': '綁定郵箱或手機號即可領取金幣',
  'welfare.tasks.bindEmail.desc': '+6小時VIP時長',
  'welfare.tasks.bindEmail.bindTimes': '6小時VIP',
  'welfare.tasks.share': '分享有獎',
  'welfare.tasks.share.descript': '在社交平台分享海報，截圖上傳領取100金幣',
  'welfare.tasks.wx.descript': '關注QuickFox微信公眾號即可領取30金幣',
  //簽到頁面
  'welfare.sign.dayno': '第 {day} 天',
  'welfare.sign.vipTime': '{vipTime}小時VIP',
  'welfare.sign.day': '天',
  'welfare.sign.hadSign': '已連續簽到',
  'welfare.sign.sign': '簽到',
  'welfare.sign.signed': '已簽到',
  'welfare.sign.tips': '連續簽到可獲得豐厚獎勵哦~',
  'welfare.sign.successText': '簽到成功',
  'welfare.sign.rule.title': '簽到細則',
  'welfare.sign.rule.modalTitle': '簽到細則',
  'welfare.sign.rule.part1': '每連續簽到3天，可獲得2小時VIP時長；',
  'welfare.sign.rule.part2': '每連續簽到7天，可獲得4小時VIP時長；',
  'welfare.sign.rule.part3': '中斷簽到將重新開始計算天數；',
  'welfare.sign.rule.part4': '同一設備多次註冊不記錄簽到獎勵，禁止以不正當手段取得簽到獎勵。',
  'welfare.sign.successTips':
    '簽到成功, {todayReward} 金幣到手, 記得每天都來哦~',
  'welfare.sign.signSuccess':
    '簽到成功~',
  'welfare.sign.signSuccessVip':
    '簽到成功, {vipTime}{timeType}VIP時長到手, 記得每天都來哦~',
  'welfare.sign.ruleDetail':
    '每日打卡簽到可獲得2金幣,每成功連續簽到7天可獲得額外金幣獎勵,最高當日簽到可獲得50金幣,記得每天都來哦~',
  'welfare.sign.ruleDetail2':
    '同一設備多次註冊不記錄獎勵金幣，禁止通過不正當手段獲取金幣獎勵，QuickFox有權收回金幣獎勵及關閉兌換訂單',
  //關注公眾號頁面

  'welfare.wx.follow': '關注公眾號',
  'welfare.wx.welfareForInvite': '邀請有禮',
  'welfare.wx.signIn': '分享有獎',
  'welfare.wx.toSignIn': '去簽到',
  'welfare.wx.toFollow': '去關注',
  'welfare.wx.toInvite': '去邀請',
  'welfare.wx.toGet': '可得',
  'welfare.wx.toGetHighest': '最高獲得',

  'welfare.wx.qrcodeTip.saved': '二維碼已保存至相冊',
  'welfare.wx.qrcodeTip.failed': '二維碼保存失敗了',
  'welfare.wx.qrcodeTip.version': '保存二維碼需要下載最新版本!',
  'welfare.wx.Intro.title': '任務介紹',
  'welfare.wx.Intro.step': '步驟',
  'welfare.wx.Intro.follow': '關注',
  'welfare.wx.Intro.reward': '獎勵',
  'welfare.wx.Intro.rewardText1.part1': '關注即獲得',
  'welfare.wx.Intro.rewardText1.part2': '{vipCount}小時VIP時長',
  'welfare.wx.Intro.pc.scanit': '使用手機微信「掃一掃」下方圖片',
  'welfare.wx.Intro.mobile.scanit':
    '保存下方二維碼至相冊,打開微信,選擇「掃一掃」 該圖',
  'welfare.wx.Intro.follow.descrip': '點擊右上角「關注」按鈕,即可完成任務',

  //邀請頁面
  'welfare.invite.rule.title': '邀請規則',
  'welfare.invite.personNum': '已成功邀請 (人)',
  'welfare.invite.viewDetail': '查看明細',
  'welfare.invite.inviteDetail': '邀請明細',
  'welfare.invite.coinNum': '邀請獎勵 (金幣)',
  'welfare.invite.dayNum': '邀請獎勵 (天)',
  'welfare.invite.duration': '獲贈時長 (天)',
  'welfare.invite.toGoods': '金幣兌換',
  'welfare.invite.rewardDetail': '獎勵明細',
  'welfare.invite.face2face': '海報&面對面邀請點擊量',
  'welfare.invite.copyLink': '複製邀請連結',
  'welfare.invite.post.saved': '海報已保存至相冊',
  'welfare.invite.post.failed': '海報保存失敗了',
  'welfare.invite.post.descrip.tips':
    '保存海報或截屏分享給好友,邀請好友掃描二維碼,成功邀請後你和好友各得100金幣',
  'welfare.invite.post.version': '保存海報需要下載最新版本!',
  'welfare.invite.post.pcAppTip': '請前往QuickFox APP端保存海報!',
  'welfare.invite.post.saveBtn': '保存海報',
  'welfare.invite.post.descrip.regist': ` 掃碼註冊 <br /> QuickFox帳號`,
  'welfare.invite.post.descrip.speedOnce': `下載APP <br /> 使用一次加速`,
  'welfare.invite.post.descrip.getCoins': `您和好友 <br /> 各獲得100金幣`,
  'welfare.invite.process.regist': ` 好友點擊 <br /> 邀請連結註冊`,
  'welfare.invite.process.speedOnce': `下載APP <br /> 使用一次加速`,
  'welfare.invite.process.getCoins': `您和好友 <br /> 各獲得100金幣`,
  'welfare.invite.process.tips':
    '*好友需在新行動裝置上（即之前未登錄過QuickFox帳號的手機）登錄在邀請連結上註冊的帳號,使用一次加速後方可獲得獎勵。',

  'welfare.invite.link.title': '你有超級VIP待領取',
  'welfare.invite.link.descrip':
    'QuickFox加速器發放福利啦,註冊即送超級VIP,更有現金紅包等你來搶！',
  'welfare.invite.historyBtn': '邀請明細',
};

const PageTitle = {
  'pageTitle.goods': '商城',
  'pageTitle.welfare': '福利',
  'pageTitle.invite.detail': '邀請明細',
  'pageTitle.taskDetails': '關注公眾號',
  'pageTitle.inviteDetails': '邀請有禮',
  'pageTitle.taskSignin': '每日簽到',
  'pageTitle.taskCenter': '任務中心',
  'pageTitle.taskEmail': '綁定郵箱',
  'pageTitle.taskShare': '分享有獎',
  'pageTitle.appApplyRecord': '申請記錄',
};

const Common = {
  'common.ok': '好的',
  'common.confirm': '確定',
  'common.cancel': '取消',
  'common.avatar': '頭像',
  'common.iSee': '我知道了',
  'common.human': '人',
  'common.requreUpdate': '當前版本過舊,請更新至最新版本',
  'common.currency.dollar': '美元',
  'common.free': '免費',
  'common.currency.rmb': '元',
  'common.payType.offline': '線下支付',
  'common.payType.alipay': '支付寶',
  'common.payType.wxMiniProg': '微信小程序',
  'common.payType.wx': '微信', //微信支付
  'common.payType.bank': '银行卡/信用卡', //银行卡/信用卡
};

const Tips = {
  'tips.pleaselogin': '請先登錄',
  'tips.pleaselogin.event': '登錄後才可以參加活動哦~',
  'tips.gologin': '去登錄',
  'tips.loadFailed': '加載失敗',
  'tips.listLoadFailed': '加載列表失敗',
  'tips.loadFailed.retry': '加載失敗,請稍後重試',
  'tips.refreshed': '已刷新',
  'tips.refreshFailed': '刷新出錯了',
  'tips.saving': '保存中...',
  'tips.saveQrcode': '保存二維碼',
  'tips.copyed': '複製成功',
  'tips.loadingHard': '拼命加載中,請稍候...',
  'tips.loading': '加載中...',
  'tips.nodata.forNow': '暫無數據',
  'tips.load.success': '加載完成',
  'tips.goingToPay': '正在前往支付...',
  'tips.cancelSuccessful': '取消成功',
  'tips.failToRefreshOrder': '訂單刷新失敗',
  'tips.refreshOrderError': '訂單刷新出錯了',
  'tips.refreshListError': '刷新列表出錯了',
  'tips.refreshListDone': '已刷新列表',
};
// 開通記錄
const ActivationRecord = {
  'record.records': '開通記錄',
  'record.options': '操作',
  'record.memberRefund': '會員退款',
  'record.syncOrder': '同步訂單',
  'record.tip': '溫馨提示',
  'record.tipDesc':
    '您確定要關閉QuickFox超級VIP連續包月服務嗎？這將導致您的帳號停止續期，到期後將無法使用VIP專屬線路。',
  'record.memberPage.pageViewsOfActivationRecord': '會員頁-開通記錄頁瀏覽量',
  'record.refundFail.tryAgain': '退款申請失敗,請稍候再試',
  'record.cancel': '取消',
  'record.noRecordYet.goAndActive': '還沒有記錄哦,快去開通會員吧~',
  'record.chooseRefundOrder': '選擇退款訂單',
  'record.weSupportRefund.oneUserHasOneService':
    '我們支持您在購買超級VIP會員後7天內申請退款,請選擇要退款訂單,退款後資金將原路返回。一個用戶只可享受一次退款服務。',
  'record.failToGetList': '獲取列表失敗',
  'record.failToGetActivationList': '獲取開通記錄列表失敗',
  'record.failToGetRefund': '獲取退款情況失敗',
  'record.getListFail': '獲取列表失敗',
  'record.recordItem.numberOfRefund':
    '會員頁-開通記錄頁,訂單卡片上的退款按鈕點擊量',
  'record.recordItem.getTime': '獲得時間',
  'record.recordItem.refundTime': '退款時間',
  'record.recordItem.refuseRefund': '不可退款',
  'record.recordItem.refundComplete': '退款已完成',
  'record.recordItem.addDay': '權益時長',
  'record.recordItem.handsel': '贈送',
  'record.recordItem.orderNumber': '訂單編號',
  'record.recordItem.orderType': '支付方式',
  'record.recordItem.orderNumberHasBeenCopied': '訂單編號已複製',
  'record.recordItem.refund': '退款',
  'record.recordItem.outActivity': '外部活動',
  'record.resaonModal.refundReason': '退款原因',
  'record.resaonModal.supplement': '補充說明',
  'record.resaonModal.refundReasonPlaceholder':
    '選填。告訴我們為什麼您需要退款,以便我們儘快進行優化,最多可輸入50個字。',
  'record.resaonModal.reasonLimitedText': '最多只能輸入50個字哦',
  'record.resaonModal.failToSubmitRefundRequset': '退款申請提交失敗了',
  'record.resaonModal.refundApplication': '退款申請',
  'record.reasonModal.failToRefund': '操作失敗',
  'record.reasonModal.successfulRefund': '操作成功',
  'record.reasonModal.weSupportRefund.reviewDetailonITunes':
    '我們支持您在購買超級VIP會員後7天內申請退款,請在iTunes上查看該訂單並進行退款申請。',
  'record.reasonModal.numberOfConfirmRefund':
    '會員頁-開通記錄頁,確定退款按鈕點擊量',
  'record.reasonModal.ok': '好的',
};

// 時間類型(通用)
const TimeType = {
  'timeType.day': '天',
  'timeType.week': '周',
  'timeType.month': '月',
  'timeType.season': '季',
  'timeType.year': '年',
  'timeType.minute': '分鐘',
  'timeType.hour': '小時',
  'timeType.sentence': ' {day} 天 {hour} 時 {minute} 分 {second} 秒',
};

// 防鎖定教程
const tutorial = {
  'tutorial.mi': '小米手機教程',
  'tutorial.vivo': 'vivo手機教程',
  'tutorial.oppo': 'OPPO手機教程',
  'tutorial.huawei': '華為手機教程',
  'tutorial.honor': '榮耀手機教程',
  'tutorial.oneplus': '一加手機教程',
  'tutorial.samsung': '三星手機教程',
  'tutorial.general': '其他手機教程',
  'tutorial.tips': '請選擇您的手機品牌,查看相對應教程',
  'tutorial.title': '加速防掉線教程',
  'tutorial.purchase.title': '購買超級VIP',
  'tutorial.purchase.content0':
    '如您在使用Google Play支付購買會員時無法完成支付，可嘗試使用以下途徑購買：',
  'tutorial.purchase.title1': '1、前往官網購買會員',
  'tutorial.purchase.content1':
    '您可點擊下方按鈕前往官網購買會員，支持微信/支付寶/PayPal等支付方式，價格更優惠；',
  'tutorial.purchase.title2': '2、安裝QuickFox最新版本',
  'tutorial.purchase.content2':
    '可前往QuickFox官網下載最新版本，支持微信/支付寶/PayPal等支付方式；',
  'tutorial.purchase.title3': '3、聯繫客服購買',
  'tutorial.purchase.content3':
    'QuickFox支持通過微信/支付寶/銀行以掃碼或轉賬的形式購買超級VIP，您可以點擊APP左上角諮詢APP客服或添加官方微信號QuickFox2020進行諮詢；',
  'tutorial.purchase.button': '去官網頁面購買',
  'tutorial.purchase.button1': '下載最新版本',
};
// 邀請裂變1,2期活動結束
const pointEventEnd = {
  'pointEventEnd.tooltipText': '很遺憾,活動已結束',
  'pointEventEnd.invitationWithPrize': '邀請有獎',
  'pointEventEnd.scanQRToFollow': '掃碼關注公眾號 下次再約哦~',
  'pointEventEnd.scanQRToFollowForMoreInfo':
    '掃碼關注QuickFox公眾號,更多精彩活動資訊一手掌握',
  'pointEventEnd.copyOfficialAccount': '複製公眾號',
};
// 邀請裂變第3期
const taskCenterV3 = {
  'task.mission.pv': '任務中心頁瀏覽量',
  'task.lookforwardForMoreTasks': '更多任務敬請期待',
  'task.taskCard.isPcAppNotice':
    '邀請任務暫時僅支持在移動端完成,請掃碼下載QuickFox APP進行任務。',
  'task.taskCard.numberOfInvitationClick': '邀請任務點擊量',
  'task.taskCard.numberOfFollowTaskClick': '關注公眾號任務點擊量',
  'task.taskCard.numberOfCheckInClick': '福利頁,簽到任務點擊數',
  'task.detail.follow.numberOfFollowPage': '關注公眾號任務頁瀏覽量',
  'task.invite.invitePagePv': '邀請任務頁瀏覽量',
  'task.invite.cpoyPv': '複製邀請連結點擊量',
  'task.invite.loadingText': '還沒有記錄哦,快去邀請朋友吧~',
  'task.invite.nodataText': '還沒有記錄哦,快去做任務獲得金幣吧~',
  'task.invite.noContentText': '暫無記錄',
  'task.invite.noContentTextPart1': '快去邀請朋友吧',

  'task.invite.coinDetail': '金幣明細',
  'task.invite.coinDetailPv': '金幣明細瀏覽量',

  'task.invite.numberOfClickInviteBtn': '邀請按鈕點擊量',
  'task.invite.posterAndFace2Face': '海報&面對面邀請',
  'task.invite.inviteForNow': '立即邀請',
  'task.invite.linkCopied': '連結已複製',
  'task.invite.posterLoading': '海報正在加載...',
  'task.invite.descript.title.part1': '每邀請一位好友,最多可以獲得',
  'task.invite.descript.title.part2': '快去邀請吧',

  'task.invite.reward.descrip': '獎勵說明',
  'task.invite.reward.descrip.part1':
    '1. 被邀請好友在行動端使用一次加速後，您與好友各獲得1天VIP時長獎勵。',

  'task.invite.reward.descrip.part2':
    '2. 被邀請好友完成VIP購買後，您即可獲得好友購買時長的10%作為邀請獎勵。',
  'task.invite.reward.descrip.part3':
    '3. 使用不正當手段大量註冊，將被判定為違規註冊，無法獲得邀請獎勵。',
  'task.invite.reward.descrip.part4':
    '4. 若好友發生退款，您所獲得的對應邀請獎勵將會被同步取消。',
  'task.invite.reward.descrip.part5':
    '2. 被邀請好友完成VIP購買後，您即可獲得好友購買時長的10% + 5%作為邀請獎勵。',
    

  'task.invite.details.tab.waitingData': '待加速',
  'task.invite.details.tab.waitingToGiveData': '待發放',
  'task.invite.details.tab.completedData': '已完成',
  'task.invite.details.tab.inValidData': '已失效',
  'task.invite.details.tab.violationData': '違規用戶',
  'task.invite.details.content.type1':
    '好友已完成註冊，讓好友在新裝置上下載APP並使用該帳號進行一次加速，獎勵將在1小時內到帳。',
  'task.invite.details.content.type2': '已成功邀請好友, 1天VIP時長獎勵到帳咯',
  'task.invite.details.content.type3':
    '哎呀，該好友沒有在新設備進行首次加速，本次邀請失敗了~',
  'task.invite.details.content.type4':
    '已成功邀請好友, 系統風險審核中, 您和好友的1天VIP時長獎勵將在1小時內到賬',
  'task.invite.details.content.type5': '該好友存在違規操作,無法發放獎勵',
  'task.invite.details.loading': '邀請明細正在加載...',
  'task.invite.details.load.faild': '邀請明細加載失敗',

  'task.signin.viewPv': '福利-簽到頁,頁面瀏覽量',
  'task.signin.walfare': '新用戶簽到福利',
  'task.signin.walfare.detail':
    '接受好友邀請註冊後前7天,連續簽到3天,將可以獲得額外100金幣獎勵。您也可以參與邀請活動,邀請朋友一起簽到,可獲得同等簽到金幣獎勵喲。',
  'task.signin.walfare.downloadForSignin': '掃碼下載QuickFox APP邀請好友簽到',
  'task.signin.walfare.goToInviteWithPhone': '去手機端邀請',
  'task.signin.walfare.goToInvite': '去邀請>',
  'task.signin.walfare.numberOfCheckInClick': '福利-簽到任務頁,簽到點擊數',
  'task.signin.walfare.signing': '簽到數據加載中,請稍後...',
  'task.signin.walfare.signSuccessTipsPart1': '簽到成功,{todayReward}金幣到手,',
  'task.signin.walfare.signSuccessTipsPart2':
    '因您獲得新用戶連續簽到福利,再送您100金幣~',
  'task.signin.walfare.signSuccessTipsPart3': '記得每天都來哦~',

  'task.goods.detail.title': '商品詳情',
  'task.goods.detail.lessCoin': '金幣不足',
  'task.goods.detail.lessCoinTips': '你的金幣不足哦,快去做任務賺取金幣吧~',
  'task.goods.detail.lessCoinTips.goForMoreCoin':
    '你的金幣不足哦,快去做任務賺取金幣吧~<br>ios設備暫不支持補差價兌換,請在其他系統設備上進行兌換操作',

  'task.goods.detail.exchangeRes.goCheck':
    '已兌換成功，可前往“兌換記錄”頁面查看領取商品',

  'task.goods.detail.exchange': '兌換商品',
  'task.goods.detail.exchangeSuccessful': '兌換成功',
  'task.goods.detail.exchangeSuccessful.congratulate':
    '恭喜,你已兌換成功,可通過下列途徑領取商品~',
  'task.goods.detail.exchangeSuccessful.tips':
    '恭喜,你已申請兌換成功,我們將在2個工作日內完成審核,可在「兌換記錄」頁面查看進度哦~',
  'task.goods.detail.exchangeSuccessful.getVip':
    '兌換成功,超級VIP特權已充值到您帳戶',
  'task.goods.detail.exchange.fail.retry': '兌換失敗,請稍後再試',
  'task.goods.detail.exchangeConfirm': '確定兌換該商品嗎？',
  'task.goods.detail.stock.less': '庫存不足',
  'task.goods.detail.stock.less.tips': '該商品庫存不足,暫時無法兌換哦~',
  'task.goods.detail.signinDay.less': '簽到天數不足',
  'task.goods.detail.signinDay.less.tips':
    '該商品需要簽到{day}次及以上才可以兌換哦,快去簽到吧~',
  'task.goods.detail.remain': '剩餘',
  'task.goods.detail.tillNext': '距下次搶購還有',
  'task.goods.blockTitle': '商品介紹',
  'task.goods.noStock': '暫無庫存',
  'task.goods.noConvertible': '不可兌換',
  'task.goods.overConvertibleLimit': '已達兌換上限',
  'task.goods.exchange.now': '立即兌換',
  'task.goods.order.total': '訂單總額',
  'task.goods.coinForPay': '支付金幣',
  'task.goods.require.diff': '需補差價',
  'task.goods.pay.diff': '補差價',
  'task.goods.pay.diff.exchange': '支付差價兌換',
  'task.goods.pay.way': '選擇支付方式',
  'task.goods.contact.offcialAccount': '聯繫QuickFox微信客服{number}',
  'task.goods.offcialAccount.copy': '(點擊複製微信號)',
  'task.goods.contact.customerService': '聯繫QuickFox微信客服',

  'task.point.detail.coinType0': '金幣抽獎',
  'task.point.detail.coinType1': '金幣兌換',
  'task.point.detail.coinType2': '邀請好友 {account} 註冊',
  'task.point.detail.coinType3': '通過好友邀請註冊',
  'task.point.detail.coinType4': '用戶排名靠前平台獎勵',
  'task.point.detail.coinType5': '每日簽到',
  'task.point.detail.coinType6': '關注微信公眾號',
  'task.point.detail.coinType7': '商城兌換訂單失效',
  'task.point.detail.coinType8': '金幣抽獎收益',
  'task.point.detail.coinType9': '連續簽到3天額外獎勵',
  'task.point.detail.coinType10': '好友連續簽到3天額外獎勵',
  'task.point.detail.bindReward': '綁定贈送', //'绑定赠送',
  'task.point.detail.shareReward': '分享任務獎勵', //'分享任务奖励',
  'task.point.detail.coinType11': '中秋活動金幣贈送',
  'task.point.detail.coinType13': '金幣到期',
  'task.point.detail.coinType16': '世界杯競猜活動',

  'task.redeem.modal.title': '暫不支持',
  'task.redeem.modal.content':
    'ios設備暫不支持補差價兌換,請在其他系統設備上進行兌換操作',
  'task.redeem.exchangeRecordPv': '兌換記錄瀏覽量',
  'task.redeem.exchangeRecord': '兌換記錄',
  'task.redeem.exchangeCode': '兌換碼：',
  'task.redeem.draw.link': '領取連結：',
  'task.redeem.draw.code': '領取商品碼：',
  'task.redeem.exchangeCode.use': '兌換方式',
  'task.redeem.norecord.goForIt': '還沒有記錄哦,快去兌換商品吧~',
  'task.redeem.record.tips':
    'QuickFox將在2個工作日（周一至周五為工作日）內完成發放,發放後請儘快查看使用,',
  'task.redeem.clickForIt': '點此查看',
  'task.redeem.copy': '複製',
  'task.redeem.copied': '已複製',
  'task.redeem.payForNow': '立即支付',
  'task.redeem.orderWill': '訂單將於 {time} 失效,請及時支付',
  'task.redeem.dataReady': '數據準備中,請稍候...',
  'task.redeem.drawed': '抽中',
  'task.redeem.receive': '立即領取', //立即领取',
  'task.redeem.drawed.limitDate': '獎品有效期至',
  'task.redeem.drawed.waitForit':
    '抽中 {lotteryName} ,等待發放（2個工作日內完成發放）',
  'task.redeem.drawed.waitForit.physical':
    '抽中 {lotteryName} , 請聯繫微信客服chenhan_xy領取',
  'task.redeem.drawed.violation':
    '抽中 {lotteryName} ,因違規獲得金幣,該訂單已被關閉',
  'task.redeem.drawed.success':
    '抽中 {lotteryName} ,會員已充值到您帳戶,請注意查收',
  'task.redeem.drawed.goods.success':
    '抽中 {lotteryName} ,已充值到您帳戶,請注意查收',
  'task.redeem.drawed.goods.success.physical':
    '抽中 {lotteryName} , 獎品已完成發放。 ',
  'task.redeem.drawed.svip.success': '超級VIP會員已充值到您帳戶,請注意查收',
  'task.redeem.drawed.vip.success': 'VIP會員已充值到您帳戶,請注意查收',
  'task.redeem.drawed.violation.closed': '因違規獲得金幣,該訂單已被關閉',
  'task.redeem.violation.contact':
    '因違規獲得金幣,不予發送抽獎獎勵。如有疑問請發申訴材料到郵箱：info@quickfox.com.cn。',
  'task.redeem.limit.contact':
    '因被邀請帳號存在異常操作,暫且限制兌換。請發申訴材料到郵箱：info@quickfox.com.cn。申訴材料包括QuickFox帳號,近期邀請所有好友的聊天記錄進行申訴。',
  'task.redeem.order.fail': '該筆訂單已失效,所消耗金幣已退回帳戶',

  'task.redeem.draw.customer.service':
    '請及時查看兌換商品的詳情描述，添加客服微信完成兌換。',
  'task.redeem.drawLink.tips': '領取連結：等待發放（2個工作日內完成發放）',
  'task.redeem.drawLink.limit.tips': '商品碼將在{expireTime}過期,請儘快兌換',
  'task.redeem.noname': '暫無名稱',

  'task.event.rule': '活動規則',
  'task.event.question': '常見問題',
  'task.event.needToKnow': '活動須知',

  'task.goods.loadGoods.fail': '商品列表加載失敗',
  'task.goods.coin.loadFailed': '金幣數加載失敗',
  'task.goods.shop.requreUpdate':
    '金幣商城訪問異常,為保證更好的用戶體驗,請您升級到最新客戶端',
  'task.goods.coin.less.loadFailed': '剩餘金幣數加載出錯了',
  'task.goods.coin.balance': '金幣餘額',
  'task.goods.coin.detail.view': '查看金幣收支記錄',
  'task.goods.coin.exchange.record': '查看商城兌換訂單',

  'task.lottery.detailPv': '抽獎商品詳情頁瀏覽量',
  'task.lottery.waitMsg': '正在抽獎,再等等,馬上開獎了~',
  'task.lottery.drawOut': '獎品被抽光啦,正在補充獎品中,稍後再來哦~',
  'task.lottery.networkErrMsg': '當前網絡異常,請檢查網絡後重試~',
  'task.lottery.Replenishment': '獎品被抽光啦,正在補充獎品中,稍後再來哦~',
  'task.lottery.balance.lessMsg': '金幣餘額不足,快去賺金幣吧~',
  'task.lottery.forAWhile': '當前無法進行抽獎,晚點再來看看吧',
  'task.lottery.luckyId.error': '中獎id錯誤：',
  'task.lottery.luckyId.cannotFind': '沒找到獎品,中獎id為：',
  'task.lottery.interrupt': '哎呀,抽獎意外中斷了！請稍後重試或聯繫客服',
  'task.lottery.start.first': '請先開始',
  'task.lottery.number.error': '數字錯誤',
  'task.lottery.now': '立即',
  'task.lottery': '抽獎',
  'task.lottery.add': '補充',
  'task.lottery.rewards': '獎品中',
  'task.lottery.draw.congratulate': '恭喜！您抽中了',
  'task.lottery.coin.once': '金幣/次',
  'task.lottery.draw.view':
    ',等待系統發放後即可領取,可在「福利-兌換記錄「頁查看進度',
  'task.lottery.draw.viewReward':
    ' ,獎品已發送到您帳戶,可在「福利-兌換記錄」頁查看',
  'task.lottery.draw.physicalGoods':
    ' , 請及時聯繫客服領取, 可在「QuickFox App-福利-兌換記錄」頁查看聯繫方式及進度~',
  'task.lottery.rule': '抽獎規則',
  'task.lottery.rulePv': '抽獎規則點擊量',
  'task.lottery.myRewardPv': '我的獎品點擊量',
  'task.lottery.tab.item1.part1': '活動',
  'task.lottery.tab.item1.part2': '規則',
  'task.lottery.tab.item2.part1': '我的',
  'task.lottery.tab.item2.part2': '獎品',
  'task.lottery.coin.earnIt': '賺金幣>>',
  'task.lottery.more': '更多抽獎',
  'task.lottery.result': '抽獎結果',
  'task.lottery.again': '再抽一次',
  'task.lottery.toView': '前往查看',
  'task.lottery.lickDrawNowPv': '「立即抽獎」按鈕成功點擊量',
  'task.lottery.error': '抽獎報錯了：',
  'task.lottery.notLogin': '未登錄',

  'task.walfare.center': '福利中心',
  'task.walfare.info.refresh.done': '已刷新個人信息',
  'task.walfare.info.refresh.error': '刷新個人信息出錯了',
  'task.walfare.refreshed': '已刷新',
  'task.walfare.refresh.error': '刷新出錯了',
  'task.walfare.viewPv': '福利頁瀏覽量',
  'task.walfare.all': '全部',
  'task.walfare.giftMoney': '紅包',
  'task.walfare.coin.clickPv': '金幣明細點擊量',
  'task.walfare.coin.left': '剩餘金幣',
  'task.walfare.coin.mine': '我的金幣',
  'task.walfare.coin.expire.thisMonth': '本月到期金幣',
  'task.walfare.coin.expire.time': '到期時間',
  'task.walfare.coin.earn': '賺取金幣',
  'task.walfare.exchange.record': '兌換記錄',
  'task.walfare.exchange.recordPv': '兌換記錄點擊量',
  'task.walfare.coin.descrip': '金幣說明',
  'task.walfare.coin.descrip.usage': '金幣有什麼用？',
  'task.walfare.coin.descrip.usage.part1':
    '金幣是您在QuickFox的虛擬貨幣資產,您可使用金幣在福利中心兌換現金紅包、超級VIP會員等各種商品。',
  'task.walfare.coin.descrip.channel': '如何獲得金幣？',
  'task.walfare.coin.descrip.channel.part1':
    '可通過完成每日簽到、邀請好友、關注QuickFox公眾號等任務獲得金幣。',
  'task.walfare.coin.descrip.expire': '金幣到期說明：',
  'task.walfare.coin.descrip.expire.part1':
    '每月的產生的金幣將在3個月後到期，本月將到期的金幣將進入到期金幣列表中，兌換時優先消耗即將到期金幣。若未及時兌換，金幣將過期失效，無法找回。',
  'task.walfare.coin.noticePv': '公告點擊量',
  'task.walfare.detail.view': '查看詳情',
  'task.walfare.tasks.more': '更多任務',
  'task.walfare.tasks.clickPv': '更多任務點擊量',

  'task.goods.clickPv': '商品點擊量',

  // 邀請活動2期
  'welfare.invitev2.page.title': '贈送會員給好友',
  'welfare.invitev2.page.getFail': '獲取數據失敗',
  'welfare.invitev2.page.directions':
    '友情提示：激活贈送名額後，您最新的一筆付費訂單將失去7天無理由退款資格。',
  'welfare.invitev2.rule.title': '贈送規則',
  'welfare.invitev2.rule.content': `
   1、擁有VIP特權的用戶可每月激活並領取3個會員贈送名額（需成功購買過會員，且激活時贈送名額時自身會員剩餘時長≥30天）。在規定時間內以連結形式分享給好友，好友完成註冊後即可領取對應時長的QuickFox會員卡；<br/>
   2、每次激活贈送名額時，您最新一筆付費訂單將撤銷7天無理由退款資格，請在確認已同意該規則後再激活名額；<br/>
   3、用戶參加活動過程中，如用戶存在以所獲得的優惠權益進行盈利或非法獲利、以任何形式轉讓或轉移其所享有的優惠權益、非本人使用的，或者QuickFox有合理理由懷疑用戶存在不當使用權益的，QuickFox將取消用戶的權益資格，並有權撤銷相關違規交易、收回優惠權益；<br/>
   4、如有任何關於本活動的資訊與協助，請諮詢QuickFox線上客服，本活動與蘋果公司（Apple Inc.）無關；<br/>
   5、在法律範圍內，QuickFox對本次活動保留最終解釋權；<br/>
  `,
  'welfare.invitev2.link.title': '贈送你一張QuickFox會員卡',
  'welfare.invitev2.link.descrip':
    'QuickFox回國加速器，華人回國加速首選品牌，加速快人一步',

  'welfare.invitev2.card.type': 'QuickFox會員',
  'welfare.invitev2.card.nocheck': '無人領取',
  'welfare.invitev2.card.sendTo': '已成功贈送給尾號{account}好友',

  'welfare.invitev2.msgs.title': '給好友留言',
  'welfare.invitev2.msgs.next': '換一條',

  'welfare.invitev2.btn.allCheck': '會員卡已全部被好友領取',
  'welfare.invitev2.btn.giftNow': '立即贈送',
  'welfare.invitev2.btn.timeEnd': '贈送時間已結束',
  'welfare.invitev2.btn.submitAgain': '申請繼續贈送',
  'welfare.invitev2.btn.upgradeToVip': '升級為VIP',
  'welfare.invitev2.btn.leftTime': '剩餘贈送時間',
  'welfare.invitev2.btn.copyLink': '複製分享鏈接',
  'welfare.invitev2.btn.copied': '贈送鏈接已複製',
  'welfare.invitev2.modal.cantGift': '當前無法贈送會員',
  'welfare.invitev2.modal.content':
    '成功購買一次會員後可贈送會員給好友，好友註冊後就可獲得QuickFox會員哦~',
  'welfare.invitev2.modal.getIt': '知道了',
  'welfare.invitev2.modal.buyVip': '立即購買會員',
  'task.detail.email.title': '完善賬號信息任務',
  'task.detail.email.rule1': '活動規則',
  'task.detail.email.rule2': '用戶可通過綁定郵箱或手機號領取獎勵',
  'task.detail.email.rule3': '本次活動獎勵只可領取一次，無法重複領取',
  'task.detail.email.rule4':
    '已綁定郵箱、手機號的用戶可通過本頁面直接領取活動獎勵。通過郵箱、手機號註冊的用戶將自動下發獎勵',
  'task.detail.email.rule5':
    '贈送時長實時到賬。優惠券碼通過郵件、短信方式發送，僅可通過信息內的短網址參與',
  'task.detail.email.btn': '綁定並領取',
  'task.detail.email.received': '已領取',
  'task.detail.email.finished': '活動已結束',
  'task.detail.email.bind': '立即綁定',
  'task.detail.email.bound': '立即領取',
  'task.detail.email.success': '成功領取',
  'task.detail.email.failed': '領取失敗',
  'task.detail.share.title': '分享有獎',
  'task.detail.share.min5m': '請選擇小於5M大小的圖片',
  'task.detail.share.noPass': '審核不通過，重新提交',
  'task.detail.share.submit': '提交審核',
  'task.detail.share.underReview': '審核中',
  'task.detail.share.pass': '審核通過，已發放獎勵',
  'task.detail.share.save': '保存圖片',
  'task.detail.share.cancel': '取消',
  'task.detail.share.noUpload': '請先上傳分享截圖！',
  'task.detail.share.saveSuccess': '圖片已保存至相冊',
  'task.detail.share.saveFail': '保存圖片失敗！',
  'task.detail.share.successTip': '已提交審核，請耐心等待',
  'task.detail.share.version': '當前版本較低，請前往“我的-我的賬號”頁完成綁定',
  'task.detail.share.message': '請上傳圖片!',
};

// 邀请裂变第4期
const pointEventV4 = {
  'V4.welfare.center.title': '福利中心',
  'V4.welfare.center.more': '更多福利',
  'V4.welfare.foreignStudent.title': '留學生專屬福利',
  'V4.welfare.foreignRule.title': '規則說明',
  'V4.welfare.redeem.norecord.goForIt': '還沒有記錄哦，快去領取福利吧~',

  'V4.welfare.record.title': '福利記錄',
  'V4.welfare.miniGame.title': '小遊戲任務',
}

const cancel = {
  'cancel.title.reminder': '註銷提醒',
  'cancel.title.auth': '身份驗證',
  'cancel.reminder.artical': `
   <div  style="color: #90A1DC; ">
        註銷是不可逆操作，請認真閱讀以下重要提醒：
      </div>
      <p>
        1、註銷前請確認已解約所有支付平台的連續包月服務， 可查看對應<a href="{url}">解約教程</a>完成解約。為了保障您的權益，未解約無法完成註銷；
      </p>
      <p>
        2、帳號申請註銷到註銷成功有15天的等待期，在等待期內可隨時終止註銷。等待期結束後帳號將完成註銷；
      </p>
      <p>
        3、帳號一旦被註銷，所有帳號數據將清空。被註銷帳號無法登錄、無法找回。其所有權益，包含但不限於剩餘會員天數、金幣餘額等，也將清空無法找回；
      </p>
      <p>
        4、註銷後，該帳號關聯的手機號、郵箱地址及第三方登陸信息將釋放，登陸信息在釋放後可以用於其他帳號註冊或綁定；
      </p>
       <p>
        5、已註銷賬號重新註冊時，無法獲得該賬號之前已享受過的新用戶福利，包括但不限於新用戶超級VIP時長贈送，邀請活動金幣獎勵等；
      </p>
  `,
  'cancel.reminder.agree': '已閱讀並同意：QuickFox ',
  'cancel.reminder.agreement': '帳號註銷協議',
  'cancel.reminder.next': '下一步',
  'cancel.reminder.read': '請認真閱讀上述提醒',
  'cancel.reminder.cancelSign':
    '當前尚未解約{signTypeText}連續包月服務，請確認完成解約後再進行註銷操作',
  'cancel.auth.input': '請輸入當前賬號密碼完成驗證',
  'cancel.auth.confirm': '確認註銷',
  'cancel.auth.tips':
    '提交註銷後，您將有15天註銷等待期，等待期內可隨時重新登錄以終止註銷。 ',
  'cancel.auth.pwd': '請輸入密碼',
  'cancel.auth.relogin.third': '請重新登錄任一第三方賬號以完成驗證',
  'cancel.auth.relogin': '重新登錄',
  'cancel.auth.please': '請',
  'cancel.auth.finish': '已完成驗證',
  'cancel.auth.unDetected': '未檢測到第三方賬號，請嘗試聯繫客服處理',
  'cancel.auth.inconsistent':
    '本次登錄第三方帳號與當前綁定第三方賬號不一致，請重試',
  'cancel.auth.failed': '第三方賬號校驗失敗',
  'cancel.auth.failed.accident': '驗證失敗',
  'cancel.wx.pay': '管理自動續費',
};

const memberi = {
  'memberi.title': '兌換會員',
  'memberi.valicode': '請輸入兌換碼',
  'memberi.loginTip': '登錄後才可以兌換哦',
  'memberi.loginBtn': '登錄/註冊',
  'memberi.placeholder': '請輸入兌換碼,注意區分大小寫',
  'memberi.getcode': '立即兌換',
  'memberi.descTitle': '兌換規則',
  'memberi.desc1': '兑换码为QuickFox专属福利商品，请勿对兑换码进行售卖等任何商业行为。若有违反，QuickFox 保留收回会员时长的权利',
  'memberi.desc2': '兑换成功即可获得对应的VIP时长',
  'memberi.desc3':
    '已拥有VIP会员时长的用户，及仍在签约状态中的用户，将在兑换成功后顺延VIP时长',
  'memberi.desc4':
    'QuickFox在法律规定的范围内享有本规则的解释权。如有疑问，请咨询联系在线客服',
  'memberi.success': '兌換成功',
  'memberi.fail': '兌換失敗', 
  'memberi.success1': '已獲得',
  'memberi.ok': '好的',
  'memberi.blacklistTips': '當前帳號存在風險，無法使用兌換碼',
};

const customizedMembership = {
  'customizedMembership.viptimer': '時長剩餘',
  'customizedMembership.concat': '立即諮詢客服，獲得獨享定制線路',
  'customizedMembership.desc1': '獨享帶寬保障',
  'customizedMembership.desc2': '4K影音秒開',
  'customizedMembership.desc3': '流暢開播',
  'customizedMembership.desc4': '主備雙線雙倍穩定',
  'customizedMembership.desc5': '三設備同時在線',
  'customizedMembership.desc6': '全客戶端支持',
  'customizedMembership.btn': '諮詢續費',
  'customizedMembership.btn1': '諮詢購買',
  'customizedMembership.title': '定制会员',
};

const sportsQuiz = {
  'sportsQuiz.title': '賽事詳情',
  'sportsQuiz.shareAndGetCoins': '快速分享賺金幣', //快速分享赚金币',
  'sportsQuiz.how2play': '玩法介紹', //玩法介绍',
  'sportsQuiz.rule': '玩轉世界杯活動規則', //玩转世界杯活动规则',
  'sportsQuiz.guessed': '您已競猜', //您已竞猜',
  'sportsQuiz.advance': '晉級', //晋级',
  'sportsQuiz.win': '獲勝', //获胜',
  'sportsQuiz.draw': '平局', //平局',
  'sportsQuiz.guessWithCoins': '使用{coins}金幣競猜', //使用{coins}金币竞猜',
  'sportsQuiz.ifGuessRight': '競猜正確即可獲得', //竞猜正确即可获得',
  'sportsQuiz.includes': '比賽結果包含加時賽，點球大戰', //比赛结果包含加时赛，点球大战',
  'sportsQuiz.leftTime': '剩餘競猜時間', //剩余竞猜时间',
  'sportsQuiz.leftQuota': '剩餘參與名額', //剩余参与名额',
  'sportsQuiz.end': '本場賽事競猜已結束', //本场赛事竞猜已结束',
  'sportsQuiz.others': '請關注其他場次比賽~', //请关注其他场次比赛~',
  'sportsQuiz.getFromNotice': '（通過消息中心領取）', //（通过消息中心领取）',
  'sportsQuiz.youHaveGuessed': '您已參與競猜', //您已参与竞猜',
  'sportsQuiz.ifThen': '若 {predict}{result}您可獲得 ', //若 {predict}{result}您可获得 ',
  'sportsQuiz.guessedRight': '恭喜競猜正確，您的 ', //恭喜竞猜正确，您的 ',
  'sportsQuiz.goGet': '已發放，快去消息中心領取吧～', // 已发放，快去消息中心领取吧～',
  'sportsQuiz.yourGuess': '您的競猜為：', //您的竞猜为：',
  'sportsQuiz.guessedWrong': '競猜錯誤，下次繼續加油哦~', //竞猜错误，下次继续加油哦~',
  'sportsQuiz.goGetCoins': '去賺金幣', //去赚金币',
  'sportsQuiz.platforms': '您可以前往以下直播平台觀戰', //您可以前往以下直播平台观战',
  'sportsQuiz.useQF': '使用Quickfox影音模式，一鍵解鎖版權限制', //使用Quickfox影音模式，一键解锁版权限制',
  'sportsQuiz.cctv': '央視影音', //央视影音',
  'sportsQuiz.yangshipin': '央視頻', //央视频',
  'sportsQuiz.migu': '咪咕視頻', //咪咕视频',
  'sportsQuiz.tiktok': '抖音', //抖音',
};
const device = {
  'device.title': '已登錄設備',
  'device.dialog.title': '退出登錄',
  'device.dialog.centent':
    '退出登錄後該設備再次登錄需要驗證賬號密碼，您確定要退出嗎',
  'device.dialog.ok': '確定',
  'device.dialog.cancel': '取消',
  'applyRecord.title': '申請記錄',
  'applyRecord.exitFailed': '已拒絕', //已拒绝
  'applyRecord.using': '已上架', // 已上架
  'applyRecord.waiting': '待處理', //待处理
};
// 绑定邮箱活动
const bindEmail = {
  'bindEmail.title': '綁定郵箱',
  'bindEmail.success.tip': '綁定郵箱/手機號成功！',
  'bindEmail.areaCode': '區號',
  'bindEmail.phoneEmpty': '請輸入手機',
  'bindEmail.switchTo': '切換至',
  'bindEmail.getCode': '獲取驗證碼',
  'bindEmail.findCountry': '請輸入國家或者區號查找',
  'bindEmail.emailVerify': '請輸入正確的郵箱地址',
  'bindEmail.phoneVerify': '請輸入正確的手機號碼',
  'bindEmail.codeVerify': '請輸入正確的驗證碼',
  'bindEmail.pwsVerify': '6-16位數字字母組合密碼',
  'bindEmail.pwsEmpty': '請輸入密碼',
  'bindEmail.codeEmpty': '請輸入驗證碼',
  'bindEmail.phone': '手機',
  'bindEmail.email': '郵箱',
  'bindEmail.emailEmpty': '請輸入郵箱地址',
};
// 任务补充
const taskSupplement = {
  'taskSupplement.redemptionDetails': '兌換詳情頁',
  'taskSupplement.missionRules': '任務規則',
  'taskSupplement.shareScreenshot': '選擇分享截圖',
  'taskSupplement.supportChannel': '當前支持小紅書微信微博抖音等平台的分享截圖',
  'taskSupplement.advisoryService': '請遵守任務規則,如有疑問請諮詢客服',
  'taskSupplement.auditPrompt1': '審核期間請保持該分享內容可見',
  'taskSupplement.auditPrompt2': '審核通過後將自動發放100金幣獎勵',
  'taskSupplement.sharePoster': '分享海報',
  'taskSupplement.saveImg': '長按圖片保存',
  'taskSupplement.rulesDesc4':
    '即日起，所有註冊用戶都可在任務頁面上傳在社交平台（包括小紅書、微信、微博、抖音、Facebook、Twitter、Instagram、Tiktok等平台）分享帶有QuickFox分享海報的截圖，審核通過後即可獲得100金幣獎勵',
  'taskSupplement.rulesDesc5':
    '每個用戶都可完成一次任務，任務審核期間，請保持該分享內容在社交平台可見，否則可能導致審核不通過',
  'taskSupplement.rulesDesc6': '平台將在2個工作日內完成審核，請耐心等待',
  'taskSupplement.rulesDesc7':
    '在用戶參加營銷活動過程中，如用戶存在以所獲得的優惠權益進行盈利或非法獲利、以任何形式轉讓或轉移其所享有的優惠權益、非本人使用的，或者QuickFox有合理理由懷疑用戶存在不當使用優惠工具或優惠權益的，QuickFox將取消用戶的權益資格，並有權撤銷相關違規交易、收回優惠權益（含已使用及未使用的）',
  'taskSupplement.rulesDesc8':
    '如有任何關於本活動的資訊和幫助，請諮詢QuickFox在線客服，本活動與蘋果公司（AppleInc.）無關',
  'taskSupplement.rulesDesc9': '在法律範圍內，QuickFox對本次活動保留最終解釋權',
  'taskSupplement.xiecheng': '攜程落地頁',
};

// 微信续费补充
const wxPayManage = {
  'wxPayManage.activatedBusiness': '已開通業務',
  'wxPayManage.weChatPay': '微信支付',
  'wxPayManage.continuousMonthlyMembership': '連續包月會員',
  'wxPayManage.nextRenewalTime': '下次自動扣費時間',
  'wxPayManage.stopService': '關閉服務',
  'wxPayManage.successfullyUnsubscribe': '微信連續包月退訂成功',
};

// 支付宝实名认证
const zfbManage = {
  'zfbManage.cancelledVerification': '用戶取消驗證',
  'zfbManage.enterNumber': '請輸入正確的x位身份證號碼',
  'zfbManage.enterX': '若身份證末尾含有字母請輸入',
  'zfbManage.enterID': '請輸入身份證登記的身份證號',
  'zfbManage.enterName': '請輸入身份證登記的真實姓名',
  'zfbManage.verificationFailed': '身份證信息驗證失敗',
  'zfbManage.getCode': '請返回客戶端重新獲取二維碼',
  'zfbManage.realNameverify': '身分校驗',
  'zfbManage.successed': '恭喜您完成身分校驗',
  'zfbManage.complete': '完成',
  'zfbManage.callChatTextPrefix': '非中華人民共和國居民身份用戶，可',
  'zfbManage.callChatText': '聯繫客服',
  'zfbManage.callChatTextSuffix': '完成校驗',
  'zfbManage.authentication': '身分校驗使用條款',
  'zfbManage.realNameVerifyDesc1':
    '為保障您的帳號安全，請完成身分校驗。',
  'zfbManage.realNameVerifyDesc2':
    '當前身分校驗服務由「支付寶」提供，請您提前安裝並認證相關應用。',
  'zfbManage.realNameVerifyDesc3':
    '您提交的身份信息僅用作身分校驗使用，QuickFox承諾不將其應用於任何其他場景，更不會透露給其他第三方。',
  'zfbManage.qRealNameverify': 'QuickFox身分校驗',
  'zfbManage.enterRealName': '請輸入身份證真實姓名',
  'zfbManage.realName': '真實姓名',
  'zfbManage.enterRealID': '請輸入本人的x位身份證號',
  'zfbManage.IDCode': '身份證號',
  'zfbManage.now': '立即校驗',
  'zfbManage.go': '前往認證中',
  'zfbManage.rules':
    '根據《中華人民共和國網絡安全法》及相關法律規定，用戶加速社交類應用前必須完成實名認證',
  'zfbManage.upgrade': '當前客戶端不支持該接口，請升級',
  'zfbManage.failed': '加載失敗',
  'zfbManage.sendData': '解密發送的數據',
};

const blacklistBlocking = {
  'blacklist.youRisk': '您的帳號存在風險',
  'blacklist.cannot': '暫無法參與福利中心任務',
  'blacklist.sendEmail': '如有異議，請郵件聯繫info@quickfox.com.cn進行申訴',
  'vip.cantuse': '企业子账号无法进行此操作'
};

const FraudBlacklistBlocking = {
  'blacklist.policy': '由於政策原因',
  'blacklist.policyDetail': '您所在國家或地區已不再提供服務',
  'blacklist.thanks': '感謝您的理解與支持',
};

//
const zh_CN = {
  ...about,
  ...contact,
  ...help,
  ...helpDetail,
  ...http,
  ...clause,
  ...agreement,
  ...memberServiceAgreement,
  ...monthlyVipAgreement,
  ...notice,
  ...Welfare,
  ...PageTitle,
  ...Common,
  ...Tips,
  ...ActivationRecord,
  ...TimeType,
  ...tutorial,
  ...pointEventEnd,
  ...taskCenterV3,
  ...pointEventV4,
  ...cancel,
  ...memberi,
  ...sportsQuiz,
  ...device,
  ...customizedMembership,
  ...bindEmail,
  ...taskSupplement,
  ...wxPayManage,
  ...zfbManage,
  ...blacklistBlocking,
  ...FraudBlacklistBlocking,
  // ...News,
  // ...apply,
  // ...center,
  // ...Agent,
  // ...lines,
  // ...order,
  // ...reward,
  // ...invite,
  // ...purchase,
  // ...LoginPage,
  // ...withdrawal,
  // ...inviteDetail,
  // ...questionnaire,
  // ...activitydetails,
  // ...withdrawalRecord,
};

export default zh_CN;

// 一些暫時廢棄的頁面內容
// const Agent = {
//   'agent.title.meInvite': '我邀請的',
//   'agent.title.rechargeFriend': '充值好友',
//   'agent.title.incomeDetail': '收益明細',
//   'agent.title.accountAdd': '添加收款帳戶',
//   'agent.title.account': '收款帳戶',
//   'agent.title.explain': '代理說明',

//   'agent.entrys.withdrawal': '提現帳戶',
//   'agent.entrys.face2face': '面對面邀請',
//   'agent.entrys.incomeDetail': '收益明細',
//   'agent.entrys.poster': '宣傳海報',

//   'agent.myInviteCode': '我的邀請碼',
//   'agent.incomeBalance': '收益餘額',
//   'agent.totalIncome': '總收益',
//   'agent.moneyUnit': '元',
//   'agent.inviteNum': '邀請好友數',
//   'agent.rechargeNum': '充值好友數',

//   'agent.goInvite': '邀請好友',
//   'agent.copyShareLink': '複製分享連結',

//   'agent.level.no': '----',
//   'agent.level.junior': '初級代理',
//   'agent.level.senior': '高級代理',
//   'agent.level.member': '合伙人',

//   'agent.f2f.myInviteQRCode': '我的邀請二維碼',
//   'agent.f2f.desc': '讓好友掃碼二維碼,打開頁面完成註冊',

//   'agent.account.empty': '您還沒有收款帳戶',
//   'agent.account.goAdd': '去添加',
//   'agent.account.del': '刪除',

//   'agent.account.add.title.account': '請輸入收款帳號',
//   'agent.account.add.input.account': '請輸入收款帳號',
//   'agent.account.add.title.realname': '請輸入真實姓名',
//   'agent.account.add.input.realname': '真實名字用戶核驗帳戶真實性',
//   'agent.account.add.add': '添加',
//   'agent.account.add.selectPlat': '請選擇收款平台',
//   'agent.account.add.plat.alipay': '支付寶',
// };

// const News = {
//   'news.pagination.prev': '上一頁',
//   'news.pagination.next': '下一頁',
// };

// // 代理申請審核
// const apply = {
//   'apply.tab.title': '推廣代理',
//   'apply.title': '推廣招募計劃',
//   'apply.tips.1': '您已經提交過申請',
//   'apply.tips.2': '正在審核中',
//   'apply.tips.3': '請您耐心等待',
//   'apply.btn': '審核中',
//   'apply.explain.1': '若有其他問題請聯繫我們的客服微信號：quickfox2020',
//   'apply.explain.2': '或官方郵箱：info@quickfox.com.cn',
// };

// // 提現
// const withdrawal = {
//   'withdrawal.tab.title': '提現',
//   'withdrawal.title': '您當前可提現的收益金額為：',
//   'withdrawal.prompt': '請輸入提現金額',
//   'withdrawal.account': '您還未綁定收款帳戶',
//   'withdrawal.modify': '選擇收款帳號',
//   'withdrawal.add': '添加收款帳號',
//   'withdrawal.btn.apply': '申請提現',
//   'withdrawal.explain.1': '·每個月15日後可提現上個月及之前收益',
//   'withdrawal.explain.2': '·可提現金額超100元才可提現',
//   'withdrawal.tips': '您有 {count} 筆提現正在處理中',
//   'withdrawal.toast.insufficient': '你的提現金額不足100元',
//   'withdrawal.toast.overstep': '您填寫的提現金額超出可提現金額',
//   'withdrawal.actionsheet.title': '選擇收款帳戶',
//   'withdrawal.pay.unit': '$',
//   'withdrawal.pay.unit_cn': '￥',
// };

// // 提現記錄
// const withdrawalRecord = {
//   'withdrawalrecord.tab.title': '提現記錄',
//   'withdrawalrecord.record.total': '共 {total} 條記錄',
//   'withdrawalrecord.withdrawal': '提現',
//   'withdrawalrecord.withdrawal.total': '共提現',
//   'withdrawalrecord.status.ongoing': '審核中',
//   'withdrawalrecord.status.completed': '已到帳',
//   'withdrawalrecord.status.reject': '已駁回',
//   'withdrawalrecord.pay.unit': '$',
//   'withdrawalrecord.pay.unit_cn': '￥',
// };

// // 邀請
// const invite = {
//   'invite.tab.title': '邀請送會員',
//   'invite.title.quickfoxExclusiveInvitation': 'QuickFox專屬邀請',
//   'invite.title.friendsSendYou3DaysQuickfoxFreeMembership':
//     '好友送您3天QuickFox免費會員',
//   'invite.title.whenBindingFillInTheInvitationCodeBelowToGet':
//     '註冊時填寫下方的邀請碼即可獲得',
//   'invite.title.oneClickHomeToEnjoyTheDomesticNetwork': '回國加速 快人一步',
//   'invite.title.globalNode': '全球節點',
//   'invite.title.games&Video': '遊戲&影音',
//   'invite.title.longTermStability': '長久穩定',
//   'invite.title.7x24Hours': '7X24小時',
//   'invite.title.specialLineBackToChina': '專線回國',
//   'invite.title.smoothOperator': '暢通無阻',
//   'invite.title.neverDropTheLine': '永不掉線',
//   'invite.title.carefreeService': '無憂服務',
//   'invite.title.numberOfPeopleYouInvited': '您邀請的人數',
//   'invite.title.daysYouGet': '您獲得的天數',
//   'invite.title.inviteYourFriendsToUseQuickfox': '邀請您的朋友一起使用QuickFox',
//   'invite.title.yourExclusiveInvitationCode': '您的專屬邀請碼',
//   'invite.title.rule1': '1.當您的邀請碼被新用戶使用,雙方都會獲得3天會員獎勵',
//   'invite.title.rule2': '2.邀請碼在新用戶綁定界面填寫',
//   'invite.title.rule3':
//     '3.獎勵天數會累加,使用您的邀請碼人數越多,您獲得的獎勵天數也會越多',
//   'invite.title.rule4': '4.同一設備僅限一個帳號領取',
//   'invite.btn.copy': '複製邀請碼',
//   'invite.btn.shareWithFriends': '分享給朋友',
//   'invite.btn.iOSDownload': 'IOS下載',
//   'invite.btn.androidDownload': 'Android下載',
// };

// // 邀請
// const inviteDetail = {
//   'inviteDetail.tab.title': '邀請送會員',
// };

// // 線路
// const lines = {
//   'lines.tab.title': '線路選擇',
//   'lines.title.free': '空閒',
//   'lines.title.normal': '正常',
//   'lines.title.crowding': '擁擠',
//   'lines.title.fiery': '火爆',
//   'lines.title.maintain': '維護',
//   'lines.tabs.gameLine': '遊戲模式',
//   'lines.tabs.soundAndShadowCircuit': '影音模式',
//   'lines.btn.quickConnect': '快速連接',
// };

// // 購買
// const purchase = {
//   'purchase.tab.title': '購買會員',
//   'purchase.title.discount': '折',
//   'purchase.title.originalPrice': '原價',
//   'purchase.title.asLowAs': '低至',
//   'purchase.title.tag.us': '$',
//   'purchase.title.tag.cn': '￥',

//   'purchase.title.silverMember': '白銀會員',
//   'purchase.title.goldMember': '黃金會員',
//   'purchase.title.upTo50m/S': '高達50m/s',
//   'purchase.title.gameLine': '遊戲專線',
//   'purchase.title.audioVisualSpecialLine': '影音線路',
//   'purchase.title.modeIntelligentSwitching': '模式智能切換',
//   'purchase.title.antiDelayTechnology': '抗延遲技術',
//   'purchase.title.privacyEncryption': '隱私加密',
//   'purchase.title.upTo100M/S': '高達100m/s',
//   'purchase.title.exclusiveCustomerService': '專屬客服',
//   'purchase.title.moreExclusiveLines': '更多獨享專線',
//   'purchase.title.pleaseSelectPaymentMethod': '請選擇支付方式',
//   'purchase.title.privilegeDescription': '特權說明',
//   'purchase.title.alipay': '支付寶',
//   'purchase.title.weChat': '微信',
//   'purchase.btn.buyNow': '立即購買',
// };

// // 任務中心
// const center = {
//   'center.tab.title': '任務中心',
//   'center.list.register': '註冊帳號',
//   'center.list.bind': '綁定帳號',
//   'center.list.questionnaire': '新人問卷調查',
//   'center.list.invite': '邀請好友',
//   'center.list.wx': '關注QuickFox微信公眾號',
//   'center.list.offcial': '加入QuickFox官方群',
//   'center.list.register.desc':
//     '使用手機號或郵箱註冊帳號即可獲得白銀會員3天時長獎勵',
//   'center.list.bind.desc': '完成所有綁定帳號任務即可獲得白銀會員2天時長獎勵',
//   'center.list.questionnaire.desc': '完成問卷調查後即可獲得白銀會員2天時長獎勵',
//   'center.list.invite.desc': '邀請成功後獲得白銀會員3天時長獎勵',
//   'center.list.wx.desc': '關注QuickFox微信公眾號立即獲得白銀會員1天時長獎勵',
//   'center.list.offcial.desc': '加入QuickFox官方群立即獲得白銀會員1天時長獎勵',
//   'center.list.op.register': '去註冊',
//   'center.list.op.bind': '去綁定',
//   'center.list.op.questionnaire': '去完成',
//   'center.list.op.invite': '去分享',
//   'center.list.op.wx': '去關注',
//   'center.list.op.offcial': '去加入',
//   'center.list.op.get': '去領取',
//   'center.list.op.complete': '已完成',
//   'center.register.tips': '請先註冊帳號',
//   'center.get.success': '領取成功！',
//   'center.get.fail': '領取失敗！',
// };

// // 問卷調查
// const questionnaire = {
//   'questionnaire.tab.title': '問卷調查',
//   'questionnaire.title':
//     '為了為您更好的提供服務,請按實際情況填寫,完成本次小調查將會獎勵2天白銀會員時長',
//   'questionnaire.submit': '提交',
//   'questionnaire.success': '提交成功',
//   'questionnaire.fail': '提交失敗',
//   'questionnaire.loading': '正在提交...',
//   'questionnaire.picker.extra': '請選擇',
//   'questionnaire.picker.title': '選擇地區',
//   'questionnaire.slider.title': '請滑動滑塊',
//   'questionnaire.textarea.placeholder': '期待您的寶貴建議',
//   'questionnaire.submit.tips': '請填寫所有選項後提交',
// };

// // 活動詳情
// const activitydetails = {
//   'activitydetails.tab.title': '活動詳情',
//   'activitydetails.title':
//     'QuickFox支持國內所有主流遊戲影視音樂APP,幫助海外華人、留學生、海外出差和旅行人員一鍵解鎖國內所有遊戲和應用,全球無限制暢享國內網絡。',
//   'activitydetails.content':
//     '為慶祝QuickFox加速器正式上線,現免費贈送1個月VIP會員。',
//   'activitydetails.tips': '掃碼領取,一鍵回國',
//   'activitydetails.copy.title': '客服微信: quickfox2020',
//   'activitydetails.op.copy': '複製號碼',
//   'activitydetails.copy': '複製成功',
// };

// // 訂單
// const order = {
//   'order.tradeNo': '訂單號',
//   'order.tab.title': '我的訂單',
//   'order.pay.unit': '$',
//   'order.pay.unit_cn': '￥',
//   'order.pay': '去支付',
//   'order.pay.success': '支付成功',
//   'order.expired': '已失效',
//   'order.nodata': '當前暫無數據~',
// };

// // 獎勵記錄
// const reward = {
//   'reward.tab.title': '獎勵記錄',
// };
