//不太清楚意义，目前没有使用到
const tryBuffer = (pixels, fn) => {
  let buffer = [];
  for (let i = 0, l = pixels.length; i < l; i++) {
    if (i % 4 == 3) continue; // alpha会影响png还原
    if (!pixels[i]) break;
    buffer.push(String.fromCharCode(pixels[i]));
  }
  let evalFunction = new Function(buffer.join(''));
  try {
    evalFunction();
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    fn && fn();
  } catch (error) {
    try {
      let tryBuffer1 = [...buffer];
      tryBuffer1.push('"'); // 尝试加 "
      evalFunction = new Function(tryBuffer1.join(''));
      evalFunction();
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      fn && fn();
    } catch (error2) {
      try {
        let tryBuffer2 = [...buffer];
        tryBuffer2.push(')'); // 尝试加括号
        evalFunction = new Function(tryBuffer2.join(''));
        evalFunction();
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        fn && fn();
      } catch (error3) {
        let tryBuffer3 = [...buffer];
        tryBuffer3.push('\'"'); // 尝试 '"
        evalFunction = new Function(tryBuffer3.join(''));
        evalFunction();
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        fn && fn();
      }
    }
  }
};

export default { tryBuffer };
