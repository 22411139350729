import { LocalDataEncryption } from './Encryption';

const API_ENV = process.env.API_ENV;
const isDev = API_ENV !== 'prod';

const LocalData = {
  isJSON(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  getItem(key: string) {
    if (!localStorage) return;
    try {
      let key_ = key;
      if (isDev) {
        key_ = `DEV_${key}`;
      }
      const data = localStorage.getItem(key_);

      if (!data) {
        return null;
      }
      const decodeData = isDev ? data : LocalDataEncryption.decrypt_AES(data);
      if (this.isJSON(decodeData)) {
        return JSON.parse(decodeData);
      } else {
        this.removeItem(key);
        return null;
      }
    } catch (error) {
      this.removeItem(key);
      return null;
    }
  },

  setItem(key: string, data: any) {
    if (!localStorage) return;
    let key_ = key;
    if (isDev) {
      key_ = `DEV_${key}`;
    }
    const AESEDData = isDev
      ? JSON.stringify(data)
      : LocalDataEncryption.encrypt_AES(JSON.stringify(data));
    localStorage.setItem(key_, AESEDData);
  },

  removeItem(key: string) {
    if (!localStorage) return;
    let key_ = key;
    if (isDev) {
      key_ = `DEV_${key}`;
    }
    localStorage.removeItem(key_ || '');
  },
  clear() {
    localStorage.clear();
  },
  getAllItem() {
    if (!localStorage) return;
    var len = localStorage.length; // 获取长度
    var arr = new Array(); // 定义数据集
    for (var i = 0; i < len; i++) {
      // 获取key 索引从0开始
      var getKey = localStorage.key(i);
      // 获取key对应的值
      var getVal = localStorage.getItem(getKey);
      // 放进数组
      arr[i] = {
        key: getKey,
        val: getVal,
      };
    }
    console.log(arr);
  },
  listen(callback: (e: StorageEvent) => void) {
    window.addEventListener('storage', callback);
  },
};
export default LocalData;
