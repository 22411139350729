/**
 * 埋点名称管理
 */
export const TrackEventName = {
  //只有移动端有的埋点
  mobile_special: new Set([
    'SVIPRecord_PV',
    'SVIPRecord_OrderRefund_C',
    'SVIPRecord_ConfirmRefund_C',
    'MyMessageCenterLetter_Click',
    'Shop_Banner_Click',
    'Shop_Goods_Click',
    'ShopGoods_PV',
    'ShopCoinDetail_PV',
    'ShopChangeDetail_PV',
    'ZuanQian_QianDao_Click',
    'ZuanQian_QianDaoXiZe_Click',
    'ZuanQian_InviteMissoion_Click',
    'ZuanQian_GuanZhuMissoion_Click',
    'ZuanQianGuanZhuMissoion_PV',
    'ZuanQianInvite_PV',
    'ZuanQianInvite_CopyLink_Click',
    'ZuanQianInvite_Invite_Click',
    'ZuanQianInvite_Invite_C',
    // 'ZuanQianInvite_Poster&F2F_Click',
    'ZuanQianInvite_InviteCount_Click',
    'ZuanQianInvite_UseCoin_Click',
    'FuLiQianDao_PV',
    'Fuli_Shop_Click',
    'FuLiQianDao_QianDao_Click',
    'FuLi_QianDaoMission_Click',
  ]),
  //只有pc端有的埋点
  pc_special: new Set([
    // 'Help_PV', //帮助页浏览量
    'FuLi_PV', //福利页浏览量
  ]),
};
