import React, { FunctionComponent } from 'react';
import picBlank from './images/blank.png';
import Style from './style.scss';
interface IProps {
  text?: string;
}

const ShowControl: FunctionComponent = (props: IProps) => {
  const { text } = props;
  return (
    <div className={Style.noData}>
      <img src={picBlank} alt="" />
      <div className={Style.text}>{text || '暂无数据~'}</div>
    </div>
  );
};

export default ShowControl;
