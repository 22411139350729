import { JudgePf } from '../../utils/JudgePf';

const os = JudgePf();
const isPc = os.isPc;

const $phone3dot2vw = '3.2vw';
const $phone3dot333vw = '3.333vw';
const $phone6dot4vw = '6.4vw';
const $phone7vw = '7vw';
const $phone8vw = '8vw';
const $phone8dot933vw = '8.933vw';

const $pc3dot2vw = isPc ? '20px; line-height: 48px' : $phone3dot2vw;
const $pc3dot333vw = isPc ? '25px; line-height: 48px' : $phone3dot333vw;
const $pc6dot4vw = isPc ? '20px; line-height: 48px' : $phone6dot4vw;
const $pc7vw = isPc ? '25px; line-height: 48px' : $phone7vw;
const $pc8vw = isPc ? '25px; line-height: 48px' : $phone8vw;
const $pc8dot933vw = isPc ? '25px; line-height: 48px' : $phone8dot933vw;

let memberAgreementTxt = `<div style="color: #fff; font-size: ${$pc3dot2vw}; padding: ${$pc8vw} ${$pc8dot933vw}; box-sizing: border-box">
    <div style="font-size: ${$pc3dot333vw};text-align: center; margin-bottom: ${$pc7vw};"> QuickFox超級VIP服務協議</div>
    <div style="line-height: ${$pc6dot4vw};">歡迎您使用QuickFox超級VIP會員服務！ </div>
 
    <div style="line-height: ${$pc6dot4vw}; color: rgb(236, 207, 171);">
        QuickFox超級VIP服務由福建紫訊信息科技有限公司旗下應用程序QuickFox加速器（以下亦稱“QuickFox”）向您提供。 QuickFox是一款面向海外華人和留學生打造專業的永久免費回國加速器，身在海外，一鍵加速回國，國內視頻、音樂、遊戲加速助手。 <br />
        《QuickFox超級VIP服務協議》（以下亦稱“本協議”）由您（以下亦稱“會員”或“超級VIP”）和QuickFox進行締結，對雙方具有同等法律效力。 QuickFox建議您仔細閱讀本協議的全部內容，尤其是以加粗形式展示的，與您的權益（可能）存在重大關係的條款（包括相關約定QuickFox責任、您享有的權利、爭議解決方式及司法管轄等條款），請您留意重點閱讀。若您認為本協議中的加粗條款可能會導致您的部分或全部權利或利益受損，請您務必再次仔細閱讀，在確保您已經理解、接受了加粗條款的前提下，繼續使用QuickFox超級VIP服務。 <br />
        如果您不同意本協議的任一或全部條款內容，請不要以確認形式（包括但不限於支付行為/接受贈與、或完成了成為會員的全部程序而在此過程中未向QuickFox提出關於本協議的任何異議、或使用超級VIP服務）進行下一步操作或使用QuickFox超級VIP服務。當您以確認形式進行下一步操作或使用QuickFox超級VIP服務時，即表示您與QuickFox已達成協議關係，您自願接受本協議並遵守本協議項下的全部約定。 <br />
        QuickFox有權變更本協議內容，一旦本協議內容髮生變更的，QuickFox將在相應頁面、站內信或以其他合理方式進行通知，請您仔細閱讀。如果您不同意變更的內容的，您可以選擇停止使用超級VIP服務。如您繼續使用超級VIP服務的，則視為您已經同意變更的全部內容。更新後的協議自文首更新之日起生效。 <br />
        若您是未成年人，請您在監護人監護、指導下閱讀並決定是否同意本協議。 <br />
    </div>
     <div style="line-height: ${$pc6dot4vw};">
        一、服務說明<br />
        1. <span
            style="color: rgb(236, 207, 171);">【QuickFox超級VIP】</span>是指完成了成為會員的所有程序，且在遵守本協議的前提下，在會員時長有效期內享受QuickFox提供的超級VIP服務的自然人。 <br />
        2. <span
            style="color: rgb(236, 207, 171);">【QuickFox超級VIP服務】</span>是由QuickFox向您提供的專享的會員權益，具體權益內容說明請您查看本協議第四節。 <br />
        3. <span
            style="color: rgb(236, 207, 171);">【QuickFox超級VIP服務協議】</span>是《QuickFox服務協議》、《QuickFox隱私政策》的補充協議，是其不可分割的一部分，與其具有同等法律效力；本協議如與前述協議發生衝突的，以本協議為準。 <br />
        4. <span
            style="color: rgb(236, 207, 171);">【QuickFox超級VIP服務規則】</span>是QuickFox在QuickFox超級VIP中心已經或未來不時發布的與超級VIP服務相關的協議、公告、頁面說明、通知、FAQ等內容。前述內容一經發布即生效，均構成本協議不可分割的一部分。 <br />
         </div>
    <div style="line-height: ${$pc6dot4vw};">
        二、使用服務<br />
        1. 服務獲取<br />
        1.1 QuickFox超級VIP服務為收費服務，您可以通過支付相應的服務費用購買；同時，您亦可以通過營銷活動、接受禮品卡等QuickFox認可的途徑獲取服務。 <br />
        1.2 您在獲取QuickFox超級VIP服務時，應當遵守法律法規、本協議約定，不侵犯第三方或QuickFox的合法權益。您不得自行（或協助他人）通過以下方式獲取超級VIP服務：<br />
        （1） 以商業性或其他非個人使用等目的；<br />
        （2） 通過機器人軟件、蜘蛛軟件、爬蟲軟件等任何自動程序、腳本、軟件等方式；<br />
        （3） 未經QuickFox允許通過轉移、贈與、借用、租用、銷售、轉讓超級VIP服務的方式；<br />
        （4） 通過不正當手段或以違反誠實信用原則的方式（如利用規則漏洞、利用系統漏洞、濫用會員身份、黑色產業、投機等）；<br />
        （5） 通過利用或破壞QuickFox超級VIP服務規則的方式。 <br />
        <span
            style="color: rgb(236, 207, 171);">QuickFox在此聲明：任何未經QuickFox明示授權而銷售、轉讓QuickFox超級VIP資格的行為屬於非法銷售、非法轉讓，QuickFox有權追究其法律責任。 </span><br />
        2. 服務使用的基本原則<br />
        2.1 您在使用QuickFox超級VIP服務的過程中，應遵守法律法規及其他規範性文件，包括但不限於《中華人民共和國保守國家秘密法》、《中華人民共和國著作權法》、《中華人民共和國計算機信息系統安全保護條例》、《計算機軟件保護條例》、《互聯網電子公告服務管理規定》、《中華人民共和國網絡安全法》、《信息網絡傳播權保護條例》等；應遵守公共秩序，尊重社會公德，不得危害網絡安全，不得利用網絡從事危害國家安全、榮譽和利益，煽動顛覆國家政權、推翻社會主義制度，煽動分裂國家、破壞國家統一，宣揚恐怖主義、極端主義，宣揚民族仇恨、民族歧視，傳播暴力、淫穢色情信息，編造、傳播虛假信息擾亂經濟秩序和社會秩序，以及侵害他人名譽、隱私、知識產權和其他合法權益等活動。在任何情況下，QuickFox一旦合理地認為您存在上述行為的，可以在任何時候，不經事先通知終止向您提供QuickFox超級VIP服務。 <br />
        2.2 QuickFox授予您對QuickFox超級VIP服務一項個人的、非獨家的、不可轉讓的、非商業用途的、可撤銷的、有期限的使用許可。 <span
            style="color: rgb(236, 207, 171);">即：您僅可出於個人、非商業的目的使用超級VIP服務。 </span><br />
        3. 服務內容的變更<br />
        <spanstyle="color: rgb(236, 207, 171);">
            QuickFox有權根據法律法規及政策變更、版權方要求、自身運營策略變更對超級VIP服務內容（包括但不限於超級VIP權益細則、收費標準、收費方式）進行部分或全部變更。就前述變更，QuickFox將通過相應服務頁面、站內信通知或以其他合理方式進行發布，並於發布之日起生效。發布後，您繼續操作的行為（包括但不限於點擊同意、或繼續購買、或完成支付行為、或使用超級VIP服務等），均視為您已經接受前述變更。
            </spanstyle=><br />
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        三、您的賬號<br />
        1. 賬號獲得<br />
        在您的超級VIP服務的有效期限內，您享有超級VIP權益的QuickFox賬號即為您的QuickFox超級VIP賬號（即：與您的超級VIP服務綁定的QuickFox賬號，以下亦稱“超級賬號”或“會員賬號”）。 <br />
        同時，QuickFox在此善意提醒您，您應當在遵守QuickFox賬號使用規則的前提下，使用您的會員賬號。 QuickFox建議您查閱《QuickFox服務協議》及相關子協議、各類公告、頁面說明和規範流程、FAQ等以獲得更多關於賬號使用規則的內容。 <br />
        2. 登錄<br />
        除您以遊客模式（具體說明見第三節第8條）購買QuickFox超級VIP服務的，QuickFox超級VIP服務需要您登錄您的QuickFox超級VIP賬號方可使用。 <br />
        3. 賬號管理及安全<br />
        &nbsp;&nbsp;3.1  您應自行負責並妥善、正確地保管、使用、維護您的超級VIP賬號和密碼，並對您的賬號和密碼採取必要和有效的保密措施。 <span
            style="color: rgb(236, 207, 171);">非QuickFox法定過錯導致的任何遺失、洩露、被篡改、被盜以及其他因保管、使用、維護不當而造成的損失，您應自行承擔。 </span><br />
        &nbsp;&nbsp;3.2  如果您發現有人未經授權使用了您的賬號或您的賬號存在其他異常情況導致無法正常登錄使用的，則您需要按照QuickFox官方公佈的賬號找回流程進行賬號找回。在找回過程中，QuickFox可能會要求您提供相應信息及/或證明資料，<span
            style="color: rgb(236, 207, 171);">請確保您所提供的內容真實有效，否則將可能無法通過QuickFox的驗證而導致找回失敗。 </span><br />
        &nbsp;&nbsp;3.3  為保護賬號安全，防止賬號被盜等情況發生，QuickFox可能會不時或定期採用一種或多種方式對賬號使用者進行用戶身份驗證（如短信驗證、郵件認證等）、< span
            style="color: rgb(236, 207, 171);">如未成功通過驗證的，QuickFox有合理理由懷疑該賬號出現被盜等不安全情況，並視情節嚴重情況而決定是否中止向該賬號繼續提供會員服務及/或採取進一步措施。 </span><br />
        &nbsp;&nbsp;3.4  請您特別注意，<span
            style="color: rgb(236, 207, 171);">您的超級VIP賬號下的行為視為您本人的行為，您應對您的超級賬號下發生的活動或通過該賬號進行的活動負責。 </span><br />
        4. 信息查詢<br />
        您可以通過登錄QuickFox超級VIP中心免費查詢您的賬號信息詳情，包括已開通的超級VIP服務內容、服務期限、消費記錄等。 <br />
        5. 登錄限制<br />
        &nbsp;&nbsp;5.1  <span
            style="color: rgb(236, 207, 171);">QuickFox超級VIP賬號可在手機端、Pad端、電腦端以及上述終端相應的網頁端使用，具體適用的設備終端以QuickFox實際提供為準。 </span><br />
        &nbsp;&nbsp;5.2  為了您順利使用QuickFox超級VIP服務，QuickFox在此建議您，您可以在產品中查閱、管理您的超級VIP賬號的設備終端登錄情況。 <br />
        6. 賬號獨立<br />
        <span
            style="color: rgb(236, 207, 171);">您的超級賬號僅限您本人出於非商業目的進行使用。除非QuickFox同意，會員權益不可在不同賬號之間進行轉移、遷徙、轉讓、贈與、售賣、租借、分享，即使前述不同賬號由同一人擁有使用權。 QuickFox在此善意提醒您，您在進行會員服務購買/參與活動時請注意區分，以免造成不必要的損失。 </span><br />
        7. 權利歸屬<br />
        <span
            style="color: rgb(236, 207, 171);">QuickFox超級VIP賬號、超級VIP賬號下的超級VIP服務、虛擬產品（商品）及其涉及的產品及/或服務的所有權及相關知識產權歸QuickFox所有或經過授權使用，您僅擁有前述產品及/或服務的有限使用權。但您經合法渠道取得的實體產品所有權、賬號下歸屬於您的個人財產、QuickFox另有說明的其他權益除外。 </span><br />
        8. 遊客模式<br />
        &nbsp;&nbsp;8.1 <span
            style="color: rgb(236, 207, 171);">因蘋果應用商店渠道允許非註冊用戶通過遊客方式購買超級VIP服務，會員服務將會綁定您購買會員時所用的設備，超級VIP服務也將僅可在綁定設備上使用，且部分會員權益可能會因為您使用遊客模式而無法享受（如活動參與）。若您設備丟失、損壞、進行系統升級、還原、清空或其他類似行為的，將會導致您無法繼續使用綁定於此設備的超級VIP權益，由此造成的您的損失，您應當自行承擔。 </span><br />
        &nbsp;&nbsp;8.2 QuickFox在此建議您按照頁面操作指引綁定您的QuickFox註冊賬號，以便可在您所擁有的其他設備上使用會員權益及更好的享受QuickFox所提供給您的會員權益。 <br />
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        四、會員權益及額外付費相關<br />
        1. 超級VIP權益<br />
        <span
            style="color: rgb(236, 207, 171);">關於“超級VIP”贈送會員權益的特別說明：若您是QuickFox超級VIP，QuickFox還將贈送您其他QuickFox自有或合作的會員服務，具體贈送的會員權益類型、該等會員權益的具體內容及使用規則（包括適用設備終端）以相關會員協議約定及/或頁面的說明為準。您的前述會員權益將直接提供至您綁定超級VIP賬號的手機號所綁定的相關會員賬號下（如：您QuickFox超級VIP綁定的手機號是12345678900，則您的QuickFox超級VIP權益將直接提供至手機號12345678900所綁定的超級VIP賬號），且有效期自您開通QuickFox超級VIP服務（或升級成為QuickFox超級VIP）之日起算，因此QuickFox在此善意提醒您，請記得及時進行使用。 </span><br />
        2. 設備及系統差異<br />
        您成為超級VIP後，可能會因您使用的軟件版本、設備、操作系統等不同以及其他第三方原因導致實際可使用的具體權益或服務內容有差別，由此可能給您帶來的不便，您表示理解且不予追究或豁免QuickFox的相關責任。 QuickFox建議您可以通過升級應用程序或操作系統版本、更換使用設備等方式來嘗試解決，或者直接通過本協議提供的方式與QuickFox聯繫進行解決。 <br />
        <span
            style="color: rgb(236, 207, 171);">QuickFox在此特別提醒您，使用QuickFox超級VIP權益需要滿足一定軟件版本、設備及/或操作系統要求，因此，QuickFox建議您及時升級應用程序或操作系統版本、或更換使用設備以順利的享受QuickFox超級VIP權益。 </span><br />
        3. 虛擬產品<br />
        您在使用超級VIP服務過程中可能獲得由我們提供的各種積分、卡券、優惠券、代金券、福利券、成長值等虛擬產品。前述虛擬產品僅能用於QuickFox官方渠道公佈的指定用途，不能退貨、兌換現金或轉讓、買賣、置換、抵押等，在使用後亦不會恢復。 <br />
        同時，請您理解，QuickFox有權根據實際運營策略變更，變更其使用規則（包括使用有效期）。 <br />
        4. 活動參與<br />
        在超級VIP服務期限內，您有權選擇參加由QuickFox組織的超級VIP活動並享有由QuickFox提供的各項優惠。具體活動及優惠的內容及規則以QuickFox實際提供為準。 <br />
        5. 優惠政策<br />
        QuickFox可能會根據實際運營情況對不同階段已經購買且持續有效的超級VIP服務給予延期、權益升級等方面的優惠，具體優惠政策以QuickFox在相關服務頁面通知的內容為準。 <br />
        6. 使用規範<br />
        您在獲取、使用QuickFox超級VIP服務(包括參與活動)時，應遵循QuickFox官方渠道公佈的流程、平台規範等：<br />
        &nbsp;&nbsp;6.1 不得通過未經QuickFox授權或公佈的途徑、方式、渠道非法獲取QuickFox超級VIP服務（包括但不限於部分或全部會員權益、超級VIP賬號）及/或參與活動獲取福利（包括但不限於會員權益、虛擬產品、實體商品、會員服務兌換碼，本條下同）；<br />
        &nbsp;&nbsp;6.2 不得通過不正當手段或以違反誠實信用原則的方式（如利用規則漏洞、利用系統漏洞、濫用會員身份、黑色產業、投機等違背QuickFox提供服務/舉辦活動的初衷的方式）參與活動或者獲取福利。 <br />
        <span
            style="color: rgb(236, 207, 171);">若您違反上述約定，則QuickFox有權對您所獲得的福利作出刪除、取消、清零等處理，且有權終止向您提供服務，由此造成的全部損失由您自行承擔。 </span><br />
        7.  服務期限<br />
        &nbsp;&nbsp;7.1  您的超級VIP服務的服務期限以您自行選擇並支付相應會員費用對應的服務期限為準，自您成為超級VIP之時起算，您可以通過登錄QuickFox超級VIP會員中心免費查詢。當會員服務期限到期後，QuickFox將停止繼續向您提供超級VIP服務；但如您開通自動續費服務且您在服務期限到期前續費成功的，會員服務期限將在原服務期限的基礎上順延。 <span
            style="color: rgb(236, 207, 171);">自動續費的超級VIP服務在當前週期內的服務有效期到期前，您可以單方面停止自動續費，QuickFox亦可以根據運營策略需要而單方面停止繼續向您自動續費服務，超級VIP服務期限自當前服務週期屆滿之日起終止。 </span><br />
        &nbsp;&nbsp;7.2  <span
            style="color: rgb(236, 207, 171);">請您理解，超級VIP服務期限中包含QuickFox解決故障、服務器維修、調整、升級等或因第三方侵權處理所需用的合理時間，對上述情況所需用的時間或造成的任何損失（如有），QuickFox不予任何形式的賠償/補償，但QuickFox會盡可能將影響降至最低。 </span><br />
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        五、收費及退訂<br />
        1.  收費方式<br />
        QuickFox超級VIP服務為收費服務，您可通過QuickFox實際支持的付費方式完成超級VIP費用的支付，如銀行卡支付、第三方支付等。請您注意，若您使用您的蘋果賬戶或與您的會員賬號綁定的通信賬戶進行支付，此付費方式為代收費運營商託收的付款方式，您通過此種付費方式付費可能存在一定的商業風險（如不法分子利用您賬戶或銀行卡等有價卡等進行違法活動），該等風險可能會給您造成相應的經濟損失，您應自行承擔全部損失。 <br />
        2.  費用退還<br />
        超級VIP服務系網絡商品和虛擬商品，採用先收費後服務的方式，會員費用是您所購買的會員服務所對應的網絡商品價格，而非預付款或者存款、定金、儲蓄卡等性質，<span
            style="color: rgb(236, 207, 171);">超級VIP服務開通後，每個帳號可享受一次七天無理由退款服務，會員費用將原路退還至您的支付賬戶。超過退款次數和時限的訂單將無法發起退款。 </span><br />
        QuickFox在此特別提醒您，您在購買超級VIP服務（包括自動續費服務）之前應仔細核對賬號信息、購買的服務內容、價格、服務期限等信息。 <br />
        3.  收費標準、方式的變更<br />
        <span
            style="color: rgb(236, 207, 171);">超級VIP服務收費標準由QuickFox根據公司的運營成本、運營策略等綜合考慮後獨立決定（調整包括但不限於促銷、漲價等），並在相關的產品服務宣傳及支付頁面向您展示；若您在購買和續費時，相關收費方式發生變化的，以QuickFox實際支持的收費方式為準；相關服務的價格發生了調整的，應以QuickFox平台上公示的現時有效的價格為準（但QuickFox與您另有約定的情形除外）。您同意您繼續操作的行為（包括但不限於點擊同意、或繼續購買、或完成支付行為、或使用超級VIP服務、或進行額外付費等），即視為您知悉並同意變更後的收費方式、收費標準。 </span><br />
        4.  自動續費<br />
        自動續費服務是指在會員已購買超級VIP服務的前提下，出於會員對於自動續費的需求，避免會員因疏忽或其他原因導致未能及時續費而推出的服務。如會員選擇開通自動續費服務的，即會員授權QuickFox可在會員服務期限即將過期時或會員服務的訂閱週期即將到期時，委託支付渠道從與您的自有充值賬戶、綁定的第三方支付賬戶、銀行卡、通信賬戶等（以下統稱“賬戶”）餘額中代扣下一個計費周期的費用。該服務實現的前提是您已開通自動續費及綁定相關賬戶，且可成功從其上述賬戶中足額扣款。 <br />
        4.1  計費周期：如月度、季度、年度等（具體以QuickFox提供的為準），會員可自行選擇。 <br />
        4.2  自動扣款規則：<br />
        &nbsp;&nbsp;（1）會員選擇開通自動續費的，則視為同意授權QuickFox可在會員服務期限即將過期時，依據支付渠道的扣款規則發出扣款指令，並同意支付渠道可以根據QuickFox發出的扣款指令，在不驗證會員賬戶密碼、支付密碼、短信校驗碼等信息的情況下從賬戶中扣劃下一個計費周期的費用。蘋果IOS渠道的自動扣費規則以蘋果公司的規定為準。 <br />
        &nbsp;&nbsp;（2）除非會員或QuickFox主動明確地取消了自動續費，否則，自動扣款長期有效、不受次數限制。 <br />
        &nbsp;&nbsp;（3）<span
            style="color: rgb(236, 207, 171);">如果您未主動明確地取消自動續費，則將視為您同意QuickFox可依據支付渠道的扣款規則在會員服務期限到期後的一定期限內進行不時的扣款嘗試（即使您賬戶內金額不足）。 </span><br />
        &nbsp;&nbsp;（4）一旦扣款成功，QuickFox將為您開通本次計費周期對應的超級VIP服務。 <br />
        4.3  取消方式：<br />
        &nbsp;&nbsp;（1）蘋果移動設備端：蘋果移動設備“APP Store” --> 點擊右上角蘋果賬戶頭像，進入“賬戶”--> “訂閱”，選擇“QuickFox”取消訂閱；<br / >
        &nbsp;&nbsp;（2）安卓移動設備端：暫時不支持用戶在客戶端取消自動續費，需在支付平台自行取消；<br />
        您知悉並同意，自動續費狀態更新可能存在顯示延時，具體以QuickFoxAPP內顯示的您的續費狀態為準。 <span
            style="color: rgb(236, 207, 171);">且您在成功取消前，已經委託QuickFox自動續費的扣款指令仍然有效，QuickFox對於基於該指令在取消操作完成前已經扣除的費用不予退還和補償。 </span><br />
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        六、您的行為規範和違約處理<br />
        1. <span style="color: rgb(236, 207, 171);">在您使用QuickFox超級VIP服務的過程中，不得存在以下行為：</span><br />
        &nbsp;&nbsp;1.1  未經QuickFox明確授權，通過技術手段對服務內容、服務期限、消費金額、交易狀態等信息進行修改；<br />
        &nbsp;&nbsp;1.2  將超級VIP服務通過非QuickFox明確授權的方式（包括但不限於轉移、贈與、借用、租用、銷售、轉讓）提供他人使用；<br />
        &nbsp;&nbsp;1.3  對QuickFox用於保護超級VIP服務的任何安全措施技術進行破解、更改、反操作、篡改或其他破壞；<br />
        &nbsp;&nbsp;1.4  未經QuickFox事先書面同意，刪除超級VIP服務內容上的任何所有權或知識產權聲明或標籤；<br />
        &nbsp;&nbsp;1.5  未經QuickFox明確授權，採用收費或免費的方式，在任何公開場合全部或部分展示超級VIP服務內容（但如您的上述行為不構成侵權的除外）；<br />
        &nbsp;&nbsp;1.6  其他未經QuickFox明示授權許可或違反本協議、法律法規或監管政策、侵犯第三方或QuickFox合法權益的行為。 <br />
        <div style="color: rgb(236, 207, 171);">
            2.
            您知悉並同意，如您存在或QuickFox經獨立判斷後認為您存在任何違反國家法律法規或監管政策、違反本協議或有損QuickFox或/及其關聯公司的聲譽、利益的行為的，QuickFox有權獨立決定採取以下一項或多項處理措施：<br />
            &nbsp;&nbsp;2.1  如本協議對此行為有單獨條款約定處理方式的，按照該條款處理；<br />
            &nbsp;&nbsp;2.2  無需通知您而採取一種或多種措施制止您的行為及行為產生的後果，如刪除/屏蔽相關鏈接或內容、限制/取消您的賬號/賬戶使用權限等；<br / >
            &nbsp;&nbsp;2.3  無需通知您而中斷或終止部分或全部超級VIP服務，且您已交納的超級VIP服務費用將不予退還且不獲得任何形式的補償/賠償；<br />
            &nbsp;&nbsp;2.4  如您的行為使QuickFox或/及其關聯公司遭受任何損失的，您應當承擔全部損失賠償責任並在QuickFox要求的時限內完成費用支付。 <br />
        </div>
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        七、服務的中斷和終止<br />
        <p>
            1.   本服務的中斷或終止包括如下情況：<br />
            &nbsp;&nbsp;1.1  您主動提出要求的；<br />
            &nbsp;&nbsp;1.2  您存在或QuickFox經獨立判斷後認為您存在任何違反國家法律法規或監管政策、違反本協議或有損QuickFox或/及其關聯公司的聲譽、利益的行為的；<br />
            &nbsp;&nbsp;1.3  QuickFox根據法律法規、監管政策的規定或有權機關的要求；<br />
            &nbsp;&nbsp;1.4  QuickFox為維護賬號與系統安全等緊急情況之需要；<br />
            &nbsp;&nbsp;1.5  不可抗力（鑑於互聯網之特殊性質，不可抗力亦包括黑客攻擊、電信部門技術調整導致之重大影響、因政府管製而造成之暫時關閉、病毒侵襲等影響互聯網正常運行之情形）；<br />
            &nbsp;&nbsp;1.6  其他QuickFox無法抗拒的情況。 <br />
        <div style="color: rgb(236, 207, 171);">
            2.   當發生前述終止的情況時，您與QuickFox均認可如下處理方式：<br />
            &nbsp;&nbsp;2.1  已經產生但未使用的超級VIP權益自動清除且不折現；<br />
            &nbsp;&nbsp;2.2  如您在QuickFox平台內有正在進行中的交易，QuickFox屆時將視情況進行合理處理；<br />
            &nbsp;&nbsp;2.3  除法律法規另有規定或QuickFox另有說明外，QuickFox已收取的會員費用不予退還；<br />
            &nbsp;&nbsp;2.4  如因您違反本協議導致終止的，QuickFox有權視情況要求您承擔相應的違約責任；<br />
            &nbsp;&nbsp;2.5  除法律法規另有規定或QuickFox另有說明外，QuickFox無需向您和第三人承擔任何責任。 <br />
        </div>
        </p>
         
    </div>
    <div style="line-height: ${$pc6dot4vw};">
        八、您的個人信息保護<br />
        1.   QuickFox深知個人信息對您的重要性，因此QuickFox非常重視保護您的個人信息，亦將您的個人信息以高度審慎的義務對待和處理。在您使用QuickFox超級VIP服務的過程中，QuickFox將採用相關技術措施及其他安全措施來保護您的個人信息。 <br />
        2.   未成年人保護<br />
        QuickFox非常注重未成年人的保護。 <br />
        &nbsp;&nbsp;2.1  若您為未成年人，應在監護人監護、指導下閱讀本協議，並且使用超級VIP服務已經得到監護人的同意。 <br />
        &nbsp;&nbsp;2.2  <span
            style="color: rgb(236, 207, 171);">監護人應指導子女上網應該注意的安全問題，防患於未然。若監護人同意未成年人使用超級VIP服務，必須以監護人名義申請消費，並對未成年人使用超級VIP服務進行正確引導、監督。未成年人使用超級VIP服務，以及行使和履行本協議項下的權利和義務即視為已獲得了監護人的認可。 </span><br />
        &nbsp;&nbsp;2.3  QuickFox提醒未成年人在使用會員服務時，要善於網上學習，認清網絡世界與現實世界的區別，避免沉迷於網絡，影響日常的學習生活。 <br />
        九、通知 <br />
        為便於您獲知與本協議和超級VIP服務相關的信息，您同意QuickFox有權通過網頁公示、頁面提示、彈窗、消息通知、公眾號通知、站內信、您預留的聯繫方式（如手機短信、電子郵件等）等一種或多種方式進行通知，該通知自QuickFox發送之時視為已成功送達您。如多種通知方式並存的，則送達時間以上述方式中最早發送之時為準。 <br />
        此類通知的內容或將對您產生重大有利或不利影響，請您務必確保聯繫方式為有效並請及時關注相應通知。 <br />
         </div>
    <div style="line-height: ${$pc6dot4vw};">
        十、聯繫QuickFox<br />
        如您對本協議或使用超級VIP服務的過程中有任何問題（包括問題諮詢、投訴等），QuickFox專門為您提供了多種反饋通道，希望為您提供滿意的解決方案：<br />
        &nbsp;&nbsp;· 在線客服/其他在線意見反饋通道：您可與QuickFox平台上產品功能頁面的在線客服聯繫或者在線提交意見反饋；<br />
        &nbsp;&nbsp;· 人工客服通道：您可以撥打QuickFox的任何一部客服電話與QuickFox聯繫；<br />
        &nbsp;&nbsp;· 其他方式：QuickFox提供的其他反饋通道。 <br />
        我們會在收到您的反饋後儘快向您答复。 <br />
         </div>
    <div style="line-height: ${$pc6dot4vw};">
        十一、其他<br />
        &nbsp;&nbsp;1. 本協議的生效、履行、解釋及爭議的解決均適用中華人民共和國法律。 <br />
        &nbsp;&nbsp;2. <span
            style="color: rgb(236, 207, 171);">如就本協議的簽訂、履行等發生任何爭議的，雙方應盡量友好協商解決；協商不成時，任何一方均可向被告住所地享有管轄權的人民法院提起訴訟。 </span><br />
        &nbsp;&nbsp;3. 如本協議因與中華人民共和國現行法律相抵觸而導致部分無效的，不影響協議的其他部分的效力。 <br />
        &nbsp;&nbsp;4. 本協議的標題僅為方便及閱讀而設，並不影響正文中任何條款的含義或解釋。 <br />
    </div>
</div>`;

export default memberAgreementTxt;
