import { observable, action } from 'mobx';
import { postMessage } from '~/utils';

class LoadingStore {
  @observable loading = false;
  @observable disabledLoading = false;
  @action changeLoading(state: boolean) {
    this.loading = state;
    postMessage.send('changeLoading', { state });
  }
  @action setDisableLoading(state: boolean) {
    this.disabledLoading = state;
  }
}

export default LoadingStore;
