import { EPostMessageReturn } from '~/models';
import { JudgePf } from './JudgePf';

export default {
  /**
   * 调用原生方法
   * @param method 方法名
   * @param data
   * @param callback 回调方法 对pc应用有效 代替window挂载
   * @returns
   */
  async send(method: string, data: any | null, callback?: (data: any) => void) {
    try {
      const os = JudgePf();
      if (os.isAndroid) {
        if (typeof window?.android?.[method] == 'function') {
          try {
            if (data == null) {
              window?.android?.[method]();
            } else {
              window?.android?.[method](JSON.stringify(data));
            }
            return EPostMessageReturn.success;
          } catch (e) {
            console.error(e);
            return EPostMessageReturn.noFunc;
          }
        } else return EPostMessageReturn.noFunc;
      }
      if (os.isIOS) {
        window?.webkit?.messageHandlers?.[method]?.postMessage(data);
        if (typeof window?.webkit?.messageHandlers == 'object')
          return EPostMessageReturn.success;
        else return EPostMessageReturn.noFunc;
      }
      if (os.isPcApp) {
        let res: any;
        // console.log('postMessage send 进入pcApp');
        // let res = await request(method, data);
        // electron版本升级，开启上下文隔离，客户端的调用方法的写法有变,兼容旧写法
        try {
          //@ts-ignore
          res = await window.customElectronAPI.request(method, data);
        } catch {
          //@ts-ignore
          res = await request(method, data);
        }
        await callback?.(res);
        return res;
      }
      return true; //用于判断页面是否在安卓/ios应用中，下面catch中的return同样
    } catch (e) {
      console.log('调用原生接口报错 send：' + e);
      return false;
    }
  },
  response(method: string, callback: (event, json) => void, once?: boolean) {
    try {
      if (!callback) {
        console.log('postMessage response请提供callback');
        return;
      }
      const os = JudgePf();
      if (os.isPcApp) {
        if (once) {
          // electron版本升级，开启上下文隔离，客户端的调用方法的写法有变,兼容旧写法
          //@ts-ignore
          // response(method, callback);
          try {
            //@ts-ignore
            window.customElectronAPI.response(method, callback);
          } catch {
            //@ts-ignore
            response(method, callback);
          }
        } else {
          let unsubscribe: Function = () => {};
          // electron版本升级，开启上下文隔离，客户端的调用方法的写法有变,兼容旧写法
          //@ts-ignore
          // const unsubscribe: Function = subscribe(method, callback);
          try {
            //@ts-ignore
            unsubscribe = window.customElectronAPI.subscribe(method, callback);
          } catch {
            //@ts-ignore
            unsubscribe = subscribe(method, callback);
          }
          // const unsubscribe: Function = subscribe ? subscribe(method, callback) : window.customElectronAPI.subscribe(method, callback);
          return unsubscribe;
        }
      }
    } catch (e) {
      console.log('调用原生接口报错 response：' + e);
    }
  },
};
