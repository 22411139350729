let memberAgreementTxtEn = `<div style="color: #fff; font-size: 3.2vw; padding: 8vw 8.933vw; box-sizing: border-box">
    <div style="font-size: 3.333vw;text-align: center; margin-bottom: 7vw;"> Quickfox Super VIP Service Agreement</div>
    <div style="line-height: 6.4vw;">Welcome to use Quickfox Super VIP member service!</div>

    <div style="line-height: 6.4vw; color: rgb(236, 207, 171);">
        Quickfox Super VIP Services is provided to you by Fujian Zifei Information Technology Co., Ltd. (hereinafter referred to as "Quickfox"). Quickfox is a permanent free return to overseas Chinese and international students to build a professional free return to the country, in overseas, one-click acceleration, domestic video, music, game accelerator assistant. <br />
        "Quickfox Super VIP Service Agreement (hereinafter referred to as" This Agreement "is concluded by you (hereinafter also known as" members "or" super VIP ") and Quickfox, which has equal legal effect on both parties. Quickfox recommends that you read all the contents of this Agreement carefully, especially in a bold form, with your equity (possibly), there is a major relationship (including relevant agreed Quickfox responsibility, your rights, dispute resolution methods, and judicial jurisdiction Please pay attention to the key reading. If you think that the bold clauses in this Agreement may cause your part or all rights or interests, please read it again, and continue to use Quickfox Super Premise to ensure that you have understood it. VIP service. <br />
        If you do not agree to any or all of the terms of this Agreement, don't make a confirmation form (including, but not limited to payment behavior / acceptance, or completing all programs that become members), this agreement is filed in this process. Any objection, or using a super VIP service) to perform the next step or use the Quickfox Super VIP service. When you perform a next step in confirmation or use Quickfox Super VIP services, you will represent your agreement relationship with Quickfox, you voluntarily accept this Agreement and follow all of this Agreement. <br />
        Quickfox has the right to change this Agreement. Once the content of this Agreement changes, Quickfox will notify the corresponding page, station letter or in other reasonable ways, please read it carefully. If you do not agree to the content of the change, you can choose to stop using the super VIP service. If you continue to use the super VIP service, you will see all the content you have agreed to change. The updated agreement takes effect from the date of the present update. <br />
        If you are a minor, please read and decide whether to agree to this agreement under guardian guardianship.<br />
    </div>
     <div style="line-height: 6.4vw;">
        First, service description<br />
        1. <span
            style="color: rgb(236, 207, 171);">[Quickfox Super VIP]</span>Refers to all the programs that have completed members, and under the premise of compliance with this Agreement, enjoy the natural person of the super VIP service provided by Quickfox during the membership period.<br />
        2. <span
            style="color: rgb(236, 207, 171);">[Quickfox Super VIP Service]</span>It is the exclusive member rights provided by Quickfox to you, and please check your section 4 of this Agreement.<br />
        3. <span
            style="color: rgb(236, 207, 171);">[Quickfox Super VIP Service Agreement]</span>It is the supplementary agreement for "Quickfox Service Agreement" and "Quickfox Privacy Policy" is an indivisible part of its equivalent legal effect; this agreement is conflicted with the aforementioned agreement.<br />
        4. <span
            style="color: rgb(236, 207, 171);">[Quickfox Super VIP Service Rules]</span>Is Quickfox's protocol, announcement, page description, notification, FAQ, etc. related to Super VIP services in the Quickfox Super VIP Center, or in the future. The aforementioned content is entered into force, and it constitutes a part of the inseparable part of this protocol.<br />
         </div>
    <div style="line-height: 6.4vw;">
        Second, use service<br />   
        1.Service acquisition<br />
        1.1 Quickfox Super VIP Services is a charge service, you can purchase the corresponding service costs; at the same time, you can also obtain the services of Quickfox approved by the gift card through marketing activities.<br />
        1.2 When getting a Quickfox Super VIP service, you should follow the laws and regulations, this Agreement is aggregated, and the legitimate rights and interests of third parties or Quickfox should not violate the legitimate rights and interests of third parties or Quickfox. You must not get yourself (or assist others) to get super VIP services in the following ways:<br />
        (1) Use of commercial or other non-personal uses;<br />
        (2) Through any automaton, spider software, reptile software, etc., etc.;<br />
        (3) The way the super VIP service is transferred without the transfer, gift, borrowing, renting, sales, and transfer of the super VIP service without Quickfox;<br />
        (4) Through ineffective means or in violation of the principle of honesty (such as using rule vulnerabilities, using system vulnerabilities, abuse of membership, black industry, speculative, etc.);<br />
        (5) The way the Quickfox Super VIP service rule is used to utilize or destroy the Quickfox.<br />
        <span
            style="color: rgb(236, 207, 171);">Quickfox is declared here: any behavior of Quickfox Super VIP qualifications is illegally sold, illegally transferred, Quickfox is right to investigate its legal responsibility.</span><br />
        2.Basic principle of service use<br />
        2.1 In the process of using the Quickfox Super VIP service, you should comply with laws and regulations and other normative documents, including but not limited to the "Conservative National Secret Law of the People's Republic of China", "People's Republic of China Copyright Law", "Computer Information of the People's Republic of China" System Security Protection Regulations, "Computer Software Protection Regulations", "Internet Electronic Bond Service Management Regulations", "The People's Republic of China Network Safety Law", "Information Network Communication Right Protection Ordinance", etc .; should comply with public order, respect social morality, Do not endanger network security, do not use the Internet to engage in national security, honor and interests, incite the subverting national regimes, overturned the socialist system, incite the split country, and destroy the national unity, promote terrorism, extremism, promote national hatred, national discrimination, spread Violence, obscene pornographic information, fabrication, dissemination of false information disrupt economic order and social order, and infringement of the reputation, privacy, intellectual property and other legitimate rights and interests. In any case, once Quickfox reasonably believes that you have the above behavior, you can provide Quickfox Super VIP services at any time without prior notification.<br />
        2.2 Quickfox grants a personal, non-exclusive, non-transferable, non-commercial, revocable, periodic license for Quickfox Super VIP services.<span
            style="color: rgb(236, 207, 171);">That is: You can only use super VIP services for personal and non-commercial purposes.</span><br />
        3.Change of service content<br />
        <span style="color: rgb(236, 207, 171);">
            Quickfox has the right to change to super VIP service content (including but not limited to super VIP equity rules, charging standards, charging methods) for super VIP services (including, but not limited to, super VIP equity rules, charging methods). For the aforementioned changes, Quickfox will be published by the corresponding service page, station letter notification or in other reasonable ways, and take effect on the date of the release. After the release, you continue to operate (including but not limited to click on clicks, or continue to buy, or complete payment behavior, or using super VIP services, etc.), it is considered to be changed.
            </span><br />
    </div>
    <div style="line-height: 6.4vw;">
        Third, your account<br />
        1.Account acquisition<br />
        Within your super VIP service, you have a Quickfox account for super VIP equity to your Quickfox Super VIP account (ie: Quickfox account bound to your super VIP service, hereinafter referred to as "super account" or "member account").<br />
        At the same time, Quickfox reminds you here, you should use your membership account under the premise of complying with the Quickfox account usage rules. Quickfox recommends that you check the "Quickfox Service Agreement" and related sub-protocols, all kinds of announcements, page descriptions, and specification processes, FAQ, etc. to get more about account usage rules.<br />
        2.log in<br />
        In addition to your tourist mode (see section III, section III), Quickfox Super VIP services requires you to log in to your Quickfox Super VIP account.<br />
        3.Account management and security<br />
        &nbsp;&nbsp;3.1 You should be responsible and properly kept, use, and maintain your super VIP account and password, and take the necessary and valid confidentiality measures for your account and password.<span
            style="color: rgb(236, 207, 171);">Any loss, leak, tampering, stolen, and other losses caused by custody, use, and improper loss, and other losses caused by the improper storage, use and maintenance.</span><br />
        &nbsp;&nbsp;3.2 If you find that someone else is not authorized to use your account or other exceptions that have not been used properly, you need to retrieve the account to retrieve the account to retrieve the account. During the retrieval, Quickfox may ask you to provide the corresponding information and/Or proof information,<span
            style="color: rgb(236, 207, 171);">Make sure that the content you provide is really valid, otherwise you will not be able to retrieve failure through Quickfox verification.</span><br />
        &nbsp;&nbsp;3.3 To protect the account security, prevent account stolen, QuickFox may use one or more ways from time to time or periodically (such as SMS verification, email authentication, etc.),<span
            style="color: rgb(236, 207, 171);">If it is not successful, Quickfox has reasonable reasons to suspect that the account has been stolen and other insecure, and decides whether to abort the account to continue to provide membership services as serious as the circumstances./Or take further measures.</span><br />
        &nbsp;&nbsp;3.4 Please pay special attention,<span
            style="color: rgb(236, 207, 171);">The behavior under your super VIP account is deemed to your own behavior, and you should be responsible for activities that occur under your super account or through the account.</span><br />
        4.Information query<br />
        You can query your account information for free by logging in to the Quickfox Super VIP Center, including the open super VIP service content, service period, consumption record, etc.<br />
        5.Login limit<br />
        &nbsp;&nbsp;5.1  <span
            style="color: rgb(236, 207, 171);">The Quickfox Super VIP account can be used on the mobile phone terminal, the PAD terminal, the computer side, and the terminal corresponding to the terminal, the specific applicable device terminal is based on Quickfox actual.</span><br />
        &nbsp;&nbsp;5.2 For your smooth use of Quickfox Super VIP services, Quickfox suggests you here, you can check the device terminal login of your super VIP account in your product.<br />
        6.Account independent account<br />
        <span
            style="color: rgb(236, 207, 171);">Your super account is only for non-commercial purposes for non-commercial purposes. Unless Quickfox consent, member interests can not be transferred, migrated, transfer, gifts, sell, rent, share, and share, even if the aforementioned different accounts have the right to use. Quickfox reminds you here, you can buy membership services/Please pay attention to distinguish when you participate in activities, so as not to cause unnecessary losses.</span><br />
        7.Owner<br />
        <span
            style="color: rgb(236, 207, 171);">Quickfox Super VIP account, super VIP service under Super VIP account, virtual product (product) and ownership and related intellectual property ownership and related intellectual property rights belong to Quickfox, you only have the product and / or The limited use of the service. However, the entity product ownership obtained by the legitimate channels, the account is at home, except for the other equations of other rights in Quickfox.</span><br />
        8.Visitor mode<br />
        &nbsp;&nbsp;8.1 <span
            style="color: rgb(236, 207, 171);">Due to Apple App Store Channels Allow non-registered users to purchase super VIP services through tourists, the member service will bind the device used when purchasing a member, the super VIP service will only be used on the binding device, and some member rights may Will you have to enjoy (such as activity participation) because you use the tourist mode. If your device is lost, damaged, system upgrade, restore, emptying, or other similar behavior will cause you to continue to use super VIP rights bound to this device, which caused by your losses, you should bear it on your own.</span><br />
        &nbsp;&nbsp;8.2 Quickfox This suggests that you bind your Quickfox registration account as follows for the page action to use members' rights and better enjoy the membership rights provided to you on your other devices.<br />
         
    </div>
    <div style="line-height: 6.4vw;">
        Fourth, member rights and additional payment<br />
        1.Super VIP rights<br />
        <span
            style="color: rgb(236, 207, 171);">Special instructions on the "Super VIP" gift member rights: If you are Quickfox Super VIP, Quickfox will also give your other Quickfox self-organized or collaborative member service, the specific content of the membership equity, the specific content of the rights and interests of this member The rules (including the applicable equipment terminal) are subject to the relevant member protocols and / or the description of the page. Your foregoing member rights will be directly available to your mobile phone number binding to your mobile phone number (such as: Your Quickfox Super VIP Binding Mobile phone number is 12345678900, your Quickfox Super VIP rights will be directly Provide a super VIP account bonded to the mobile phone number 12345678900, and the validity period will be at the date you open the Quickfox Super VIP service (or upgrade to the Quickfox Super VIP), so Quickfox will remind you at this kind of goodwill, please remember to use it in time.</span><br />
        2. Equipment and system differences<br />
       After you become a super VIP, you may have a difference due to the different software versions, devices, operating systems, etc. of your use and other third-party reasons, which may give you the inconvenience, you Indicates that it is not allowed or exempt about QUICKFOX related responsibilities. Quickfox recommends that you can try to resolve by upgrading applications or operating system versions, replacement using devices, or to solve the Quickfox contact with the way through this agreement.<br />
        <span
            style="color: rgb(236, 207, 171);">Quickfox specially reminds you that using Quickfox Super VIP equity needs to meet certain software versions, devices, / or operating system requirements, so Quickfox recommends you upgrade your application or operating system version, or replace the device to enjoy Quickfox super VIP rights.</span><br />
        3. Virtual products<br />
        You may get virtual products such as the various points, coupons, coupons, vouchers, welfare coupons, growth values, etc. in the use of the super VIP service. The aforementioned virtual products can only be used for the designated use of Quickfox official channels, cannot return, exchange cash or transfer, buying, replacement, mortgage, etc., will not recover after use. <br />
        At the same time, please understand that Quickfox has the right to change its use rules based on the actual operational policy (including the use of validity period).<br />
       4. Event participation <br />
        During the Super VIP service period, you have the right to choose to participate in the Super VIP event organized by the Quickfox and enjoy all the offer provided by Quickfox. Contents and rules of specific activities and offers are based on Quickfox actual. <br />
        5. Preferred Policy <br />
        Quickfox may be preliminary, equity upgrades, etc. in different phases, in accordance with the actual operational situation, and specific preferential policies are subject to the content of Quickfox notified by Quickfox in the relevant service page. <br />
        6. Use specifications <br />
        You can follow the process, platform specifications, etc., platform specifications, etc., using Quickfox Super VIP services (including participating activities), etc.
        &nbsp;&nbsp;6.1 Do not obtain Quickfox Super VIP services (including but not limited to partial or all member rights, super VIP accounts) and / or participation activities, including but not limited to members' rights , Virtual products, physical products, membership service redemption code, the same below this article); <br />
        &nbsp;&nbsp;6.2 Do not participate in activities or obtain benefits by unfair means or in violation of honesty, such as using rule vulnerabilities, use system vulnerabilities, abuse membership, black industry, and violating Quickfox to provide services / organizer. . <br />
        <span
            style="color: rgb(236, 207, 171);">If you violate the above agreed, Quickfox has the right to delete, cancel, cleaner, etc., and have the right to terminate to provide you with services, which is borne by you.</span><br />
      7. Service Duration <br />
        &nbsp;&nbsp;7.1 The service period of your super VIP service is based on your own service period, and pays the corresponding membership fee. It is calculated from you to the super VIP. You can check with the Quickfox Super VIP Member Center. When the member service period expires, Quickfox will stop continuing to provide you with a super VIP service;<span
            style="color: rgb(236, 207, 171);">The super VIP service of the automatic renewal fee is expired before the service validity period in the current cycle. You can unilaterally stop automatic renewal, and QuickFox can also stop continuing to your automatic renewal service according to the operational strategy needs, super VIP service period from the current service Termination from the date of the period expiration.</span><br />
        &nbsp;&nbsp;7.2  <span
            style="color: rgb(236, 207, 171);">Please understand that the super VIP service period contains QuickFox resolution, server maintenance, adjustment, upgrade, etc., or any loss required for the above case due to the reasonable time required for third-party infringement processing (if there is ), Quickfox does not presented any form of compensation / compensation, but Quickfox will minimize the impact as much as possible.</span><br />
         
    </div>
    <div style="line-height: 6.4vw;">
       Five, charges and unsubscribe <br />
        1. Fees <br />
        Quickfox Super VIP Services is a charge service, you can complete the payment of super VIP fees through QuickFox actual payment, such as bank card payment, third-party payment, etc. Please note that if you pay for your Apple account or a communication account bound to your member account, this payment method is a payment method for the charger operator. You may have a certain amount through this payment method. Business risks (such as the illegal activities such as valuable cards such as your account or bank card), these risks may cause corresponding economic losses, you should bear all the losses. <br />
        2. Fee refund is also <br />
       Super VIP service system network products and virtual goods, using first-selling service, membership fee is the price of network commodities corresponding to the membership service you purchased, rather than advance payment or deposit, deposit, savings card, etc.<span
            style="color: rgb(236, 207, 171);">After the super VIP service is opened, you can enjoy the seven days of refund service, and the membership fee will return the original road to your payment account.</span><br />
       Quickfox specially reminds you that you should carefully check the account information, purchase service content, price, service period, etc. before buying a super VIP service (including the automatic renewal service).<br />
        3. Charges, modified changes <br />
        <span
            style="color: rgb(236, 207, 171);">The super VIP service charges are independently decided by Quickfox according to the company's operating costs, operational strategies, etc. (adjustments but are not limited to promotion, price increase, etc.), and present in the relevant product service promotion and payment page; if you At the time of purchase and renewal, the relevant charging method changes, the charging method actually supported by Quickfox is accurate; the price of related services has occurred, which should be subject to the current effective price publicly on the Quickfox platform (but Quickfox is with you) There is another conventional except for situations). You agree to continue the behavior (including but not limited to click on the consent, or continue to buy, or complete payment behavior, or using super VIP services, or pay additional payment, etc.), as you know and agree to change the fee, Charges.</span><br />
4. Automatic renewal <br />
        The automatic renewal service refers to the services that members who have not been able to renew the payment due to negligence or other reasons for the need for negligence or other reasons. If the member chooses to open the automatic renewal service, the member authorization Quickfox can pay the payment channel when the membership service is about to expire or the subscription cycle of the membership service is about to pay for the third party that is bound to your own recharge account, binding Accounts, bank cards, communication accounts, etc. (hereinafter collectively referred to as "Account") balances of the second alumni. The premise of this service is that you have opened the automatic renewal and binding relevant accounts, and you can succeed from the above accounts. <br />
        4.1 Billing Period: As a month, quarter, annual, etc. (the specific quasi-quickfox is provided), and the member can choose it. <br />
        4.2 Automatic deduction rules: <br />
        &nbsp;&nbsp;(1) If the member chooses to open the automatic renewal fee, it is considered that the authorization of Quickfox will issue a deduction instruction based on the debit rules of the payment channel, and agree to the payment channel to be issued according to the deduction command issued by Quickfox. In the case where the membership account password, payment password, SMS check code, etc., the fee is deducted from the account. The automatic deduction rules for Apple iOS channels are subject to Apple's regulations. <br />
        &nbsp;&nbsp;(2) Unless the member or Quickfox actively clears the automatic renewal fee, the automatic deduction is long-term effective, and the number of times is not limited. <br />
        &nbsp;&nbsp;(3)<span
            style="color: rgb(236, 207, 171);">If you don't actively cancel the recurning fee, you will be considered as you agree that QuickFox can perform from time to time after the deduction rules of the payment channel (even if your account is insufficient) .</span><br />
        &nbsp;&nbsp;(4) Once the deduction is successful, Quickfox will open the super VIP service corresponding to this billing cycle.<br />
        4.3 Cancellation: <br />
        &nbsp;&nbsp;(1) Apple mobile device: Apple mobile device "App Store" -> Click on Apple account avatar in the upper right corner, enter "account" -> "Subscribe", select "Quickfox" to cancel subscription; <br />
        &nbsp;&nbsp;(2) Android mobile device: The user does not support the user's cancellation fee on the client, and the payment platform is required to cancel it. <br />
      You know and agree that the automatic renewal status update may have a display delay, which is subject to your renewal status displayed in QuickFoxApp.<span
            style="color: rgb(236, 207, 171);">And before successful cancellation, the deduction command that has already entrusted Quickfox automatic renewal is still valid, Quickfox has not returned and compensated for the cost that has been deducted before the command is completed based on the instruction.</span><br />
         
    </div>
    <div style="line-height: 6.4vw;">
       Sixth, your behavior norms and default processing<br />
        1. <span style="color: rgb(236, 207, 171);">Do not have the following behavior during you using the Quickfox Super VIP service:</span><br />
        &nbsp;&nbsp;1.1 Unresses the service content, service period, consumption amount, transaction status, etc.
        &nbsp;&nbsp;1.2 Provide others using the super VIP service through non-Quickfox clearly authorized ways (including but not limited to transfer, gift, borrowing, renting, sales, transfer); <br />
        &nbsp;&nbsp;1.3 Recognition, change, anti-operation, tampering, or other destruction for any security measures for protecting super VIP services; <br>
        &nbsp;&nbsp;1.4 Unaffected any ownership or intellectual statement or label on the super VIP service content without prior written consent; <br />
        &nbsp;&nbsp;1.5 Excellent super VIP service content is displayed in any publication or part of the super-VIP service without being explicitly authorized by Quickfox, and except for any publication occasions (but if your above behavior does not constitute an infringement); <br />
        &nbsp;&nbsp;1.6 Other Behaviors that have not been licensed or violates this agreement, laws and regulations or regulatory policies, violating this agreement, laws and regulations or regulatory policies, violating the legal rights of third parties or QUICKFOX. <br />
        <div style="color: rgb(236, 207, 171);">
            2. You know and agree, if you exist or Quickfox, you think that you have any violations of national laws, regulations or regulatory policies, violating this agreement or damaged Quickfox or / and its affiliate, Quickfox It is right to independently decide one or more of the following processing measures: <br />
            &nbsp;&nbsp;2.1 If this Agreement has a separate terms, according to this terms; <br />
            &nbsp;&nbsp;2.2 No need to notify you to take one or more measures to stop your behavior and behavior, such as deleting / masking related links or content, restrictions / cancel your account / account usage permission; <br />
            &nbsp;&nbsp;2.3 No need to inform you or stop part or all of the super VIP services, and the super VIP service fee you have paid will not be refunded and will not obtain any form of compensation / compensation; <br />
            &nbsp;&nbsp;2.4 If your behavior has caused any losses of Quickfox or / and its affiliates, you should assume full loss of compensation and complete the fee payment within the time limit required by Quickfox. <br />
        </div>
         
    </div>  
    <div style="line-height: 6.4vw;">
        Seven, interruption and termination of services <br />
        <p>
           1. The interruption or termination of this service includes the following cases: <br />
            &nbsp;&nbsp;1.1 You take the initiative to make requirements; <br />
            &nbsp;&nbsp;1.2 Your existence or Quickfox believes that you have any violation of national laws and regulations or regulatory policies, violating this agreement or damaged Quickfox or / and its affiliates; <br />
            &nbsp;&nbsp;1.3 Quickfox requirements according to laws and regulations, regulatory policies or authorities; <br />
            &nbsp;&nbsp;1.4 Quickfox is the need for emergencies such as maintenance of accounts and system security; <br />
            &nbsp;&nbsp;1.5 force majeure (in view of the special nature of the Internet, the force majeure also includes the significant impact of the hacker attack, the technical adjustment of telecommunications department, which is temporarily closed due to government control, and the viral invasion is affected by the normal operation of the Internet.) <br />
            &nbsp;&nbsp;1.6 Other Quickfox can't resist. <br />
        <div style="color: rgb(236, 207, 171);">
          2. When the above termination occurs, you are approved as the following processing method with Quickfox: <br />
            &nbsp;&nbsp;2.1 Already produced but unused super VIP rights automatic cleaning and is not discounted; <br />
            &nbsp;&nbsp;2.2 If you have a transaction in progress in the Quickfox platform, QuickFox will be reasonably processed by the situation; <br />
            &nbsp;&nbsp;2.3 In addition to the laws and regulations, there is no other indication of Quickfox, the membership fee of Quickfox is not returned; <br />
            &nbsp;&nbsp;2.4 If you violate this Agreement, Quickfox has the right to ask you to pay the corresponding default responsibility; <br />
            &nbsp;&nbsp;2.5 In addition to the provisions of laws and regulations or other instructions in Quickfox, Quickfox does not need to take any responsibility to you and the third person. <br />
        </div>
        </p>
         
    </div>
    <div style="line-height: 6.4vw;">
       Eight, your personal information protection <br />
       1. Quickfox knows the importance of personal information, so Quickfox attaches great importance to protecting your personal information, and also treats your personal information with high prudent obligations. In the process of using the Quickfox Super VIP service, Quickfox will protect your personal information using relevant technical measures and other security measures. <br />
       2. Minors Protection <br />
       Quickfox highly focuses on the protection of minors. <br />
        &nbsp;&nbsp;2.1 If you are a minor, you should read this agreement under guardian monitoring, guidance, and use Super VIP services to get the consent of the guardian.<br />
        &nbsp;&nbsp;2.2  <span
            style="color: rgb(236, 207, 171);">The guardian should guide the safety issues that the child should pay attention to the Internet should prevent it. If the guardian agrees that the minor uses a super VIP service, you must apply for consumption in the name of the guardian, and conduct correct guidance and supervision of minors using super VIP services. Minors use super VIP services, as well as exercise and fulfilling their rights and obligations under this Agreement, as well as recognition of guardians.</span><br />
        &nbsp;&nbsp;2.3 Quickfox reminds minors to use membership services, be good at learning, recognize the difference between the network world and the real world, to avoid adding the network, affecting daily learning life. <br />
        Nine, notice <br />
        For your convenience you know related to this Agreement and Super VIP service, you agree that Quickfox has the right to publicize, page prompts, pop-ups, messaging notifications, public notices, station letter, your interrogation (such as mobile phone SMS , Email, etc.), etc., etc., which is regarded as successful delivery from Quickfox sent you. As in a variety of notices coexist, the delivery time is subject to the first time in the above manner. <br />
        The content of such notifications or will make you a major and adverse effect on you. Please make sure that the contact information is valid and pay attention to the appropriate notice. <br />
         </div>
    <div style="line-height: 6.4vw;">
        Ten, contact Quickfox <br />
        If you have any questions in this agreement or using a super VIP service (including question consultation, complaint, etc.), Quickfox provides you with a variety of feedback channels, I hope to provide you with a satisfactory solution: <br />
        &nbsp;&nbsp;· Online customer service / Other online comments Feedback channel: You can contact your online customer service contact with the product feature page on the Quickfox platform; <br />
        &nbsp;&nbsp;· Artificial customer service channel: You can call any customer service phone in Quickfox and Quickfox; <br />
        &nbsp;&nbsp;· Other ways: other feedback channels provided by Quickfox. <br />
        We will reply to you as soon as possible after receiving your feedback. <br />
         </div>
    <div style="line-height: 6.4vw;">
        Eleven, other <br />
        &nbsp;&nbsp;1. Effectiveness, performance, interpretation and dispute resolution of this agreement apply to the law of the People's Republic of China.<br />
        &nbsp;&nbsp;2. <span style="color: rgb(236, 207, 171);">
            If there is any dispute in this agreement, the performance, etc., the two sides should be as good as a friendly to solve the problem; when negotiating, either party can file a lawsuit with the people's courts with jurisdiction.</span><br />
        &nbsp;&nbsp;3. If this Agreement leads to some of the effectiveness of the agreement, it does not affect the effectiveness of the agreement. <br />
        &nbsp;&nbsp;4. The title of this Agreement is only convenient and reading, does not affect the meaning or explanation of any of the terms in the body. <br />
    </div>
</div>`;
export default memberAgreementTxtEn;
