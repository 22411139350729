interface IResponseData<T> {
  code: number; //200
  data: T;
  message: string; //ok
  success: boolean; //true
}

//与服务端 /sys/protocolTutorial/list 返回列表的code保持一致
enum EProtocolTutorialCode {
  honor = 'honor_mobile_tutorial',
  huawei = 'huawei_lock_tutorial',
  oneplus = 'onePlus_mobile_tutorial',
  oppo = 'oppo_lock_tutorial',
  samsung = 'samsung_lock_tutorial',
  xiaomi = 'xiaomi_lock_tutorial',
  vivo = 'vivo_lock_tutorial',
  general = 'other_brand_mobile_tutorial', //其他品牌手机教程
  monthlyVipAgreement = 'continuous_monthly_agreement',
  memberServiceAgreement = 'member_service_agreement',
  agreement = 'user_privacy_policy_agreement',
  clause = 'user_service_agreement',
  cancelAccountAgreement = 'cancel_account_agreement',
}

//Stores
interface IUserStore {
  token: string;
  cancelData: ICancelData;
}

//注销所需的用户三方账号信息
interface ICancelData {
  uid: ''; // <string> uid
  password: ''; // <number> 0没设置过密码 1设置过
  bind: ''; // <string> 0 微信  1 qq 2 Google  3 facebook 4 apple
  //以下都是昵称
  wx: '';
  qq: '';
  google: '';
  facebook: '';
  apple: '';
}

enum EPostMessageReturn {
  success = 1,
  noFunc = 0,
}

export {
  IResponseData,
  EProtocolTutorialCode,
  ICancelData,
  IUserStore,
  EPostMessageReturn,
};
