import { observable, action } from 'mobx';
import { IResponseData } from '~/models';
import { Http, JudgePf } from '~/utils';

//协议教程列表item
interface IAgreementListItem {
  id: number; //
  code: string; //编码（用于匹配）
  status: number; //
  title: string; //标题
  content: string; //内容
}

class DataStore {
  @observable loading = false;
  @observable list: IAgreementListItem[] = [];

  /**
   * 获取协议教程列表
   * @param params
   * @returns
   */
  @action
  getAgreements = async (params: { version: string; lang: string }) => {
    this.loading = true;
    try {
      let list: IResponseData = await Http.post(
        '/sys/protocolTutorial/list',
        {
          platformType: JudgePf().platformType,
          ...params,
        },
        {
          headers: {
            platformType: JudgePf().platformType,
            ...params,
            h5: 1,
          },
        }
      );
      if (list.code !== 200) {
        return false;
      }
      this.list = list.data as IAgreementListItem[];
      return true;
    } catch (e) {
      return false;
    } finally {
      this.loading = false;
    }
  };
}

export default DataStore;
