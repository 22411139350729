import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Use } from '~/utils';
import CodedStore from '~/stores/Coded';
import './super-encode';

const { tryBuffer } = Use;

interface IProps {
  coded?: CodedStore;
  onLoaded: Function;
}

@inject('coded')
@observer
export default class SecretImage extends Component<IProps> {
  componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    let source = require('./images/temp.png');
    let img = new Image();
    img.crossOrigin = '';
    this.props.coded.setLoaded(true);
    this.props.onLoaded();
    img.onload = () => {
      let canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      let context = canvas.getContext('2d');
      context.drawImage(img, 0, 0);
      let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      let pixels = imageData.data;
      img = null;
      tryBuffer(pixels, () => {
        let timer = setInterval(() => {
          if (window['superEncoder'].isLoaded) {
            clearInterval(timer);
            this.props.coded.setLoaded(true);
            this.props.onLoaded();
          }
        }, 50);
      });
    };
    img.src = source;
  }

  render() {
    return null;
  }
}
