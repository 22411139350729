import { JudgePf } from '../../utils/JudgePf';

const os = JudgePf();
const isPc = os.isPc;

const $phone1vw = '1vw';
const $phone3dot2vw = '3.2vw';
const $phone3dot333vw = '3.333vw';
const $phone6dot4vw = '6.4vw';
const $phone7vw = '7vw';
const $phone8vw = '8vw';
const $phone8dot933vw = '8.933vw';

const $pc1vw = isPc ? '10px' : $phone1vw;
const $pc3dot2vw = isPc ? '20px; line-height: 48px' : $phone3dot2vw;
const $pc3dot333vw = isPc ? '25px; line-height: 48px' : $phone3dot333vw;
const $pc6dot4vw = isPc ? '20px; line-height: 48px' : $phone6dot4vw;
const $pc7vw = isPc ? '25px; line-height: 48px' : $phone7vw;
const $pc8vw = isPc ? '25px; line-height: 48px' : $phone8vw;
const $pc8dot933vw = isPc ? '25px; line-height: 48px' : $phone8dot933vw;

let monthlyVipAgreementTxt = `
        <div style="color: #fff; font-size: ${$pc3dot2vw}; padding: ${$pc8vw} ${$pc8dot933vw}; box-sizing: border-box; line-height: ${$pc6dot4vw} ; ">
          <div style="font-size: ${$pc3dot333vw};text-align: center; margin-bottom: ${$pc7vw};">QuickFox會員連續包月協議</div> <div>1.服務條款的接受與修改</div>
          &nbsp;&nbsp;本協議是超級VIP會員（下稱“會員”）與福建紫訊信息科技有限公司旗下應用軟件QuickFox（以下稱“QuickFox”）之間關於會員使用QuickFox提供的自動續費委託扣款服務（下稱“本服務”）所訂立的協議，本協議描述QuickFox與會員之間關於本服務的使用及相關方面的權利義務。 “會員”是指享受QuickFox提供的會員服務的個人或單一實體。本協議構成會員（無論個人或者單位）使用QuickFox所提供的本服務之先決條件，除非會員接受本協議條款，否則會員無權使用本服務，會員選擇使用本服務行為將視為同意接受本協議各項條款的約束。
          <br />
          <br />
          <div style="margin-bottom: ${$pc1vw};"> 2.服務說明</div>
          &nbsp;&nbsp;2.1
          本服務是出於會員對於自動續費的需求，在會員已開通本服務的前提下，避免會員因疏忽或其他原因導致未能及時續費造成損失而推出的服務，會員不可撤銷地授權QuickFox可在會員有效期即將過期時，從會員的自有充值賬戶、與會員賬號綁定的第三方支付賬戶、銀行卡、通信賬戶（以下統稱“賬戶”）餘額中代扣下一個計費周期的費用，計費週期包括但不限於月度、季度、年度等（下同），會員可根據QuickFox平台上顯示的計費周期自行選擇。該服務實現的前提是會員已將其QuickFox會員賬號與上述賬戶綁定，且可成功從其上述賬戶中扣款。
          <br />
          &nbsp;&nbsp;2.2
          自動續費具體指，基於2.1的前提，QuickFox通過上述賬戶收取會員下一計費周期費用的扣費方式，會員需保證QuickFox可以從上述賬戶扣款成功，因上述賬戶中可扣款餘額不足導致的續費失敗，由會員自行承擔責任。
          <br />
          &nbsp;&nbsp;2.3
          會員在享受本服務時，會員的使用行為視為其對本服務的服務條款以及QuickFox針對本服務發出的各類公示的同意。
          <br />
          &nbsp;&nbsp;2.4
          QuickFox向會員提供的本服務均僅限於會員在QuickFox平台使用，任何以惡意破解等非法手段將QuickFox提供的本服務與QuickFox平台分離的行為，均不屬於本協議中約定的本服務。由此引起的一切法律後果由行為人負責，QuickFox將依法追究行為人的法律責任。
          <br />
          <br />
          <div>3. 雙方的權利和義務</div>
          &nbsp;&nbsp;3.1
          QuickFox負責向會員提供有關自動續費收費具體情況的查詢方式，但不負責打印相關發票並轉送會員。
          <br />
          &nbsp;&nbsp;3.2
          QuickFox通過會員上述賬戶扣除下一計費周期費用的，應於開通自動續費約定時間劃扣，並將款項記入會員支付記錄，並同時相應延長會員有效期。
          <br />
          &nbsp;&nbsp;3.3
          如在扣費過程出現差錯，QuickFox和會員應密切配合查明原因，各自承擔己方過錯造成的損失；若因雙方各自存在不均等過錯造成損失的，由雙方按過錯的程度承擔相應責任；因雙方共同過錯造成損失但無法區分各自過錯的，由雙方均攤責任。
          <br />
          &nbsp;&nbsp;3.4
          QuickFox可根據其業務開展或技術升級等情況變更或修改本協議的有關服務內容、規則及條款，QuickFox將通過在QuickFox相關頁面公示的方式告知用戶相關變更或修改內容，但無義務另行做個別通知。如果會員不同意本協議的修改，可以取消已經獲取的本服務並停止使用；如果會員繼續使用QuickFox提供的服務，則視為會員已經接受本協議的全部修改。
          <br />
          &nbsp;&nbsp;3.5
          本服務由會員自主選擇是否取消，若會員選擇不取消，則視為會員同意QuickFox按照一定規則進行扣款嘗試，一旦扣款成功，QuickFox將為會員開通下一個計費周期的會員服務。
          <br />
          &nbsp;&nbsp;3.6
          若在自動續費時/之前，QuickFox服務價格發生調整，應以現時有效的價格為準。
          <br />
          &nbsp;&nbsp;3.7
          QuickFox對會員開通本服務不收取任何費用，但QuickFox有權根據業務需要或市場變化等原因決定是否對本服務本身進行收費或調整自動續費周期及費用，並在相關頁面向會員進行公示。
          <br />
          <br />
          <div>4. 協議有效期限、終止</div>
          &nbsp;&nbsp;4.1
          本協議自會員選擇接受或使用本服務後生效，直至會員終止本服務/註銷會員資格時終止。
          <br />
          &nbsp;&nbsp;4.2
          會員有權隨時在會員賬號設置中選擇終止本服務，終止本服務後，QuickFox將停止向會員提供本服務。
          <br />
          &nbsp;&nbsp;4.3
          會員在選擇終止本服務前已經委託QuickFox自動續費扣款的指令仍然有效，QuickFox對於基於該指令扣除的費用不予退還，由會員承擔相關責任。
          <br />
          <br />
          <div>5. 違約責任</div>
          &nbsp;&nbsp;如果一方違約，守約方有權通過雙方約定的爭議解決方式獲得補償。
          <br />
          <br /> <div>6. 爭議解決和法律適用</div>
          &nbsp;&nbsp;QuickFox與會員應通過友好協商解決本協議履行過程中產生的爭議，經協商無法解決的，任何一方均有權將爭議提交至福州市中級人民法院訴訟解決。本協議的解釋、效力和執行等有關問題均適用中華人民共和國法律。
          <br />
        </div>
`;
export default monthlyVipAgreementTxt;
