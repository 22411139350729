import React from 'react';
import { observer } from 'mobx-react';
import Crypto from 'crypto-js';
import intl from 'react-intl-universal';
import SecretImage from '../SecretImage';
// import CN from '~/.config/i18n/zh-CN.ts';
// import CN_China from '~/.config/i18n/zh-CN-China.ts';
import Store from './store';
// import loadingImg from '~/.assets/images/loading.png';
// import './style.scss'
import Style from './style.scss';
// import RootStore from '~/stores';

window.Crypto = Crypto;
// 当初为了ios上架弄了马甲包（Quickfox资讯） 以环境变量+多语言的方式切换打包内容
// const locales = {
//   'zh-CN': LANG === 'zh-CN-China' ? CN_China : CN,
// };

@observer
class RootApp extends React.Component {
  store = new Store();

  // componentDidMount() {
  //   console.log('rootApp init intl');
  //   const currentLocale = 'zh-CN';
  //   intl
  //     .init({
  //       currentLocale,
  //       locales,
  //     })
  //     .then(() => {
  //       this.store.setLangInit(true);
  //     });
  // }

  onImgLoaded = () => {
    this.store.setImgLoaded(true);
  };

  render() {
    const { langInit, imgLoaded } = this.store;
    // let loading = RootStore.getInstance().loadingStore.loading;
    // TODO: 这个分支修改还没有同步到正式环境上去
    return imgLoaded ? (
      // <> {loading?<div className={Style.loading}><div className={Style.loadingWrap}><img className={Style.turn} src={loadingImg} alt=""/></div></div>:null} {this.props.children}</>
      this.props.children
    ) : (
      <>
        <SecretImage onLoaded={this.onImgLoaded} />
        <div className={Style.loading}>{intl.get('common.init')}...</div>
      </>
    );
  }
}

export default RootApp;
