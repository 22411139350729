import { observable, action, computed } from 'mobx';
import { ICancelData } from '~/models';
import { Http, JudgePf } from '~/utils';

class UserStore {
  @observable token: string;
  @observable cancelData: ICancelData;
  @observable vip404: boolean
  @action
  async getUserInfo(params = {}) {
    const res = await Http.get('/user/info', { params });
    if (res?.code === 200 && res?.data?.accountType === 2) {
      return true
    }
    return false
  }
}

export default UserStore;
